import * as React from 'react';
import { ComboBox, IComboBoxOption } from 'office-ui-fabric-react/lib/ComboBox';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import globalStyle from "./globalStyle.module.scss";
import { IColumnInfo } from '../interfaces/IRenderForm';

interface SPFieldChoiceProps {
  field: IColumnInfo;
  value: string;
  readOnly?:boolean;
  onChange?: (question:IColumnInfo, type?:string) => (event: any, newValue?: any, index?: number, value?:string) => void;
}

interface SPFieldChoiceState {

}

export default class SPFieldChoice extends React.Component<SPFieldChoiceProps, SPFieldChoiceState> {
  constructor(props: SPFieldChoiceProps) {
    super(props);
    this.state = {};
  }

  render() {
    const onChangeParam:any = this.props.onChange != undefined && this.props.onChange != null ? {onChange:this.props.onChange(this.props.field)} : {};
    // On regarde si c'est du liste déroulante ou cases à cocher
    if (this.props.field.createData.EditFormat == 0) {
      const options: IComboBoxOption[] = this.props.field.createData.Choices.map((choice:string) => { return { key: choice, text: choice } });
      return (
        <div style={{position:"relative"}}>
          <ComboBox
            label={this.props.field.createData.Title}
            allowFreeform={false}
            autoComplete={'on'}
            options={options}
            required={this.props.field.createData.Required}
            selectedKey={this.props.value}
            key={this.props.field.createData.Title + this.props.field.createData.TypeAsString}
            multiSelect={this.props.field.createData.isMulti}
            useComboBoxAsMenuWidth={true}
            // onChange={this.props.onChange(this.props.field)}
            {...onChangeParam}
          />
          {this.props.readOnly != undefined && this.props.readOnly == true ?
            <div style={{position: "absolute",top: 0,left: 0,width: "100%",height: "100%"}}></div>:<React.Fragment></React.Fragment>
          }
          {
            this.props.field.createData.Description != undefined && this.props.field.createData.Description != null && this.props.field.createData.Description.length > 0 ?
              <span className={globalStyle.formFieldDescription}>{this.props.field.createData.Description}</span>
              :
              ""
          }
        </div>
      );
    }
    else {
      const options: IChoiceGroupOption[] = this.props.field.createData.Choices.map((choice:string) => { return { key: choice, text: choice } });
      return (
        <div style={{position:"relative"}}>
          <ChoiceGroup
            defaultSelectedKey={this.props.value}
            options={options}
            // onChange={_onChange}
            label={this.props.field.createData.Title}
            required={this.props.field.createData.Required}
            readOnly={this.props.readOnly != undefined ? this.props.readOnly : false}
            name={this.props.field.internalName}
            // onChange={this.props.onChange(this.props.field)}
            key={this.props.field.createData.Title + this.props.field.createData.TypeAsString + "ChoiceGroup"}
            {...onChangeParam}
          />
          {this.props.readOnly != undefined && this.props.readOnly == true ?
            <div style={{position: "absolute",top: 0,left: 0,width: "100%",height: "100%"}}></div>:<React.Fragment></React.Fragment>
          }
          {
            this.props.field.createData.Description != undefined && this.props.field.createData.Description != null && this.props.field.createData.Description.length > 0 ?
              <span className={globalStyle.formFieldDescription}>{this.props.field.createData.Description}</span>
              :
              ""
          }
        </div>
      )
    }
  }
}