import * as React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
const styles = require('../../styles/styles.module.scss');
import Store from '../store/store';
import axios from 'axios';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import SelectReact, { createFilter } from 'react-select';
import { observer } from "mobx-react";
import{MenuList , customMultipleValue} from './../helper/reactSelectCustomRender';


interface Props {
}

interface State {
    reload?: boolean;
}

const today: Date = new Date();
@observer
export default class EditWorFlow extends React.Component<Props, State>{
    public constructor(props: Props) { // Dans le constructeur, on initialise toujours les states
        super(props)
        this.state = {

        }

    }

    addStep = (event) => {
        event.preventDefault();
        const formatDateOfBeginning = this.formatDate(Store.addeEditStep.plannedDateBegin);
        const formatDateOfEnd = this.formatDate(Store.addeEditStep.plannedDateEnd);
        const idProj = Store.addeEditStep.idProject;

        axios.get(Store.wsPath + `/1/workflows/getAllStepsAndTasksByIdProject`, { params: { id: idProj } })
            .then(responseSteps => {
                const allSteps = responseSteps.data;
                const rankStep = Store.addeEditStep.addAfterIndex + 1;
                const stepsToUpdate = allSteps.splice(rankStep);

                axios.post(Store.wsPath + '/1/workflows/addStepAndTasks', { // TODO test
                    idProject: idProj,
                    nameStep: Store.addeEditStep.name,
                    state: Store.addeEditStep.state,
                    plannedDateBegin: formatDateOfBeginning,
                    plannedDateEnd: formatDateOfEnd,
                    rankStep: rankStep + 1,
                    steps: stepsToUpdate,
                    idResponsible: Store.addeEditStep.idResponsible,
                    idDoc : Store.selectedDoc.id
                }).then((responseCreateTask) => {
                    if (responseCreateTask.data.success == true && responseCreateTask.status == 200) {
                        Store.reloadWorkFlow = true;
                        Store.contextualmenu.open = "extended";
                        if(Store.modeSharepoint == false){
                            Store.contextualmenu.content = "documentVersion";
                          }
                        Store.addeEditStep = Store.DefaultValueAddeEditStep;
                        // SnackBar
                        Store.snackBar.message = "Etape ajoutée avec succées"
                        Store.snackBar.open = true;
                        Store.snackBar.error = false;
                    }
                    else {
                        // SnackBar
                        Store.snackBar.message = "Une erreur c'est produite, réessayez"
                        Store.snackBar.open = true;
                        Store.snackBar.error = true;
                    }
                })
            }).catch(error => {
                console.log("error : " + error);
                // SnackBar
                Store.snackBar.message = "Une erreur c'est produite, réessayez"
                Store.snackBar.open = true;
                Store.snackBar.error = true;
            })
    }

    public formatDate = (date) => {
        const maDate = new Date(date);
        if ((maDate.getMonth() + 1) < 10) {
            return maDate.getFullYear() + "-0" + (maDate.getMonth() + 1) + "-" + maDate.getDate();
        } else {
            return maDate.getFullYear() + "-" + (maDate.getMonth() + 1) + "-" + maDate.getDate();
        }
    }

    public editStep = (event) => {
        event.preventDefault();
        const formatDateOfBeginning = this.formatDate(Store.addeEditStep.plannedDateBegin);
        const formatDateOfEnd = this.formatDate(Store.addeEditStep.plannedDateEnd);

        axios.post(Store.wsPath + `/1/workflows/updateStep`, {
            id: Store.addeEditStep.id,
            nameStep: Store.addeEditStep.name,
            plannedDateBegin: formatDateOfBeginning,
            plannedDateEnd: formatDateOfEnd,
            idResponsible: Store.addeEditStep.idResponsible,
            tasksList: Store.addeEditStep.tasksList,
            idDoc : Store.selectedDoc.id,
            idUserConnected : Store.userConnected.id
        }).then(responseSteps => {
            if (responseSteps.data.success == true && responseSteps.status == 200) {
                Store.reloadWorkFlow = true;
                Store.contextualmenu.open = "extended";
               // Store.contextualmenu.content = "documentVersion";
               if(Store.modeSharepoint == false){
                    Store.contextualmenu.content = "documentVersion";
                }
                Store.addeEditStep = Store.DefaultValueAddeEditStep;
                // SnackBar
                Store.snackBar.message = "Etape modifiée avec succées"
                Store.snackBar.open = true;
                Store.snackBar.error = false;
            }
            else {
                // SnackBar
                Store.snackBar.message = "Une erreur c'est produite, réessayez"
                Store.snackBar.open = true;
                Store.snackBar.error = true;
            }
        })
    }

    public onChange = name => event => {
        if (name == "nameProject") {
            Store.editWorkflow.name = event.target.value;
        }
        else if (name == "nameStep") {
            Store.addeEditStep.name = event.target.value;
        }
        else if (name == "beginDateProject") {
            Store.editWorkflow.plannedDateBegin = event;
        }
        else if (name == "endDateProject") {
            Store.editWorkflow.plannedDateEnd = event;
        }
        else if (name == "beginDateStep") {
            Store.addeEditStep.plannedDateBegin = event;
        }
        else if (name == "endDateStep") {
            Store.addeEditStep.plannedDateEnd = event;
        }
        else if (name == "select") {
            Store.addeEditStep.idResponsible = event.map(user => { return user.value; });
        }
    }

    public updateReactSelect = (users: number[]) => {
        const tempArray: any[] = []
        users.forEach(user => {
            tempArray.push(Store.suggestionsUser.find(suggestion => suggestion.value == user));
        });
        return tempArray
    }

    public renderAddStep = () => {
        return (
            <form autoComplete="false" onSubmit={Store.contextualmenu.content == "addStep" ? this.addStep : this.editStep}>
                <h5 className={styles.titleContextual}>{Store.contextualmenu.content == "addStep" ? "Ajouter une étape" : "Modifier l'étape"}</h5>
                <TextField
                    variant='outlined'
                    className={styles.field}
                    required
                    type="text"
                    defaultValue={Store.addeEditStep.name != undefined ? Store.addeEditStep.name : ""}
                    onChange={this.onChange("nameStep")}
                    label="Nom de l'étape"
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                    <DatePicker
                        className={styles.field}
                        value={Store.addeEditStep.plannedDateBegin}
                        format="dd/MM/yyyy"
                        InputLabelProps={{ shrink: true }}
                        onChange={this.onChange("beginDateStep")}
                        variant="outlined"
                        label="Date de début"
                        minDate={Store.workflow.plannedDateBegin}
                        minDateMessage="La date de début doit être superieure à la date de début du projet"
                        maxDate={Store.workflow.plannedDateEnd}
                        maxDateMessage="La date de début doit être inférieure à la date de fin du projet"
                        required
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                    <DatePicker
                        className={styles.field}
                        value={Store.addeEditStep.plannedDateEnd}
                        format="dd/MM/yyyy"
                        InputLabelProps={{ shrink: true }}
                        onChange={this.onChange("endDateStep")}
                        variant="outlined"
                        label="Date de fin"
                        minDate={Store.addeEditStep.plannedDateBegin}
                        minDateMessage="La date de fin doit être supérieure à la date de début du projet"
                        maxDate={Store.workflow.plannedDateEnd}
                        maxDateMessage="La date de fin doit être inférieure à la date de fin du projet"
                        required
                    />
                </MuiPickersUtilsProvider>

                <InputLabel>Responsable(s) de tâches :</InputLabel>
                <div style={{ position: "relative" }}>
                    <SelectReact
                        options={Store.suggestionsUser}
                        isMulti
                        onChange={this.onChange("select")}
                        placeholder="Responsable(s) de tâches"
                        filterOption={createFilter({ ignoreAccents: false })}
                        defaultValue={Store.addeEditStep.idResponsible.length > 0 ? this.updateReactSelect(Store.addeEditStep.idResponsible) : []}
                        className={styles.field}
                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                        components={{ MultiValueLabel: customMultipleValue , Option: MenuList}}

                    />
                    <input className={styles.hideInput} name="Responsable(s) de tâches" value={Store.addeEditStep.idResponsible.length > 0 ? this.updateReactSelect(Store.addeEditStep.idResponsible) : []} required></input>
                </div>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    {Store.contextualmenu.content == "addStep" ?
                        "Valider"
                        :
                        "Modifier"
                    }
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    className={styles.btnCancel}
                    color="default"
                    onClick={() => { Store.contextualmenu.open = "extended"; Store.contextualmenu.content = "documentVersion"; Store.addeEditStep = Store.DefaultValueAddeEditStep; }}
                >
                    Annuler
                </Button>
            </form>
        )
    }

    public render() {
        return (
            <div>
                {Store.contextualmenu.content == "addStepModel" || Store.contextualmenu.content == "editStep" ? this.renderAddStep() : ""}
            </div>
        )
    }
}