import * as React from 'react';
import { observer } from 'mobx-react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import axios from 'axios';
import Store from '../store/store';
const styles = require('./styles/workflow_styles.scss');

interface State {
    dataArray: any[],
    openModal?: boolean
}

interface Props {
    idDoc: number
}
let today
@observer
export default class HistoricActions extends React.Component<Props, State>{
    public constructor(props:Props) {
        super(props)
        this.state = {
            dataArray: undefined,
            openModal: false
        }

    }
    componentDidMount() {
        this.getDataArray(this.props.idDoc)
    }
    getDataArray = (idDoc) => {
        axios.post(Store.wsPath + '/1/workflows/getAllHistoricActionByIdDocument', {
            idDoc: idDoc

        }).then(response => {
            this.setState({ dataArray: response.data })
        })
    }
    convertDate = (cell) => {
        if (cell != null) {
            today = this.formatDateDisaplyingBootstrap(cell)
        } else {
            today = ""
        }
        return today
    }
    public convertUser = (userIdx) => {
        let tempUserFind = Store.allUsers.find(user =>
            user.id == userIdx
        )
        if (tempUserFind != undefined || null) {
            return tempUserFind.firstname + ' ' + tempUserFind.lastname
        }
        else {
            return "Auteur introuvable"
        }
    }

    public formatDateDisaplyingBootstrap = (date) => {
        const dateConverted = new Date(date)
        let day = dateConverted.getDate();
        let month = dateConverted.getMonth() + 1;
        let year = dateConverted.getFullYear();
        let hour = dateConverted.getHours();
        let min = dateConverted.getMinutes();
        return day + ((month < 10)?"/0":"/") + month + "/" + year + " à " + hour + "h" + ((min < 10)?"0":"") + min;
    }
    
    public render() {
        return (

            <div className={styles.mainContent}>
                <BootstrapTable
                    data={this.state.dataArray}
                    scrollTop={'Bottom'}
                    pagination={true}
                    bordered={true}
                    version="4"
                    ref="table"
                >
                    
                    <TableHeaderColumn dataField='id' isKey={true} hidden={true}></TableHeaderColumn>
                    <TableHeaderColumn dataField='createdAt' width='10%' dataFormat={this.convertDate}>Date</TableHeaderColumn>
                    <TableHeaderColumn dataField='userIdx' width='10%' dataFormat={this.convertUser}>Acteur</TableHeaderColumn>
                    <TableHeaderColumn dataField='action' width='20%' >Action</TableHeaderColumn>
                    <TableHeaderColumn dataField="designation">Désignation de l'évènement</TableHeaderColumn>
                </BootstrapTable>
            </div>

        )
    }
}