import * as React from 'react';
const styles = require('../../styles/styles.module.scss');
import { observer } from "mobx-react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Store from '../store/store';


interface Props {

}

interface State {
    nameSearch
}

@observer

export default class AddSearchWorkflow extends React.Component<Props, State>{
    public constructor(props: Props) {
        super(props)
        this.state = {
            nameSearch: undefined
        }
    }

    addSearch = () => {
    }

    onChange = (name: string) => event => {
        if (name == "nameSearch") {
            this.setState({ nameSearch: event.target.value })
        }
    }

    public render() {
        return (
            <form key="mySearchForm" autoComplete="false" onSubmit={this.addSearch}>
                <h5 className={styles.titleContextual}>Enregistrer une recherche.</h5>
                <TextField
                    variant="outlined"
                    className={styles.field}
                    required
                    type="text"
                    value={this.state.nameSearch}
                    onChange={this.onChange('nameSearch')}
                    label="Nom de la recherche"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Valider
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    className={styles.btnCancel}
                    color="default"
                    onClick={() => { Store.contextualmenu.open = "hidden"; Store.contextualmenu.content = ""; Store.myUser = Store.DefaultMyUser }}
                >
                    Annuler
                </Button>
            </form>
        )
    }
}