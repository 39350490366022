import { Redirect, Route } from "react-router-dom";
import React from 'react'
import Layout from "../Layout/Layout";
import Store from "../store/store";
import { observer } from "mobx-react";

const notAdminPaths = ["/"]

const ProtectedRoutes = observer(({component: Component, ...rest}) => {
  const {path, menuContextuel, key, menuSharepoint, isAdmin, formAdmin } = rest;
  if(Store.userConnected.id == undefined || (isAdmin == true && Store.userConnected.typeUserLydoc != "ged" && Store.userConnected.typeUserLydoc != "admin")) {
    return <Redirect to="/" />
  }
  return (
    <Route {...rest} render={matchProps =>(
      <Layout exact path={path} menuContextuel={menuContextuel} key={key} menuSharepoint={menuSharepoint} component={Component} {...matchProps}/>
    )}/>
  )
});

export default ProtectedRoutes;