import * as React from "react";
import UploadZone from "../Dropzone/Dropzone";
const styles = require("./styles.scss");
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import axios from "axios";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Store from "../store/store";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IDoc } from "./interfaces/IDoc";

interface Props {}

interface State {
  valueRadioFolder?: any;
  valueNewFolder?: string;
}

@observer
class DocumentSelected extends React.Component<
  RouteComponentProps<any>,
  State
> {
  public constructor(props: any) {
    super(props);
    this.state = {
      valueRadioFolder: "",
      valueNewFolder: "",
    };
  }

  public archieveDocuments = () => {
    axios.post(Store.wsPath + "/1/documents/archieveDocument", {
      listId: Store.idDOcSelected,
    });
  };

  public displayingFolder = () => {
    Store.isFolderDisplaying = !Store.isFolderDisplaying;
  };

  public onChangeValue = (name) => (event) => {
    if (name == "folder") {
      this.setState({
        valueRadioFolder: event.target.value,
      });
    } else if (name == "textfieldNewFolder") {
      this.setState({
        valueNewFolder: event.target.value,
      });
    } else if (name == "classeurSelect") {
      Store.idFolder = event.target.value;
    }
  };

  public reinitialiseMnuContextual = () => {
    Store.contextualmenu.content = "";
    Store.contextualmenu.open = "hidden";
  };

  public render() {
    return (
      <div>
        {/* {Store.TinyContextualmenu.visible == true ? 
                <React.Fragment>
                    <div className={styles.itemMenuParameter} onClick={this.archieveDocuments}> <Icon className={styles.icon}>assignment_returned</Icon></div>
                    <div className={styles.itemMenuParameter} onClick={this.displayingFolder}><Icon className={styles.icon}>create_new_folder</Icon></div>
                </React.Fragment>
                   :
                <React.Fragment>
                    <div className={styles.itemMenuParameter} onClick={this.archieveDocuments}> <Icon className={styles.icon}>assignment_returned</Icon>Archiver</div>
                    <div className={styles.itemMenuParameter} onClick={this.displayingFolder}><Icon className={styles.icon}>create_new_folder</Icon>Ajouter dans un classeur</div>
                </React.Fragment>
                } */}
        <React.Fragment>
          <div
            className={styles.itemMenuParameter}
            onClick={this.archieveDocuments}
          >
            {" "}
            <Icon className={styles.icon}>assignment_returned</Icon>Archiver
          </div>
          <div
            className={styles.itemMenuParameter}
            onClick={this.displayingFolder}
          >
            <Icon className={styles.icon}>create_new_folder</Icon>Ajouter dans
            un classeur
          </div>
        </React.Fragment>
        {/* <div className={styles.itemMenuParameter} onClick={this.archieveDocuments}> <Icon className={styles.icon}>assignment_returned</Icon>Archiver</div>
                <div className={styles.itemMenuParameter} onClick={this.displayingFolder}><Icon className={styles.icon}>create_new_folder</Icon>Ajouter dans un classeur</div> */}
        {Store.isFolderDisplaying == true ? (
          <div>
            <FormLabel>Sélectionner le choix : </FormLabel>
            <RadioGroup
              name={"Sélection"}
              value={this.state.valueRadioFolder}
              onChange={this.onChangeValue("folder")}
            >
              <FormControlLabel
                value="existant"
                control={
                  <Radio className={styles.radioPadding} color="primary" />
                }
                label="Classeur existant"
              />
              <FormControlLabel
                value="new"
                control={
                  <Radio className={styles.radioPadding} color="primary" />
                }
                label="Nouveau classeur"
              />
            </RadioGroup>
            {this.state.valueRadioFolder == "existant" ? (
              <div className={styles.selectoverride}>
                <Button className={styles.btnUser}>Valider</Button>
              </div>
            ) : (
              ""
            )}
            {this.state.valueRadioFolder == "new" ? (
              <div>
                <TextField
                  label="Nouveau classeur"
                  onChange={this.onChangeValue("textfieldNewFolder")}
                  value={this.state.valueNewFolder}
                  style={{ marginRight: "10px" }}
                />
                <Button className={styles.btnUser}>Valider</Button>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default withRouter(DocumentSelected);
