import React from 'react';
const styles = require('./styles/styles.scss')
import Store from '../store/store'
import TextField from '@material-ui/core/TextField';
import  Button from '@material-ui/core/Button';
import axios from 'axios';
import InputLabel from "@material-ui/core/InputLabel";
interface Props {
}

interface State {
    tempValueDateFinSouhaitee? : Date
}

const style = {
}
export default class EditDateFinSouhaitee extends React.Component<Props, State>{

    public constructor(props: Props) {
        super(props)
        this.state = {
            tempValueDateFinSouhaitee : Store.workflow.dateFinSouhaitee
        }
    }

    public onChange = e => {
        this.setState({
            tempValueDateFinSouhaitee : e.target.value
        })
    }

    public convertDateFromDataBaseToDisplaying = (date) => {
    //    return "2020-02-21"
        const newDate = new Date(date)
        if ((newDate.getMonth() + 1) < 10) {
          if (newDate.getDate() < 10) {
            return newDate.getFullYear() + "-0" + (newDate.getMonth() + 1) + "-0" + newDate.getDate() 
          }
          else {
            return newDate.getFullYear() + "-0" + (newDate.getMonth() + 1) + "-" + newDate.getDate()
          }
        } else {
          if (newDate.getDate() < 10) {
            return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-0" + newDate.getDate()
          }
          else {
            return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate()
          }
        }
   }

   public onValidDateFinSouhaitee = () => {
    axios.post(Store.wsPath + '/1/workflows/validDateSouhaitee',{
        idProject : Store.workflow.id,
        dateFinSouhaitee : this.state.tempValueDateFinSouhaitee
    }).then((response)=>{
        Store.snackBar.open = true
        Store.snackBar.error = false
        Store.snackBar.message = "La date de fin souhaitée a été modifiée"
        Store.contextualmenu.content = "ActionsWorkflow"
        Store.contextualmenu.open = "open"
        Store.reloadWorkFlow = true
        Store.workflow.dateFinSouhaitee = this.state.tempValueDateFinSouhaitee;
    })
   }

    public render() {
        return (
            <div>
              <div>
                <InputLabel>Date de fin souhaitée</InputLabel>
                <TextField
                    variant='outlined'
                    id="date"
                    type="date"
                    className={styles.field}
                    required
                    defaultValue={this.convertDateFromDataBaseToDisplaying(this.state.tempValueDateFinSouhaitee)}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.onChange}
                />
              </div>
              <div style={{marginTop: "10px"}}>
                <Button onClick={this.onValidDateFinSouhaitee} type="submit" variant="contained" color="primary">Modifier</Button>
              </div>
            </div>
        )
    }
}