import * as React from "react";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
// import SelectReact, { createFilter } from 'react-select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Store from '../../store/store';
// import { toJS } from "mobx";
import axios from 'axios';
import Spinner from "../../Spinner/Spinner";
const styles = require('./css/taxonomyPicker.module.scss');


export interface ITaxoPickerProps {
    onChange?: any; // () => any;
    termSetId: string;
    label: string;
    multiSelect: boolean;
    anchorId: string;
    value?: any | any[];
    onBlur?: (any) => void;
    helperText?: Element | string;
    required?: boolean;
    disabled?: boolean;
}

export interface ITaxoPickerState {
    openModal: boolean;
    errorContent: boolean;
    loadingContent: boolean;
    loadingInput: boolean;
    treeTermeValue: any[];
    termesSelectedMulti: any[];
    termeSelected: any;
    allTermes: any[];
    termSetInfo: any;

}
export default class TaxoPicker extends React.Component<ITaxoPickerProps, ITaxoPickerState> {
    constructor(props: ITaxoPickerProps) {
        super(props);
        this.state = {
            openModal: false,
            errorContent: false,
            loadingContent: false,
            loadingInput: false,
            treeTermeValue: [],
            termesSelectedMulti: [],
            termeSelected: null,
            allTermes: [],
            termSetInfo: null
        };
    }

    componentDidMount() {
        if (this.props.value != undefined && this.props.value != null && this.props.value != "") {
            this.getAllTermInTermSet(this.props.termSetId, this.props.anchorId).then(result => {
                if (this.props.multiSelect == false) {
                    const termFound = this.state.allTermes.filter(term => term.TermGuid == this.props.value.TermGuid);
                    if (termFound.length > 0) {
                        this.setState({ termeSelected: termFound[0] });
                    }
                }
                else {
                    const termesFound = [];
                    this.props.value.forEach(termValue => {
                        const findTerm: any[] = this.state.allTermes.filter(term => term.TermGuid == termValue.TermGuid);
                        if (findTerm.length > 0) {
                            termesFound.push(findTerm[0]);
                        }
                    });
                    if (termesFound.length > 0) {
                        this.setState({ termesSelectedMulti: termesFound });
                    }
                }
            }).catch(error => {
                console.error("Error in 'getAllTermInTermSet' : ", error);
            })
        }
    }

    // Fonction qui permet de fermer la modale contenant l'arbre de sélection, et qui remet des paramètres à leur valeur par défaut
    private closeModal = () => {
        this.setState({ openModal: false, loadingContent: false, errorContent: false });
        this.props.onBlur("");
    }


    // Fonction qui ouvre la modala contenant l'arbre de sélection. On appelle la fonction de récupération des termes au cas ou elle n'a pas était utilisée avant dans l'input autocomplete
    private openModal = () => {
        if (this.state.allTermes.length == 0) {
            this.setState({ loadingContent: true, openModal: true }, () => {
                this.getAllTermInTermSet(this.props.termSetId, this.props.anchorId);
            });
        }
        else {
            this.setState({ openModal: true });
        }
    }

    // Fonction qui récupère les termes d'un termset avec le termset id reçu en props
    private getAllTermInTermSet = (termSetId: string, anchorId: string) => {
        return new Promise((resolve, reject) => {
            // Appel du webservice qui retourne les terms du termset
            axios.post(Store.wsPath + '/1/documents/getAllTermInTermSet', {
                termSetId: termSetId
            }).then(result => {
                if (result.status == 200) {
                    // Si le terme parent est un termeset alors on rempli les state correspondant
                    if (anchorId == "00000000-0000-0000-0000-000000000000" || anchorId == null || anchorId == undefined) {
                        this.setState({ allTermes: result.data.terms, termSetInfo: result.data.termSet, loadingInput: false, loadingContent: false }, () => {
                            resolve(result.data.terms);
                        });
                    }
                    // Sinon on boucle sur tous les termes pour retourné qu les enfants du terme selectionné
                    else {
                        // Recherche du thème parent dans la liste
                        const parentTerm = result.data.terms.filter(term => term.Id == anchorId)[0];
                        // Si on le trouve on appelle la fonction findTermChildren pour chercher tous ses enfants sinon on affiche une erreur
                        if (parentTerm != undefined) {
                            const children = this.findTermChildren(parentTerm, result.data.terms);
                            this.setState({ allTermes: children, termSetInfo: parentTerm, loadingInput: false, loadingContent: false }, () => {
                                resolve(children);
                            });
                        }
                        else {
                            throw new Error("Le terme parent est introuvable dans le termset");
                        }
                    }
                }
                else {
                    this.setState({ errorContent: true, loadingContent: false });
                    reject("Error 'getAllTermInTermSet'");
                }
            }).catch(error => {
                console.error("Error 'getAllTermInTermSet' : ", error);
                this.setState({ errorContent: true, loadingContent: false, loadingInput: false });
                reject("Error 'getAllTermInTermSet' : " + error)
            })
        })
    }

    // Fonction qui cherche les enfants d'un terme
    private findTermChildren = (parent: any, listTerms: any[]) => {
        const findChildren = listTerms.filter(term =>  term.PathOfTerm.split(";").length > 1 && term.PathOfTerm.split(";")[term.PathOfTerm.split(";").length - 2] == parent.Name && term.PathOfTerm.split(";").slice(0,term.PathOfTerm.split(";").length -1).join(";") == parent.PathOfTerm && parent.PathOfTerm != term.PathOfTerm);

        if (findChildren.length > 0 ) {
            const childrenFormated = findChildren.map(chi => {
                const child = chi;
                child.IsRoot = true;
                return child;
            })
            const childs = this.findTermChildrenChild(childrenFormated, listTerms, []);
            return childs;
        }
        else {
            return [];
        }
    }
    // Fonction de récurcivité pour la recherche des enfants d'un terme
    private findTermChildrenChild = (parents: any[], listTerms: any[], result: any[]) => {
        let values = [...result];

        parents.forEach(parent => {
            values.push(parent);
            const findChildren = listTerms.filter(term =>  term.PathOfTerm.split(";").length > 1 && term.PathOfTerm.split(";")[term.PathOfTerm.split(";").length - 2] == parent.Name && term.PathOfTerm.split(";").slice(0,term.PathOfTerm.split(";").length -1).join(";") == parent.PathOfTerm && parent.PathOfTerm != term.PathOfTerm);

            if (findChildren.length > 0 ) {
                values = this.findTermChildrenChild(findChildren, listTerms, values);
            }
        })

        return values;
    }
    // Fonction qui permet de plier ou de déplier un terme qui à des enfants
    private openCloseChildren = (id: string) => event => {
        const el: any = document.querySelector("div[parent-id='" + id + "']");
        if (el.style.display == "none") {
            el.style.display = "block";
            event.target.innerHTML = "-"
        }
        else {
            el.style.display = "none";
            event.target.innerHTML = "+"
        }
    }

    // Fonction onChange appelée lors de la sélection du terme ou des termes dans l'arbre
    private onChangeValue = (value: any, multi: boolean) => event => {
        if (multi == true) {
            let valueSelected: any[] = this.state.termesSelectedMulti;

            if (event.target.checked == true) {
                valueSelected.push(value)
            }
            else {
                valueSelected = valueSelected.filter(val => val.Id != value.Id);
            }
            this.setState({ termesSelectedMulti: valueSelected });
            this.sendDataToFather(valueSelected);
        }
        else {
            let valueSelected: any = this.state.termeSelected;

            if (event.target.checked == true) {
                valueSelected = value;
            }
            else {
                valueSelected = null;
            }
            this.setState({ termeSelected: valueSelected });
            this.sendDataToFather(valueSelected);
        }
    }

    // Fonction appelé sur le onchange du composant Matérial ui autocomplete
    private onChangeValueInput = (value: any, multi: boolean) => {
        if (multi == true) {
            this.setState({ termesSelectedMulti: value });
        }
        else {
            this.setState({ termeSelected: value });
        }
        this.sendDataToFather(value);
    }

    // Fonction qui fabrique l'arbre en fonction des termes parents pour l'affichage, retourne du HTML
    private buildTreeViewChildren = (parent: any, termsList: any[], ismulti: boolean) => {
        const findChildren = termsList.filter(term =>  term.PathOfTerm.split(";").length > 1 && term.PathOfTerm.split(";")[term.PathOfTerm.split(";").length - 2] == parent.Name && term.PathOfTerm.split(";").slice(0,term.PathOfTerm.split(";").length -1).join(";") == parent.PathOfTerm && parent.PathOfTerm != term.PathOfTerm); 
        let valueCheck: boolean = false;
        if (ismulti == true) {
            if (this.state.termesSelectedMulti.filter(val => val.Id == parent.Id).length > 0) {
                valueCheck = true;
            }
        }
        else {
            if (this.state.termeSelected != undefined && this.state.termeSelected != null && this.state.termeSelected.Id == parent.Id) {
                valueCheck = true;
            }
        }


        let control = <Radio checked={valueCheck} onChange={this.onChangeValue(parent, ismulti)} name={parent.Name} />
        if (ismulti == true) {
            control = <Checkbox checked={valueCheck} onChange={this.onChangeValue(parent, ismulti)} name={parent.Name} />
        }

        if (findChildren.length > 0 ) {
            const renderResult = findChildren.map(father => {
                const result = this.buildTreeViewChildren(father, termsList, ismulti);
                return result;
            })
            return (
                <div key={parent.Id}>
                    <div className={styles.termContainer}>
                        <span id={parent.Id} className={styles.iconTree} onClick={this.openCloseChildren(parent.Id)}>+</span>
                        <FormControlLabel className={styles.formControlLabel} control={control} label={parent.Name} />
                    </div>

                    <div style={{ marginLeft: "20px", display: "none" }} parent-id={parent.Id}>
                        {
                          renderResult
                        }
                    </div>
                </div>
            )
        }
        else {
            return (
                <div key={parent.Id} className={styles.termContainer}>
                    <span className={styles.iconTree} style={{ visibility: "hidden" }}>+</span>
                    <FormControlLabel className={styles.formControlLabel} control={control} label={parent.Name} />
                </div>
            )
        }
    }

    // Fonction qui cherche les termes de premier niveau et qui appelle en suite la fonction de création de l'arbre pour l'affichage
    private buildTreeView = (termsList: any[]) => {
        const parents = termsList.filter(term => term.IsRoot);
        return (
            parents.map(parent => {
                return this.buildTreeViewChildren(parent, termsList, this.props.multiSelect);
            })
        )
    }

    // Fonction permettant de faire remonter les valeurs sélectionnées au composant parent
    private sendDataToFather = (value: any) => {
        this.props.onChange(value);
    }

    render() {
        // const options = this.state.allTermes.map(term => {
        //     return {
        //         title: term.Name,
        //         id: term.Id
        //     }
        // });
        const paramMultiple: any = {};
        let value: any = this.state.termeSelected;
        if (this.props.multiSelect == true) {
            paramMultiple["multiple"] = true;
            value = this.state.termesSelectedMulti
        }
        return (
            <div>
                <div className={styles.taxoInputButtonContainer}>
                    <Autocomplete
                        value={value}
                        className={styles.taxoInput}
                        onChange={(event: any, newValue: string | null | any) => {
                            this.onChangeValueInput(newValue, this.props.multiSelect);
                        }}
                        // inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            if (newInputValue.length > 0 && this.state.allTermes.length == 0) {
                                this.setState({ loadingInput: true }, () => {
                                    this.getAllTermInTermSet(this.props.termSetId, this.props.anchorId);
                                });
                            }
                        }}
                        {...paramMultiple}
                        options={this.state.allTermes}
                        getOptionLabel={(option: any) => option.Name}
                        disableOpenOnFocus={true}
                        onBlur={this.props.onBlur}
                        loading={this.state.loadingInput}
                        loadingText="Chargement en cours..."
                        noOptionsText="Pas de résultat trouvé"
                        // style={{ width: 300 }}
                        disabled={this.props.disabled}
                        renderInput={(params) => <TextField {...params} label={this.props.label} required={this.props.required != undefined && this.props.required != null && this.props.required == true ? true : false} variant="outlined" />}
                    />
                    {
                        this.props.disabled == false ?
                            <div className={styles.iconContainer}>
                                <Icon className={styles.icon} onClick={this.openModal}>toc</Icon>
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                    }
                </div>
                <div>{this.props.helperText}</div>

                {/* // Modal pour afficher les termes à sélectionner */}
                <Dialog onClose={this.closeModal} aria-labelledby="taxonomy-dialog" open={this.state.openModal} classes={{ paper: styles.taxonomyModal }}>
                    <DialogTitle className={styles.modalTitle}>{this.props.multiSelect == true ? "Sélectionner des termes" : "Sélectionner un terme"}<IconButton aria-label="close" onClick={this.closeModal}><Icon>close</Icon></IconButton></DialogTitle>
                    <DialogContent className={styles.modalContent} dividers>
                        {
                            this.state.errorContent == true ?
                                <div>Une erreur est survenue, réessayez</div>
                                :
                                this.state.loadingContent == true ?
                                    <Spinner label="Chargement en cours..." labelPlacement="right" />
                                    :
                                    <div>
                                        {
                                            this.state.termSetInfo != null && this.state.termSetInfo.Name != undefined && this.state.termSetInfo.Name != null ?
                                                <h2>{this.state.termSetInfo.Name}</h2>
                                                :
                                                ""
                                        }
                                        {
                                            this.buildTreeView(this.state.allTermes)
                                        }
                                    </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={this.closeModal} variant="contained" color="primary">
                            Valider
                        </Button> */}
                        <Button onClick={this.closeModal} variant="contained" color="default">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}