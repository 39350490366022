import * as React from 'react'
import Store from '../store/store'
import axios from 'axios'
const styles = require('./styles/paramMail_styles.scss')
import TextField from '@material-ui/core/TextField'

interface State {
    mailSender ?: any
    password ?: any
    tempMailSender ?: any
    tempPassword ?: any

}
// interface Props {

// }


export default class paramMailSender extends React.Component<{}, State>{
   public constructor(props) {
        super(props)
            this.state = {
                  mailSender : undefined,
                  password : undefined,
                  tempMailSender : undefined,
                  tempPassword: undefined  
            }
        this.getSenderMail()
    }
    // getSenderMail = () => {
    //     axios.get(Store.wsPath + '/1/mailing/getSenderMail', {
    //         params : {
    //             id: 1 
    //         }
    //     }).then(response => {
    //         this.setState({mailSender : response.data.email})
    //     })
    // }

    // replaceSenderEmail = () => {
    //    axios.post(Store.wsPath + '/1/mailing/replaceSenderMail', {

    //    }).then(response => {
    //        this.setState({mailSender : response.data.email})
    //    })
    // }

    public onChange = (name : string) => event => {
        if(name == "MailSender") {
            this.setState({
                tempMailSender : event.target.value
            })
        }
        else if (name == "PassWord") {
            this.setState({
                tempPassword : event.target.value
            })
        }
    }

    public getSenderMail = () => {
        axios.get(Store.wsPath + '/1/mailing/getSenderMail', {
            params : {id: 1 }
        }).then((response => {
            this.setState({
                mailSender : response.data.mailAdress,
                password : response.data.password
            })
        }))
    }

    public changeInfoMailer = () => {
        axios.post(Store.wsPath + '/1/mailing/changeInfoMailer' , {
            newMailer : this.state.tempMailSender,
            newPassword : this.state.tempPassword
        }).then ((response => {
            if(response.status == 200) {
                Store.snackBar.error = false
                Store.snackBar.message = "La modification de l'expediteur des e-mails à bien été prise en compte."
                Store.snackBar.open = true
            }
        }))
    }

    public 

    public render() {
		return (
            <div>
				<div>
                    <TextField
                        label="Adresse Mail"
                        variant="outlined"
                        className={styles.groupInput}
                        value={this.state.mailSender}
                        onChange={this.onChange("MailSender")}
                    />
                </div>
                <div>
                    <TextField
                        label="Mot de passe "
                        variant="outlined"
                        className={styles.groupInput}
                        value={this.state.password}
                        onChange={this.onChange("PassWord")}
                    />
                </div>
            </div>
		);
    }
    
}
