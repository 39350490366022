import * as React from "react";
const styles = require("./styles/loadingLogo.scss");

export interface LoadingLogoProps {
    text?:string;
}

export interface LoadingLogoState {

}

export default class LoadingLogo extends React.Component<LoadingLogoProps, LoadingLogoState> {
    constructor(props: LoadingLogoProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className={styles.loadingLogoComponent}>
                <div className={styles.loadingLogoContainer}>
                    <div className={styles["cssload-cube"] + " " + styles["cssload-c1"]}></div>
                    <div className={styles["cssload-cube"] + " " + styles["cssload-c2"]}></div>
                    <div className={styles["cssload-cube"] + " " + styles["cssload-c4"]}></div>
                    <div className={styles["cssload-cube"] + " " + styles["cssload-c3"]}></div>
                </div>
                {this.props.text != undefined && this.props.text != null && this.props.text.length > 0 ?
                    this.props.text
                :
                <React.Fragment></React.Fragment>
                }
            </div>
        );
    }
}