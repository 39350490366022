import * as React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  DateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";

interface IDateEditorProps {
  onUpdate: (date:Date) => (event:any) => void;
  defaultValue: Date | string;
  onBlur: () => any;
  row: any;
}

interface IDateEditorState {
  value: Date;
}

export default class DateEditor extends React.Component<IDateEditorProps, IDateEditorState> {
  constructor(props: IDateEditorProps) {
    super(props);
    this.state = {
      value: this.props.defaultValue != undefined && this.props.defaultValue != null && this.props.defaultValue != "" ? new Date(this.props.defaultValue) : null
    };
  }

  public onChange = (event) => {
    if(event != "Invalid Date") {
      this.setState({value:event}, () => {
        this.props.onUpdate(this.state.value);
      });
    }
  }

  render() {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <DateTimePicker
            ampm={false}
            variant="dialog"
            inputVariant="outlined"
            format="dd/MM/yyyy HH:mm"
            margin="normal"
            value={this.state.value}
            onChange={this.onChange}
            style={{margin:0}}
            // inputProps={{
            //   onBlur: this.saveData
            // }}
            // onAccept={this.saveData}
            showTodayButton
            todayLabel="Aujourd'hui"
            cancelLabel="Annuler"
          />
        </MuiPickersUtilsProvider>
    );
  }
}