import * as React from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import Store from '../store/store';
import TextField from '@material-ui/core/TextField';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import { getAllUsers } from '../store/store';
import cookie from "react-cookies";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
const styles = require('./styles/Me.scss');
interface Props {

}
interface State {
  checkboxOpenTask?: boolean,
  checkboxModifTask?: boolean,
  checkboxClotureTask?: boolean,
  loading: boolean,
  updatedUservalue: { lastname: string, firstname: string, email: string };
  openDeconnectModal: boolean;
}

export default class Me extends React.Component<RouteComponentProps<any>, State>{
  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      checkboxOpenTask: false,
      checkboxModifTask: false,
      checkboxClotureTask: false,
      updatedUservalue: { lastname: Store.userConnected.lastname, firstname: Store.userConnected.firstname, email: Store.userConnected.email },
      openDeconnectModal: false
    }
    this.getParamMail()
  }

  getParamMail() {
    axios.get(Store.wsPath + '/1/mailing/getParamMailUser', {
      params: {
        idUser: Store.userConnected.id
      }
    })
      .then(response => {
        this.setState({ checkboxOpenTask: response.data.ouvertureTache, checkboxModifTask: response.data.modifTache, checkboxClotureTask: response.data.clotureTache, loading: false })
      })
      .catch(error => {
        this.setState({ checkboxOpenTask: false, checkboxModifTask: false, checkboxClotureTask: false, loading: false })
        Store.snackBar = {
          message: "Erreur lors de la récupération de données.",
          open: true,
          error: true
        }
      })
  }

  buttonFormat = status => event => {
    if (status == 'Open') {
      this.setState({ checkboxOpenTask: event.target.checked }, )
    }
    if (status == 'Modif') {
      this.setState({ checkboxModifTask: event.target.checked }, )
    }
    if (status == 'Close') {
      this.setState({ checkboxClotureTask: event.target.checked }, )
    }
  }

  onClickButtonValidation() {
    axios.post(Store.wsPath + '/1/mailing/changeParamMailUser', {
      idUser: Store.userConnected.id,
      ouvertureTache: this.state.checkboxOpenTask,
      modifTache: this.state.checkboxModifTask,
      clotureTache: this.state.checkboxClotureTask
    })
      .then(response => {
        if (response.status == 200) {
          Store.snackBar.message = "Paramétrage des mails mis à jour";
          Store.snackBar.open = true;
        }
        else {
          Store.snackBar.message = "Erreur lors de la mise à jour";
          Store.snackBar.open = true;
          Store.snackBar.error = true;
        }
      })
  }
  public onEditUser = (event) => {
    event.preventDefault();
    axios.post(Store.wsPath + '/1/users/updateUser', {
      id: Store.userConnected.id,
      lastname: this.state.updatedUservalue.lastname,
      firstname: this.state.updatedUservalue.firstname,
      login: Store.userConnected.login,
      email: this.state.updatedUservalue.email,
      civility: Store.userConnected.civility,
      typeUserLydoc: Store.userConnected.typeUserLydoc,
      formRights: Store.userConnected.formRights
    }).then(response => {
      getAllUsers();
      Store.userConnected.lastname = this.state.updatedUservalue.lastname,
        Store.userConnected.firstname = this.state.updatedUservalue.firstname,
        Store.userConnected.email = this.state.updatedUservalue.email
      // SnackBar
      Store.snackBar.message = "Vos informations ont été mises à jour"
      Store.snackBar.open = true;
    })

  }

  onChangeUser = (name) => event => {
    const tempValue = this.state.updatedUservalue
    if (name == "firstname") {
      tempValue.firstname = event.target.value;
    } else if (name == "lastname") {
      tempValue.lastname = event.target.value;
    } else if (name == "email") {
      tempValue.email = event.target.value;
    }
    this.setState({ updatedUservalue: tempValue });
  }

  // Fonction appelée pour la deconnexion d'un utilisateur
  public onDeconnectUser = async () => {
    cookie.remove("token");
    if (Store.authMode == "AAD") {
      const idUser: number = Store.userConnected.id;
      await window.location.assign(Store.wsPath + "/1/authentication/aadLogout?idUser=" + idUser);
    } else {
      this.props.history.push("/");
      Store.userConnected = {};
    }
  };

  // Fonction pour ouvrir la modale de confirmation de déconnexion
  public openCloseDecoModal = (open?:boolean) => (event: any) => {
    if(open == true) {
      this.setState({ openDeconnectModal: true });
    }
    else {
      this.setState({ openDeconnectModal: false });
    }
  }

  public render() {
    return (
      <div className={styles.mainContent}>
        <div className={styles.titleParam} style={{ background: Store.themeParam.backgroundPrimary }}>
          <h5>Paramètres du compte :</h5>
        </div>
        <div style={{ margin: "0 20px 50px 20px" }}>
          <form autoComplete="false" onSubmit={this.onEditUser}>
            <div>
              <TextField
                variant='outlined'
                className={styles.field}
                required
                type="text"
                value={this.state.updatedUservalue.lastname}
                onChange={this.onChangeUser('lastname')}
                label="Nom"
              />
            </div>
            <div>
              <TextField
                name="firstname"
                value={this.state.updatedUservalue.firstname}
                label="Prénom"
                onChange={this.onChangeUser("firstname")}
                margin="normal"
                variant='outlined'
                type="text"
                className={styles.field}
              />
            </div>
            <div>
              <TextField
                name="email"
                value={this.state.updatedUservalue.email}
                label="Email de l'utilisateur"
                onChange={this.onChangeUser("email")}
                margin="normal"
                variant='outlined'
                required
                type="email"
                className={styles.field}
              />
            </div>
            <Button
              type="submit"
              // className={styles.btnUser + " primaryColor"}
              variant="contained"
              color="primary"
            >
              Enregister les modifications
            </Button>
          </form>

          <Button
            variant="contained"
            color="secondary"
            startIcon={<PowerSettingsNewIcon />}
            onClick={this.openCloseDecoModal(true)}
            style={{ marginTop: "15px" }}
          >
            Déconnexion
          </Button>


          {/* MODAL pour la confirmation de déconnexion */}
          <Dialog
            maxWidth='xs'
            open={this.state.openDeconnectModal}
            onClose={this.openCloseDecoModal}
            aria-labelledby="Déconnexion"
            aria-describedby="Fenêtre de déconnexion"
          >
            <DialogTitle>
              <div>Déconnexion</div>
            </DialogTitle>
            <DialogContent>
              <p>Voulez vous vraiment vous déconnecter ?</p>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.openCloseDecoModal()}>
                annuler
              </Button>
              <Button variant="contained" onClick={this.onDeconnectUser} color="secondary" autoFocus>
                Déconnexion
              </Button>
            </DialogActions>
          </Dialog>

        </div>
        <div className={styles.titleParam} style={{ background: Store.themeParam.backgroundPrimary }}>
          <h5>Paramètres des mails:</h5>
        </div>
        <div className={styles.checkbox} style={{ marginLeft: "20px" }}>
          <div>
            Envoyer un mail automatiquement lors de l'ouverture d'une de vos tâches ?
                  <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.checkboxOpenTask} onChange={this.buttonFormat('Open')} />
          </div>
          <div>
            Envoyer un mail automatiquement lors de la modification d'une de vos tâches ?
                  <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.checkboxModifTask} onChange={this.buttonFormat('Modif')} />
          </div>
          <div>
            Envoyer un mail automatiquement lors de la cloture d'une de vos tâches ?
                  <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.checkboxClotureTask} onChange={this.buttonFormat('Close')} />

          </div>
        </div>
        <div className={styles.button} style={{ marginLeft: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => { this.onClickButtonValidation() }}
            disabled={this.state.loading}
          >
            {this.state.loading ?
              <Spinner label="Chargement des données..." size={16} />
              :
              "Enregistrer les préférences des mails"
            }
          </Button>
        </div>
      </div>
    )
  }
}
