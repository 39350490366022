import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IColumnInfo } from '../interfaces/IRenderForm';

interface IFileProps {
  field: IColumnInfo;
  value: string;
  readOnly?: boolean;
  onChange?: (question:IColumnInfo, type?:string) => (event: any, newValue?: string) => void;
}

interface IFileState {

}

class File extends React.Component<IFileProps, IFileState> {
  constructor(props: IFileProps) {
    super(props);
    this.state = {};
  }
  render() {
    if(this.props.readOnly != undefined && this.props.readOnly == true) {
      return (
        <TextField
          label={this.props.field.createData.Title}
          name={this.props.field.internalName}
          defaultValue={'Le document "'+this.props.field.createData.Title+'" est disponible dans la partie Documents associés'}
          noValidate
          required={this.props.field.createData.Required}
          description={this.props.field.createData.Description}
          readOnly={this.props.readOnly != undefined ? this.props.readOnly : false}
        />
      );
    }
    else {
      const onChangeParam:any = this.props.onChange != undefined && this.props.onChange != null ? {onChange:this.props.onChange(this.props.field)} : {}; 
      return (
        <TextField
          type="file"
          label={this.props.field.createData.Title}
          name={this.props.field.internalName}
          defaultValue={this.props.value}
          noValidate
          borderless={true}
          accept=".jpg, .jpeg, .png, .pdf"
          required={this.props.field.createData.Required}
          description={this.props.field.createData.Description}
          readOnly={this.props.readOnly != undefined ? this.props.readOnly : false}
          // onChange={this.props.onChange(this.props.field)}
          key={this.props.field.createData.Title}
          {...onChangeParam}
        />
      );
    }
  }
}

export default File;