import * as React from 'react';
import { IColumnInfo } from '../interfaces/IRenderForm';

interface ParagrapheProps {
    field:IColumnInfo;
    value?: string;
}

interface ParagrapheState {

}

export default class Paragraphe extends React.Component<ParagrapheProps, ParagrapheState> {
    constructor(props: ParagrapheProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <p style={{margin:"10px 0"}}>{this.props.field.createData.Description}</p>
        );
    }
}