import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import Button  from "@material-ui/core/Button";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import styles from "./styles/orientation.module.scss";
import { IDBWfStep, IDBWorkflow, IModelWf } from "./interfaces/IWorkflow";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Icon from "@material-ui/core/Icon";
import Store from "../store/store";
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from "@material-ui/core/IconButton";
import AppsIcon from "@material-ui/icons/Apps";
import ListIcon from "@material-ui/icons/List";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Spinner from "../Spinner/Spinner";
interface IOrientationProps {
  workflow: IDBWorkflow;
  selectedStep :IDBWfStep;
  childModels:IModelWf[];
  mode:string;
}

interface IOrientationState {
  isModalOpen:boolean;
  idOrientationModel:number;
  displayMode:string;
  orientationLoad:boolean;

}

@observer
class Orientation extends React.Component<RouteComponentProps<{}> & any, IOrientationState> {
  constructor(props: IOrientationProps) {
    super(props);
    this.state = {
      isModalOpen:false,
      idOrientationModel:undefined,
      displayMode:"grid",
      orientationLoad:false
    };
  }

  public componentDidMount() {

  }
  public handleClose = () => {
    this.setState({isModalOpen:false,idOrientationModel:undefined,orientationLoad:false,displayMode:"grid"});
  };
  public onClickChangeDisplayMode =  (mode:string) => ()=>{
    this.setState({displayMode:mode});
  }
  public onClickList =(id)=>async (event)=>{

    this.setState({idOrientationModel: id});
  }
  public onValidateOrientation =async (event)=>{
    this.setState({orientationLoad:true})
    axios.post(Store.wsPath + '/1/workflows/orienteProject', {
        idModel: this.state.idOrientationModel,
        idUserConnected: Store.userConnected.id,
        parentOrientationStepRank: this.props.selectedStep.rankStep,
        idParentStep:this.props.selectedStep.id,
        modeSharepoint:Store.modeSharepoint
    }).then((response) => {
      this.setState({isModalOpen:false,idOrientationModel:undefined,orientationLoad:false,displayMode:"grid"});
      if (response.data.stayOnSameProject == true) {
        this.props.history.push("/refresh?redir=document?w="+Store.workflow.id);
      } else {
        this.props.history.push("/refresh?redir=document");
      }
    }).catch(error => {
      console.error("Error 'onValidateOrientation' : ", error);
      this.setState({orientationLoad: false});
      Store.snackBar = {
        message:"Une erreur est survenue, réessayez",
        error:true,
        open:true
      }
    })
  }
 
  render() {
    return(
      <div className={styles.orientationContainer} >
        <div className={styles.buttonContainer}>
          <Button disabled={this.props.mode == "view"?true:false} size="small" onClick={() => { this.setState({isModalOpen:true}) }} className={styles.customButton}  variant="contained" color="primary">
            Orienter
          </Button>
        </div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="dialogTitle"
          open={this.state.isModalOpen}
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle id="dialogTitle">Orientez la suite de votre circuit</DialogTitle>
          <DialogContent dividers>
          {this.state.orientationLoad ?
            <div className={styles.formControlModelSelect}>
              <Spinner  label={"Orientation en cours ..."}/>
            </div>
            :
            <div className={styles.formControlModelSelect}>
              <div style={{display  :"flex"}}>
                <div style={{alignItems: "center",display: "flex"}}>Affichage :</div>
                <IconButton disableRipple style={{padding: "0 4px 0 0 !important",color:this.state.displayMode == "grid"?"black":"grey"}} aria-label="Grille" onClick={this.onClickChangeDisplayMode("grid")}>
                  <AppsIcon fontSize="small"/>
                </IconButton>
                <IconButton disableRipple style={{padding: "0 !important",color:this.state.displayMode == "list"?"black":"grey"}}  aria-label="Liste" onClick={this.onClickChangeDisplayMode("list")}>
                  <ListIcon fontSize="small"/>
                </IconButton>
              </div>
              {
                this.state.displayMode == "grid"?
                  <Grid container className={styles.gridContainer}>
                    {this.props.childModels.map((model, index) => {
                      const id = model.id
                      let cssSelectionned: string = ""
                      if (id == this.state.idOrientationModel) {
                        cssSelectionned = "selected"
                      }
                      return (
                        <Grid key={model.icon != null && model.icon != undefined ? model.icon.label + index : "icon" + index} item lg={3} sm={3} xs={5} className={styles.cardsContainer}>
                          <Card title={model.name} onClick={this.onClickList(model.id)}>
                            <CardContent className={styles.modelOrientation + " " + cssSelectionned}>{model.name}</CardContent>
                            <div className={styles.iconCards}><Icon fontSize="large"  >{model.icon != null && model.icon != undefined ? model.icon.label : ""}</Icon></div>
                          </Card>
                        </Grid>
                      )
                    })}
                  </Grid>
                :this.state.displayMode == "list"?
                  <List dense={true}>
                    {
                      this.props.childModels.map(cM=>{
                        const id = cM.id
                        let cssSelectionned: string = ""
                        if (id == this.state.idOrientationModel) {
                          cssSelectionned = "selected"
                        }
                        return(
                          <ListItem style={{cursor: "pointer"}} className={cssSelectionned}>
                            <ListItemAvatar>
                              <Avatar >
                                <Icon>{cM.icon.label}</Icon>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              onClick={this.onClickList(cM.id)}
                              primary={cM.name}
                            />
                          </ListItem>
                        )
                      })
                    }
                  </List>
                  :""
              }
            </div>
          }
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.handleClose} className={styles.customButtonDialogCancel} variant="contained" color="secondary">
              Annuler
            </Button>
            <Button onClick={this.onValidateOrientation} disabled={this.state.idOrientationModel == undefined || this.state.orientationLoad == true} className={styles.customButtonDialogValid} variant="contained" color="primary">
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
export default withRouter(Orientation);