import * as React from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import axios from "axios";
import Store from "../../../../store/store";
import { IDBWorkflow } from "../../../../Workflow/interfaces/IWorkflow";
import DateEditor from "../dateEditor/DateEditor";
import MultilineEditor from "../multilineEditor/MultilineEditor";
import TextEditor from "../textEditor/TextEditor";
import Button from "@material-ui/core/Button";
import Dialog, { DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { IFormInfo } from "../../../renderForm/interfaces/IRenderForm";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import SpinnerMU from "../../../../Spinner/Spinner";
import Switch from "@material-ui/core/Switch/Switch";
import FormControlLabel from "../../../../../../node_modules/@material-ui/core/FormControlLabel/FormControlLabel";


interface IConvocationMasseProps {
  form: IFormInfo;
}

interface IConvocationMasseState {
  demandeLiees: any[];
  loading: boolean;
  error: boolean;
  errorMessage: string;
  convocDialogParam: {
    error: boolean;
    errorMessage: string;
    loading: boolean;
    hideDialog: boolean;
    type: string;
    wfToDenied: IDBWorkflow[];
  },
  loadingIndicator: boolean;
  addInfoOnAllPopper: {
    open: boolean;
    elAnchor: any;
    dataForUpdateAllDemande: {
      convocPlace?: string;
      convocLink?: string;
      convocInfo?: string;
      eraseValue: boolean;
    };
    loading: boolean;
  }
}

export default class ConvocationMasse extends React.Component<IConvocationMasseProps, IConvocationMasseState> {
  constructor(props: IConvocationMasseProps) {
    super(props);
    this.state = {
      demandeLiees: [],
      loading: true,
      error: false,
      errorMessage: "",
      convocDialogParam: {
        error: false,
        errorMessage: "",
        loading: true,
        hideDialog: true,
        type: "convocations",
        wfToDenied: []
      },
      loadingIndicator: false,
      addInfoOnAllPopper: {
        open: false,
        elAnchor: null,
        dataForUpdateAllDemande: {
          convocInfo: "",
          convocLink: "",
          convocPlace: "",
          eraseValue: false
        },
        loading: false
      }
    };
  }

  public componentDidMount() {
    if (this.props.form != undefined && this.props.form != null) {
      this.getAllDemande(this.props.form.id);
    }
  }

  public componentDidUpdate(prevProps: IConvocationMasseProps, prevState: IConvocationMasseState) {
    if (this.props.form != undefined && this.props.form != null && this.props.form.id != prevProps.form.id) {
      this.getAllDemande(this.props.form.id);
    }
  }

  public getAllDemande = (idForm: number) => {
    this.setState({ loading: true, error: false, errorMessage: "" }, async () => {
      try {
        const allDemande = await this.getAllDemandeLiees(idForm);
        const allDemandeFormated = allDemande.map((wf: IDBWorkflow) => {
          let recruit_Prenom: string = "";
          let recruit_Nom: string = "";
          if (wf.Document != undefined && wf.Document != null) {
            recruit_Prenom = wf.Document.dataSharepoint.recruit_Prenom != undefined && wf.Document.dataSharepoint.recruit_Prenom != null ? wf.Document.dataSharepoint.recruit_Prenom : "";
            recruit_Nom = wf.Document.dataSharepoint.recruit_Nom != undefined && wf.Document.dataSharepoint.recruit_Nom != null ? wf.Document.dataSharepoint.recruit_Nom : "";
          }
          wf["recruit_Prenom"] = recruit_Prenom;
          wf["recruit_Nom"] = recruit_Nom;

          let convocDate = "";
          let convocPlace = "";
          let convocLink = "";
          let convocInfo = "";

          if (wf.convocationData != undefined && wf.convocationData != null) {
            if (wf.convocationData.convocDate != undefined && wf.convocationData.convocDate != null && wf.convocationData.convocDate != "") {
              convocDate = wf.convocationData.convocDate;
            }
            if (wf.convocationData.convocPlace != undefined && wf.convocationData.convocPlace != null && wf.convocationData.convocPlace != "") {
              convocPlace = wf.convocationData.convocPlace;
            }
            if (wf.convocationData.convocLink != undefined && wf.convocationData.convocLink != null && wf.convocationData.convocLink != "") {
              convocLink = wf.convocationData.convocLink;
            }
            if (wf.convocationData.convocInfo != undefined && wf.convocationData.convocInfo != null && wf.convocationData.convocInfo != "") {
              convocInfo = wf.convocationData.convocInfo;
            }
          }
          wf["convocDate"] = convocDate;
          wf["convocPlace"] = convocPlace;
          wf["convocLink"] = convocLink;
          wf["convocInfo"] = convocInfo;

          return { ...wf };
        });
        this.setState({ demandeLiees: [...allDemandeFormated], loading: false, error: false, errorMessage: "" });
      } catch (error) {
        console.error("Error 'getAllDemande' : ", error);
        this.setState({ loading: false, error: true, errorMessage: "Erreur lors de la récupération des demandes liées, veuillez réessayer" });
      }
    });
  }

  // Fonction qui appelle le back pour récupérer les wf en cours avec une étape de convocation en cours
  public getAllDemandeLiees = (idForm: number) => {
    return new Promise<IDBWorkflow[]>(async (resolve, reject) => {
      try {
        const result = await axios.post(Store.wsPath + "/1/formulaire/GetAllLinkedFormConvoc", {
          idForm: idForm,
          userConnected: Store.userConnected
        });
        const allDemandes: IDBWorkflow[] = result.data;
        resolve(allDemandes);
      } catch (error) {
        console.error("Error 'getAllDemandeLiees' : ", error);
        reject(error);
      }
    });
  }

  // Fonction appelée lors de la sélection d'une ligne dans le tableau
  public onSelectConvoc = (row: any, isSelected: boolean, e) => {
    const stateDemande = [...this.state.demandeLiees];
    const stateEdited = stateDemande.map(wf => {
      if (wf.id == row.id) {
        wf["selected"] = isSelected;
      }
      return wf;
    });
    this.setState({ demandeLiees: stateEdited });
  }

  // Fonction appelée lors de la sélection de toutes les lignes dans le tableau
  public onSelectAllConvoc = (isSelected, rows) => {
    const stateDemande = [...this.state.demandeLiees];
    rows.forEach(wf => {
      const findWfInList = stateDemande.findIndex(w => w.id == wf.id);
      if (findWfInList != -1) {
        stateDemande[findWfInList]["selected"] = isSelected;
      }
    });
    this.setState({ demandeLiees: stateDemande });
  }

  // // Fonction appelée pour formater les utilisateurs dans le tableau
  // public formatName = (type: "firstName" | "lastName") => (cell,row) => {
  //   let returnValue = "";
  //   if(row.Document != undefined && row.Document != null) {
  //     const dataSharepoint = row.Document.dataSharepoint;
  //     if(type == "firstName") {
  //       returnValue = dataSharepoint.recruit_Prenom;
  //     }
  //     else {
  //       returnValue = dataSharepoint.recruit_Nom;
  //     }
  //   }
  //   return returnValue;
  // }

  // Fonction appelée pour formater les dates pour l'affichage
  public formatDate = (time?: boolean) => (cell: string, row): string => {
    let date: string = "";
    if (cell != undefined && cell != null && cell != "") {
      const cellDate = new Date(cell);
      date = (cellDate.getDate() < 10 ? "0" + cellDate.getDate() : cellDate.getDate()) + "/" + ((cellDate.getMonth() + 1) < 10 ? "0" + (cellDate.getMonth() + 1) : (cellDate.getMonth() + 1)) + "/" + cellDate.getFullYear();
      if (time == true) {
        date = date + " " + (cellDate.getHours() < 10 ? "0" + cellDate.getHours() : cellDate.getHours()) + ":" + (cellDate.getMinutes() < 10 ? "0" + cellDate.getMinutes() : cellDate.getMinutes());
      }
    }
    return date;
  }

  // Fonction appelée pour enregistrer les valeurs saisies dans les cellules modifiable
  public saveEditCellValue = (row, cellName, cellValue) => {
    const stateDemande = [...this.state.demandeLiees];
    const stateEdited = stateDemande.map(wf => {
      if (wf.id == row.id) {
        wf[cellName] = cellValue;
        if(wf.convocationData == undefined || wf.convocationData == null){
          wf.convocationData = {};
        }
        wf.convocationData[cellName] = cellValue;
      }
      return wf;
    });
    this.setState({ demandeLiees: [...stateEdited], loadingIndicator: true }, async () => {
      try {
        await this.saveConvocValueInWf(row.id, cellName, cellValue);
        this.setState({ loadingIndicator: false });
      } catch (error) {
        this.setState({ loadingIndicator: false }, () => {
          Store.snackBar = {
            error: true,
            message: "Une erreur est survenue lors de la mise à jour des données",
            open: true
          };
        });
      }
    });
  }

  // Fonction appelée pour enregistrer les valeurs saisies dans la pop up dans toutes les cellules modifiable
  public saveEditCellValueOnAll = () => {
    this.setState({ addInfoOnAllPopper: { ...this.state.addInfoOnAllPopper, loading: true } }, async () => {
      try {
        const dataPopover = this.state.addInfoOnAllPopper.dataForUpdateAllDemande;
        const allDemande = this.state.demandeLiees;
        // WS mets à jour toute les infos sur les wf
        await axios.post(Store.wsPath + "/1/formulaire/SaveConvocValueInAllWf", {
          dataValue: dataPopover,
          allWF: allDemande
        });
        Store.snackBar = {
          message: "Valeurs ajoutées à toutes les convocations",
          error: false,
          open: true
        };
        this.openCloseAddOnAll("");
        this.getAllDemande(this.props.form.id);
      } catch (error) {
        console.error("Error 'saveEditCellValueOnAll' : ", error);
        this.setState({ addInfoOnAllPopper: { ...this.state.addInfoOnAllPopper, loading: false } });
        Store.snackBar = {
          message: "Une erreur est survenue lors de l'ajout des valeurs à toutes les convocations",
          error: true,
          open: true
        };
      }
    });
  }

  // Fonction appelée dans le popper pour changer les valeurs
  public onChangeAddOnAll = (key: string) => event => {
    const dataConvoc = { ...this.state.addInfoOnAllPopper.dataForUpdateAllDemande };
    if(key == "eraseValue"){
      dataConvoc[key] = event.target.checked;
    }
    else {
      dataConvoc[key] = event.target.value;
    }
    this.setState({ addInfoOnAllPopper: { ...this.state.addInfoOnAllPopper, dataForUpdateAllDemande: dataConvoc } });
  }

  // Fonction qui appelle le back pour mettre à jour le wf avec les infos saisies dans les colonnes editable
  public saveConvocValueInWf = (idWF: number, key: string, value: any) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await axios.post(Store.wsPath + "/1/formulaire/SaveConvocValueInWf", {
          idWf: idWF,
          key: key,
          value: value
        });
        resolve();
      } catch (error) {
        console.error("Error 'saveEditValueInWf' : ", error);
        reject(error);
      }
    });
  }

  // Fonction appelée lors du click sur le bouton convoquer
  public openCloseConvocModal = (open: boolean) => event => {
    if (open == true) {
      this.setState({ convocDialogParam: { ...this.state.convocDialogParam, hideDialog: false, loading: false, error: false, errorMessage: "", wfToDenied: [] } });
    }
    else {
      this.setState({ convocDialogParam: { ...this.state.convocDialogParam, hideDialog: true, loading: false, error: false, errorMessage: "", wfToDenied: [] } });
    }
  }

  // Fonction appelée pour terminer et envoyer les convocations
  public sendConvocations = () => {
    this.setState({ convocDialogParam: { ...this.state.convocDialogParam, loading: true, error: false, errorMessage: "", type: "convocations" } }, async () => {
      try {
        const allWfSelected = this.state.demandeLiees.filter(wf => wf.selected == true);
        const responseConvoc = await axios.post(Store.wsPath + "/1/formulaire/SendConvocations", {
          form: this.props.form,
          allWfSelected: allWfSelected,
          userConnected: Store.userConnected,
          modeSharePoint: Store.modeSharepoint
        });
        const projectToDenied = responseConvoc.data;
        // On enlève les convoc qui ont étaient faites
        const allDemande = [...this.state.demandeLiees.filter(wf => wf.selected != true)];
        this.setState({ convocDialogParam: { ...this.state.convocDialogParam, loading: false, type: "denied", wfToDenied: projectToDenied, errorMessage: "" }, demandeLiees: allDemande });
      } catch (error) {
        console.error("Error 'sendConvocations' : ", error);
        this.setState({ convocDialogParam: { ...this.state.convocDialogParam, loading: false, type: "convocations", error: true, errorMessage: "Erreur lors de l'envoi des convocations" } });
      }
    });
  }

  // Fonction appelée pour refuser les wfs des candidatures qui n'ont pas étaient retenues
  public deniedWf = () => {
    this.setState({ convocDialogParam: { ...this.state.convocDialogParam, loading: true, type: "denied", error: false, errorMessage: "" } }, async () => {
      try {
        const allWfSelectedForDenied = this.state.convocDialogParam.wfToDenied;
        await axios.post(Store.wsPath + "/1/formulaire/DeniedConvocations", {
          deniedWfs: allWfSelectedForDenied,
          userConnected: Store.userConnected,
          modeSharePoint: Store.modeSharepoint
        });
        this.setState({ convocDialogParam: { ...this.state.convocDialogParam, loading: false, type: "deniedSuccess", errorMessage: "", error: false } });
      } catch (error) {
        console.error("Error 'deniedWf' : ", error);
        this.setState({ convocDialogParam: { ...this.state.convocDialogParam, loading: false, type: "denied", wfToDenied: [], error: true, errorMessage: "Erreur lors du refus des candidatures" } });
      }
    });
  }

  public openCloseAddOnAll = (event) => {
    this.setState({ addInfoOnAllPopper: { ...this.state.addInfoOnAllPopper, elAnchor: event.currentTarget, open: !this.state.addInfoOnAllPopper.open, dataForUpdateAllDemande: { convocInfo: "", convocLink: "", convocPlace: "", eraseValue: false }, loading: false } });
  };

  public checkConditionForConvocation = () => {
    let condition = "noSelection";
    if(this.state.demandeLiees.filter(wf => wf.selected == true).length == 0) {
      condition = "noSelection";
    }
    else {
      const selectedConvoc = this.state.demandeLiees.filter(wf => wf.selected == true);
      const selectedConvocNumber = this.state.demandeLiees.filter(wf => wf.selected == true).length;
      const convocNumberLinkOrPlace = selectedConvoc.filter(convoc => (convoc.convocPlace != undefined && convoc.convocPlace != null && convoc.convocPlace != "") || (convoc.convocLink != undefined && convoc.convocLink != null && convoc.convocLink != "")).length;
      
      if(convocNumberLinkOrPlace == selectedConvocNumber) {
        condition = "ok";
      }
      else {
        condition = "noRequire";
      }
    }
    return condition;
  };

  render() {
    // Variable pour savoir si on désactive le bouton ou pas
    const disableButton = this.checkConditionForConvocation();
    return (
      <div>
        {
          this.state.error == true ?
            <p style={{ textAlign: "center", padding: "10px" }}>{this.state.errorMessage}</p>
            :
            this.state.loading == true ?
              <Spinner size={SpinnerSize.large} label={"Chargement des convocations en cours..."} labelPosition="bottom" />
              :
              <React.Fragment>
                <div style={{ height: "34px" }}>
                  {
                    this.state.loadingIndicator == true ?
                      <ProgressIndicator description="Mise à jour des données en cours..." />
                      :
                      <React.Fragment></React.Fragment>
                  }
                </div>
                {
                  this.state.demandeLiees.length > 0 ?
                    <Button
                      component="span"
                      variant="contained"
                      color="primary"
                      onClick={this.openCloseAddOnAll}
                      disabled={this.state.addInfoOnAllPopper.loading == true ? true : false}
                    >
                      Appliquer à tous
                    </Button>
                    :
                    <React.Fragment></React.Fragment>
                }
                <BootstrapTable
                  containerStyle={{ marginTop: "-30px" }}
                  data={this.state.demandeLiees}
                  searchPlaceholder='Rechercher dans la liste'
                  search={true}
                  pagination
                  options={{
                    noDataText: "Pas de convocation en cours pour ce formulaire",
                  }}
                  selectRow={{
                    mode: 'checkbox',
                    bgColor: function (row, isSelect) {
                      if (isSelect) {
                        return '#e7e7e7';
                      }
                      return null;
                    },
                    onSelect: this.onSelectConvoc,
                    onSelectAll: this.onSelectAllConvoc
                  }}
                  cellEdit={{
                    mode: 'click',
                    blurToSave: true,
                    afterSaveCell: this.saveEditCellValue
                  }}
                  bordered={false}
                  striped
                >
                  <TableHeaderColumn dataField='id' hidden isKey={true}>id</TableHeaderColumn>
                  <TableHeaderColumn dataField='realDateBegin' dataSort={true} dataFormat={this.formatDate(false)} maxWidth="" editable={false}>Date candidature</TableHeaderColumn>
                  <TableHeaderColumn dataField='recruit_Nom' dataSort={true} editable={false}>Nom candidat</TableHeaderColumn>
                  <TableHeaderColumn dataField='recruit_Prenom' dataSort={true} editable={false}>Prénom candidat</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='convocDate'
                    dataSort={true}
                    editable={true}
                    customEditor={{
                      getElement: (onUpdate, props) => (<DateEditor onUpdate={onUpdate} {...props} />)
                    }}
                    dataFormat={this.formatDate(true)}
                  >
                    Date de convocation
                </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='convocPlace'
                    dataSort={true}
                    editable={true}
                    customEditor={{
                      getElement: (onUpdate, props) => (<TextEditor onUpdate={onUpdate} {...props} />)
                    }}
                  >
                    Lieu
                </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='convocLink'
                    dataSort={true}
                    editable={true}
                    customEditor={{
                      getElement: (onUpdate, props) => (<TextEditor onUpdate={onUpdate} {...props} />)
                    }}
                  >
                    Lien visio
                </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='convocInfo'
                    dataSort={true}
                    editable={true}
                    customEditor={{
                      getElement: (onUpdate, props) => (<MultilineEditor onUpdate={onUpdate} {...props} />)
                    }}
                  >
                    Info supplémentaire
                </TableHeaderColumn>
                </BootstrapTable>
                <Button
                  disabled={disableButton != "ok" ? true : false}
                  component="span"
                  variant="contained"
                  color="primary"
                  onClick={this.openCloseConvocModal(true)}
                >
                  Convoquer
                </Button>
                {
                  disableButton == "noRequire" ?
                    <p style={{ marginTop: "10px", color: "orange"}}>Des données sont manquantes pour effectuer les convocations, veuillez les vérifier.</p>
                  :
                  <React.Fragment></React.Fragment>
                }
              </React.Fragment>
        }
        <Popper open={this.state.addInfoOnAllPopper.open} anchorEl={this.state.addInfoOnAllPopper.elAnchor} placement="bottom-start" transition style={{ zIndex: 9999999 }}>
          <Paper>
            <div style={{ maxWidth: "340px", display: "flex", flexDirection: "column", padding: "20px" }}>
              <h5>Ajouter à toutes les convocations</h5>
              <TextField
                label="Lieu"
                name="convocPlace"
                key="convocPlace"
                variant="outlined"
                onChange={this.onChangeAddOnAll("convocPlace")}
                disabled={this.state.addInfoOnAllPopper.loading == true ? true : false}
                style={{ marginTop: "10px" }}
              />
              <TextField
                label="Lien visio"
                name="convocLink"
                key="convocLink"
                variant="outlined"
                onChange={this.onChangeAddOnAll("convocLink")}
                disabled={this.state.addInfoOnAllPopper.loading == true ? true : false}
                style={{ marginTop: "10px" }}
              />
              <TextField
                label="Info supplémentaire"
                name="convocInfo"
                key="convocInfo"
                variant="outlined"
                multiline
                minRows={3}
                onChange={this.onChangeAddOnAll("convocInfo")}
                disabled={this.state.addInfoOnAllPopper.loading == true ? true : false}
                style={{ marginTop: "10px" }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.addInfoOnAllPopper.dataForUpdateAllDemande.eraseValue}
                    onChange={this.onChangeAddOnAll("eraseValue")}
                    name="eraseValue"
                    color="primary"
                  />
                }
                label="Écraser les valeurs existantes"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={this.saveEditCellValueOnAll}
                disabled={this.state.addInfoOnAllPopper.loading == true ? true : false}
                style={{ marginTop: "10px" }}
              >
                {
                  this.state.addInfoOnAllPopper.loading == true ?
                    <SpinnerMU size={20} />
                    :
                    <React.Fragment>Appliquer</React.Fragment>
                }
              </Button>
            </div>
          </Paper>
        </Popper>
        <Dialog
          hidden={this.state.convocDialogParam.hideDialog}
          onDismiss={this.openCloseConvocModal(false)}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Convocations",
            closeButtonAriaLabel: 'Fermer',
            showCloseButton: false
          }}
          modalProps={{
            isBlocking: true
          }}
        >
          {this.state.convocDialogParam.error == true ?
            <React.Fragment>
              <p>{this.state.convocDialogParam.errorMessage}</p>
              <DialogFooter>
                <PrimaryButton onClick={this.openCloseConvocModal(false)} text="Fermer" />
              </DialogFooter>
            </React.Fragment>
            :
            this.state.convocDialogParam.loading == true ?
              <Spinner size={SpinnerSize.large} label={this.state.convocDialogParam.type == "convocations" ? "Envoi des convocations en cours..." : this.state.convocDialogParam.type == "denied" ? "Refus des candidatures en cours..." : ""} labelPosition="bottom" />
              :
              this.state.convocDialogParam.type == "convocations" ?
                <React.Fragment>
                  <div>Vous allez convoquer {this.state.demandeLiees.filter(wf => wf.selected == true).length} candidats pour l'offre {this.props.form.name}</div>
                  <DialogFooter>
                    <PrimaryButton onClick={this.sendConvocations} text="Convoquer" />
                    <DefaultButton onClick={this.openCloseConvocModal(false)} text="Annuler" />
                  </DialogFooter>
                </React.Fragment>
                :
                this.state.convocDialogParam.type == "denied" ?
                  <React.Fragment>
                    {
                      this.state.convocDialogParam.wfToDenied != undefined && this.state.convocDialogParam.wfToDenied != null && this.state.convocDialogParam.wfToDenied.length > 0 ?
                        <React.Fragment>
                          <div>Candidatures envoyées, il reste {this.state.convocDialogParam.wfToDenied.length} candidatures, voulez-vous les refuser ?</div>
                          <DialogFooter>
                            <PrimaryButton onClick={this.deniedWf} text="Refuser" />
                            <DefaultButton onClick={this.openCloseConvocModal(false)} text="Annuler" />
                          </DialogFooter>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <div>Candidatures envoyées</div>
                          <DialogFooter>
                            <PrimaryButton onClick={this.openCloseConvocModal(false)} text="Fermer" />
                          </DialogFooter>
                        </React.Fragment>
                    }

                  </React.Fragment>
                  :
                  <React.Fragment>
                    <div>Les {this.state.convocDialogParam.wfToDenied.length} candidatures ont bien été refusées</div>
                    <DialogFooter>
                      <PrimaryButton onClick={this.openCloseConvocModal(false)} text="Fermer" />
                    </DialogFooter>
                  </React.Fragment>
          }
        </Dialog>
      </div>
    );
  }
}