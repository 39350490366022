import { ITypeForm } from "../Workflow/interfaces/IWorkflow";

// Fonction qui retourne un objet URLSearchParams pour pouvoir manipuler les param dans l'url
export const useQuery = (): URLSearchParams => {
    return new URLSearchParams(window.location.search);
}

// Fonction pour construire l'arborescence des modèles
export const constructTree = async (modeles: any[],typesForm:ITypeForm[]) => {
    const modelesParent: any[] = modeles.filter(modele => modele.idParent == null);
    const testResult: any = [];
    if (modelesParent.length > 0) {
        for (const modeleParent of modelesParent) {
            const children = await getChildren(modeleParent, modeles);
            testResult.push({ key: modeleParent.id.toString(), id: modeleParent.id, label: modeleParent.name, nodes: children, isGRC: modeleParent.isGRC, isForm: modeleParent.isForm, formType:modeleParent.formType, guid:modeleParent.guid });
        }
        return { treeModeles: testResult, allModelsList: modeles,allTypeForm:typesForm };
    }
    else {
        testResult.push({ key: "Pas de modèle pour le moment", label: "Pas de modèle pour le moment", name: "Pas de modèle pour le moment", id: "noModel" });
        return { treeModeles: testResult, allModelsList: [], allTypeForm:typesForm };
    }
}

// Fonction de récurssivitée pour construire l'arbre des modèles
const getChildren = async (modeleParent, allModeles: any[]) => {
    const treeChildren: any[] = [];
    const children: any[] = allModeles.filter(modele => modele.idParent == modeleParent.id);
    for (const child of children) {
        const usersDirectReports = await getChildren(child, allModeles);
        // usersDirectReports ? treeChildren.push({ name: child.name, id: child.id, idParent: child.idParent, children: usersDirectReports }) :
        //     treeChildren.push({ title: child });

        usersDirectReports ? treeChildren.push({ key: child.id.toString(), id: child.id, label: child.name, nodes: usersDirectReports, isGRC: child.isGRC, isForm: child.isform, formType: child.formType, guid:child.guid }) :
            treeChildren.push({ title: child });
    }
    return treeChildren;
}