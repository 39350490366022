import * as React from 'react';
import Slider from "react-slick";
import { observer } from "mobx-react";
import Store from "../store/store";
import Banettes from "../banette/banette"
import { IDBWorkflow } from '../Workflow/interfaces/IWorkflow';
import Icon from '@material-ui/core/Icon';
import { withRouter, RouteComponentProps } from "react-router";
import { useQuery } from "./../helper/helpers";


const styles = require("./styles/taskSlider_styles.scss");

export interface TaskSliderProps {
  userConnectedId: number;
  reload: boolean;
  reloadTable?: boolean;
  allBannetteAndContent: any[];
}

export interface TaskSliderState {
  reload: boolean;
  searchTemp: any;
  allSearch?: any[];
  numberSearch?: any[];
  firstLoading?: boolean;
  selectedBanetteWF?: IDBWorkflow[];
}

@observer
class TaskSlider extends React.Component<RouteComponentProps<{}> & TaskSliderProps, TaskSliderState>{
  private sliderRef: any;
  constructor(props: RouteComponentProps & TaskSliderProps) {
    super(props);
    this.state = {
      reload: false,
      searchTemp: "En cours",
      allSearch: [],
      firstLoading: false,
      numberSearch: [],
      selectedBanetteWF: []

    };
  }
  componentDidMount() {
    const idBan = useQuery().get("b")
    if (idBan != undefined) {
      const idSelectedBannette: number = parseInt(idBan);
      const search = this.props.allBannetteAndContent.find(a => (idSelectedBannette != undefined && a.id == idSelectedBannette));
      this.getAllTasksByUser(search)
    }else{
      this.getAllTasksByUser("En cours")
    }
    
  }
  componentWillReceiveProps(nextProps: TaskSliderProps) {
    if (this.props.userConnectedId != nextProps.userConnectedId) {
      Store.taskSliderTasks = [];
      
      this.getAllTasksByUser(this.state.searchTemp);
      // this.searchTest()
    }
    if (this.props.reload != nextProps.reload) {
      Store.taskSliderTasks = [];
      this.getAllTasksByUser(this.state.searchTemp);
      // this.searchTest()
    }
  }
  public getAllTasksByUser = (search?) => {
    if (search != undefined) {
      const searchAndContent = this.props.allBannetteAndContent.find(a => (search.id != undefined && a.id == search.id) || search == a.name);
      Store.taskSliderTasks = searchAndContent.projects;
      this.setState({ selectedBanetteWF: searchAndContent.projects });
    } else {
      const searchAndContent = this.props.allBannetteAndContent[0];
      Store.taskSliderTasks = searchAndContent.projects;
      this.setState({ selectedBanetteWF: searchAndContent.projects });
    }
  }
  public displayWf = (idp: number) => {
    Store.clickDisplaySliderTask = true;
    // Store.historyIdWorkflow = Store.workflow.id;
    const getBannette= useQuery().get('b');
    const bannette = (getBannette != undefined)? "&b="+getBannette : '';
    this.props.history.push("/document?w=" +idp + bannette);
  }
  public formatDate = (date: Date) => {
    let dateFormat: string = "";
    if (date.getMonth() + 1 > 9) {
      dateFormat = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    } else {
      dateFormat = date.getDate() + '/0' + (date.getMonth() + 1) + '/' + date.getFullYear();
    }
    return (dateFormat);
  }
  render() {
    const firsTaskIndex = this.state.selectedBanetteWF.findIndex(proj => Store.workflow.id == proj.id);
    if (firsTaskIndex != -1  && this.state.selectedBanetteWF !=undefined && this.state.selectedBanetteWF.length > 0) {
      this.sliderRef.slickGoTo(firsTaskIndex);
    }
    const settings = {
      initialSlide: firsTaskIndex >= 0 ? firsTaskIndex : 0,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 6,
      swipe: false,
      variableWidth: false,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            swipeToSlide: true
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            swipeToSlide: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            swipeToSlide: true
          }
        }
      ]
    };

    return (
      <div>
        <Banettes onSearch={this.getAllTasksByUser} allBannetteAndContent={this.props.allBannetteAndContent} paramGestion={false} />
        <div className={styles.taskSlider}>
          {this.state.selectedBanetteWF ?
            <div className={styles.sliderContentYES}>
              
                <Slider
                  {...settings}
                  ref={slider => (this.sliderRef = slider)}
                >
                  {this.state.selectedBanetteWF.map((proj, index) => {
                    let styleTask = {};
                    let classNameIcon = "";
                    let titleIcon = "";
                    let icon = "";
                    let stepEnCours = proj.WfSteps.find(step => step.state == "En cours");
                    if(stepEnCours == undefined){ // Projet terminée ou autre
                      titleIcon = proj.WfSteps[proj.WfSteps.length-1].WfModel.name;
                      if(proj.WfSteps[proj.WfSteps.length-1] != undefined && proj.WfSteps[proj.WfSteps.length-1].WfModel.icon != undefined) {
                        icon = proj.WfSteps[proj.WfSteps.length-1].WfModel.icon.label;
                      }
                    } else { // Projet en cours
                      titleIcon = stepEnCours.WfModel.name;
                      if(stepEnCours != undefined && stepEnCours.WfModel.icon != undefined){
                        icon = stepEnCours.WfModel.icon.label;
                      }
                    }
                    if (Store.workflow.id == proj.id) {
                      styleTask["background"] =  "rgba(202, 255, 202)";
                    }
                    // Gère la couleur de l'icon en fonction de son état (casi tout essayé et c'est ce qui rend le mieux)
                    // else if(proj.state == "En retard"){
                    //   titleIcon = "En retard";
                    //   classNameIcon = styles.projEnRetard
                    // } else if (proj.state === "Terminé"){
                    //   titleIcon = "Terminé";
                    //   classNameIcon = styles.projTermine
                    // } else if (proj.state === "Urgent"){
                    //   titleIcon = "Urgent";
                    //   classNameIcon = styles.projUrgent
                    // }
                    const step = proj.WfSteps[0];
                    let dateFormat = "";
                    if (step.realDateBegin != undefined && step.realDateBegin != null) {
                      const dateBegin = new Date(step.realDateBegin);
                      const myDateEstimadedEndTask: Date = new Date(dateBegin.setDate((dateBegin.getDate()) + (step.realNbDays)));

                      const date = myDateEstimadedEndTask.getDate() >= 10 ? myDateEstimadedEndTask.getDate() : "0" + myDateEstimadedEndTask.getDate();
                      const month = (myDateEstimadedEndTask.getMonth() + 1) > 9 ? (myDateEstimadedEndTask.getMonth() + 1) : "0" + (myDateEstimadedEndTask.getMonth() + 1);
                      dateFormat = date + '/' + month + '/' + myDateEstimadedEndTask.getFullYear();
                    }
                    return (
                      <div key={index} className={styles.keyClass}>
                        <div className={styles.taskContainer} style={styleTask} onClick={() => this.displayWf(proj.id)}>
                          <div className={styles.contentDescr}>
                            {proj.state == "En retard" ? 
                              <Icon className={styles.sizeIcon + " " + styles.projEnRetard} title="En retard">watch_later</Icon>
                            : ''}
                            <Icon className={styles.sizeIcon + " " + classNameIcon} title={titleIcon}>{icon}</Icon>
                            <div className={styles.docName} title={" " + proj.name}>{" " + proj.name}</div>
                          </div>
                          <div className={styles.date}>
                            <span>{dateFormat}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
                {this.state.selectedBanetteWF != undefined && this.state.selectedBanetteWF.length > 0 ? ''
                  : 'Aucune tâches ne correspond à votre recherche.'}
            </div>
            : ""
          }
        </div>
      </div>
    );
  }
}

export default withRouter(TaskSlider);