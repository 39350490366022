import * as React from "react";
import { render } from "react-dom";
import App from "./components/App";
import { BrowserRouter as Router } from "react-router-dom";
const ROOT = document.querySelector("#root");

render(
  <Router>
    <App />
  </Router>,
  ROOT
);
