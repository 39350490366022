import * as React from 'react'
import Store from '../store/store'
import { observer } from "mobx-react";
import  ListModelWf from "./ListModelWf";

interface Props {
}

interface State {
}

@observer
export default class ListContainer extends React.Component<Props, State>{

    public constructor(props: Props) { // Dans le constructeur, on initialise toujours les states
        super(props)
        this.state = {
        }
    }

    render(){
        return(
            <ListModelWf reload={Store.reloadListModel} />
        )
    }
}