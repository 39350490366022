import * as React from 'react'
import { components} from 'react-select';
//import Store from '../store/store';
//import axios from 'axios'
import { css } from 'emotion';


export const MenuList = ({ cx, children, getStyles, innerRef, ...props })  => {
        return (
            <div ref={innerRef}​​​​​  title={props.data.mail} {...props.innerProps} className={cx(css(getStyles('option', props)),{'option': true,'option--is-disabled': props.innerProps.isDisabled,'option--is-focused': props.innerProps.isFocused,'option--is-selected': props.innerProps.isSelected,})}>
                <span>{props.data.label}​​​​​</span>
            </div>
        );
}

export const customSingleValue = ({ data }) => {
    return (
    <div className="input-select" >
        <div className={" input-select__single-value" } title={data.mail}>
            <span>{data.label}</span>
        </div>
    </div>
)};

export const customMultipleValue = ({ ...props }) => {
    return (
    <div style={{width: "85%"}} title={props.data.mail}>
        <components.MultiValueLabel {...props}/>
    </div>
)};