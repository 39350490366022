import { ITheme } from "../interfaces/ITheme";

// const themeConfig:ITheme = {
//     primaryColor:{
//         backgroundColor: "#10a544",
//         color:"white"
//     },
//     backgroundColor: "#10a544",
//     color: "#ffffff",
//     backgroundSecondary: "linear-gradient(60deg, #265e66, #1e4c51)"
// }

const themeConfig:ITheme = {
    primaryTheme:{
        background: "#10a544",
        color: "#ffffff",
    },
    backgroundSecondary: "#10a544",
    color: "#ffffff",
    backgroundPrimary: "linear-gradient(60deg, #265e66, #1e4c51)"
}
export default themeConfig;
