import * as React from "react";
import Slider from "react-slick";
import { observer } from "mobx-react";
import Icon from "@material-ui/core/Icon";
import Store from "../store/store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "./../helper/helpers";


const styles = require("./styles/banettes.scss");

interface Props {
  onSearch: (search: any) => any;
  paramGestion? : boolean;
  allBannetteAndContent?:any[];
  displayBanette? : (search : any) => any;
  editBanet? : (search : any) => any;
  deleteBanette? : (search : any) => any;
  setIsNewSearch? : () => void;
}
interface State {
  reload: boolean;
  selectedBannette: number;
}


@observer
class Banettes extends React.Component<RouteComponentProps<{}> & any, State>{
//export default class Banettes extends React.Component<Props, State>{
  public constructor(props:Props) {
    super(props);
    this.state = {
      reload: false,
      selectedBannette:  1
    };
  }


  componentDidMount() {
    const idBan = useQuery().get("b")
    const idWf = useQuery().get("w")
    if (idBan != undefined) {
      const findBannette = this.props.allBannetteAndContent.find(ban => ban.id == parseInt(idBan));
      if(findBannette != undefined) {
        this.onClickBanet(findBannette);
      }

      // const idSelectedBannette: number = parseInt(idBan);
      // this.setState({selectedBannet:idSelectedBannette});
    }
    else if (idWf != undefined){
      const findBannette = this.props.allBannetteAndContent.find(ban => ban.projects.filter(p => p.id == idWf ).length != 0 )
      if(findBannette != undefined) {
        this.onClickBanet(findBannette);
      }
    }
  }

  public displayBanette = (search) => {
    this.props.displayBanette(search)
  }

  public deleteBanette = (search) => {
    this.props.deleteBanette(search)
  }

  public editBanet = (search) => {
    this.props.editBanet(search)
  }
  public onClickBanet =(search)=>{
    this.props.onSearch(search);
    this.setState({selectedBannette:search.id});
    const query = useQuery();

    const b = query.get("b");
    if(b != undefined && b != null && b.length > 0) {
      query.set("b",search.id);
    }
    else {
      query.append("b",search.id);
    }
    
    window.history.replaceState(null,'',window.location.pathname+"?"+query);
  }
  public render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 6,
      swipe: false,
      variableWidth: false,
      focusOnSelect: false,
      arrows : true,
      responsive: [
        {
          breakpoint: 1550,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            swipeToSlide: true
          }
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            swipeToSlide: true
          }
        },
        {
          breakpoint: 972,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            swipeToSlide: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            swipeToSlide: true
          }
        }
      ]
    };

    const styleBanette = styles.itemSearch2
    const colorStatic = styles.colorStatic
    const colorCustom = styles.colorCustomUser
    // let colorParamGestion = styles.colorGestion
    // let banetParamGestion = styles.banetGestion
    const baneSelectedStatic = styles.itemSearch + ' ' + styles.banetSelectedStatic
    let color
    let banetSelected
    const banetSelectdCustom = styles.itemSearch + ' ' + styles.banetSelectedCustom
    let value = {};
    if(this.props.setIsNewSearch != undefined){
      value = { onClick: () => this.props.setIsNewSearch() };
    }
    return (
      <div className={styles.taskBanettes}>
        <div className={styles.sliderContentBanettes} {...value}>
          <Slider
            {...settings}
          >
            {this.props.allBannetteAndContent.map((search) => {
          //   if(this.props.paramGestion == false){
                if (search.name == "En cours" || search.name == "Différée") {
                  color = colorStatic
                  banetSelected = baneSelectedStatic
                } else {
                  color = colorCustom
                  banetSelected = banetSelectdCustom
                }
              // }else{
                //  color = colorCustom


              // let nombreDisplay = 0
              // const nombreDossier = this.props.nombreDossier.filter(nombreDossier => nombreDossier.id == search.id)
              // if (nombreDossier.length > 0) {
              //   nombreDisplay = nombreDossier[0].lengthElements
              // }
              return (
                <React.Fragment key={search.id}>
                  <div title={search.name} onClick={() => this.onClickBanet(search)} className={((search.id != undefined && search.id == this.state.selectedBannette)|| search == "En cours" ? banetSelected : styleBanette)}>
                    <div className={styles.numberDossierBanette + " " + color}>
                      <div className={styles.numberBanette}>{search.projects.length}</div>
                    </div>
                    <div className={styles.textBanette} title={search.name}>{search.name}</div>
                    {this.props.paramGestion == true && search.name != "En cours" && search.name != "Différée" ?
                      <div className = {styles.iconsBanet}>
                        <Icon className={styles.icon} onClick = {()=>{this.displayBanette(search)}} title={search.displayBanette == true ?"Masquer la banette" : "Afficher la banette"}>{search.displayBanette == true ?"visibility":"visibility_off"}</Icon>
                        <Icon className={styles.icon} onClick = {()=>{this.editBanet(search)}} title="Editer la banette">mode_edit</Icon>
                        <Icon className={styles.icon} onClick = {()=>{this.deleteBanette(search)}} title="Supprimer la banette">clear</Icon></div>
                    :""}
                  </div>
                </React.Fragment>
              )
            })}
          </Slider>
        </div>
      </div>
    );
  }
}

export default withRouter(Banettes);