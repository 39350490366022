import * as React from 'react'
import { getAllUsers } from "../store/store";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SelectReact, { createFilter } from 'react-select';
import axios from 'axios'
import Store from '../store/store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from "mobx-react";
import { MenuList, customMultipleValue } from './../helper/reactSelectCustomRender'
import Spinner from '../Spinner/Spinner';

const styles = require('./styles/ParamGroup.scss');

interface Props {

}
interface State {
    allUsersList: any[]
    nameGroup: string,
    choiceUser?: number[];
    allGroup?: any[]
    loadingButton: boolean;

}

@observer

class ParamGroupes extends React.Component<RouteComponentProps & Props, State>{

    constructor(props: any) {
        super(props)
        this.state = {
            allUsersList: undefined,
            nameGroup: "",
            choiceUser: [],
            allGroup: [],
            loadingButton: false
        }
        getAllUsers();

    }
    public addGroup = (event) => {
        event.preventDefault()
        const addGroup = axios.post(Store.wsPath + `/1/ParamGroupes/createGroupe`, {
            //nameGroupe: this.state.nameGroup,
            //idUsers: this.state.choiceUser
            nameGroupe: Store.myUser.firstname,
            idUsers: Store.myUser.usersOfGroup
        }).then(response => {
            if (response.status == 200) {
                Store.contextualmenu.open = "hidden";
                Store.contextualmenu.content = undefined;
                // SnackBar
                Store.snackBar.message = "Groupe ajouté avec succés"
                Store.snackBar.open = true;
                Store.snackBar.error = false;
                Store.myUser = Store.DefaultMyUser
                this.props.history.push("/refresh?redir=groups");
            }
            else {
                // SnackBar
                Store.snackBar.message = "Une erreur c'est produite, veuillez réessayez"
                Store.snackBar.open = true;
                Store.snackBar.error = true;
            }
        }).catch(error => {
            Store.snackBar.message = "Une erreur c'est produite, veuillez réessayez"
            Store.snackBar.open = true;
            Store.snackBar.error = true;
        })
    }




    public editGroup = (event) => {
        event.preventDefault()
        axios.post(Store.wsPath + `/1/paramGroupes/editGroupe`, {
            nameGroupe: Store.myUser.firstname,
            idUser: Store.myUser.id,
            usersOfGroup: Store.myUser.usersOfGroup
        }).then(response => {
            if (response.status == 200) {
                Store.contextualmenu.open = "hidden";
                Store.contextualmenu.content = undefined;
                Store.snackBar.message = "Groupe modifié avec succés"
                Store.snackBar.open = true;
                Store.snackBar.error = false;
                this.props.history.push("/refresh?redir=groups");
            }
            else {
                // SnackBar
                Store.snackBar.message = "Une erreur c'est produite, veuillez réessayez"
                Store.snackBar.open = true;
                Store.snackBar.error = true;
            }
        }).catch(error => {
            Store.snackBar.message = "Une erreur c'est produite, veuillez réessayez"
            Store.snackBar.open = true;
            Store.snackBar.error = true;
        })
    }

    public onChange = name => event => {
        if (name == "nameGroup") {
            Store.myUser.firstname = event.target.value
        }
    }

    public updateReactSelect = (search: any[], element: string) => {
        if (element == "choiceUser") {
            const tempArrayUser: any[] = []
            if (search) {
                search.forEach(elmt => {
                    const tempSuggestion = Store.suggestionsUser.find(suggestion => suggestion.value == elmt);
                    if (tempSuggestion != undefined) {
                        tempArrayUser.push(tempSuggestion);
                    }
                });
            }
            return tempArrayUser
        }
    }

    public onChangeSelect = (option, type) => {
        if (type == "user") {
            const tempArray = option.map(role => role.value)
            Store.myUser.usersOfGroup = tempArray
        }
    }

    public onCancel = () => {
        Store.contextualmenu = Store.defaultValueContextualMenu;
    }



    public render() {
        // console.log('le state ', this.state.choiceUser)
        return (
            <form className={styles.editGroupContainer} key="myForm" autoComplete="false" onSubmit={Store.contextualmenu.content == "addNewGroup" ? this.addGroup : this.editGroup}>
                <h5 className={styles.titleContextual}>{Store.contextualmenu.content == "addNewGroup" ? "Ajouter un groupe" : "Modifier le groupe"}</h5>
                <TextField // Nom du groupe
                    // InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    className={styles.field}
                    required
                    type="text"
                    value={Store.myUser.firstname}
                    onChange={this.onChange("nameGroup")}
                    label="Nom du Groupe"
                />

                {/* <SelectReact
                        options={Store.suggestionsUser!= undefined ? Store.suggestionsUser.filter(user => user.isGroup == false) : [] }
                        onChange={option => this.onChangeSelect(option ? option : null, "user")}
                        placeholder="Utilisateurs à ajouter"
                        filterOption={createFilter({ ignoreAccents: false })}
                        value={Store.myUser.usersOfGroup!= [] ? this.updateReactSelect(Store.myUser.usersOfGroup, "choiceUser") : []}
                        className={styles.field}
                        isMulti
                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                        isClearable
                        components={{ MultiValueLabel: customMultipleValue , Option: MenuList}}
                    /> */}

                <div className={styles.buttonContainer}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={this.state.loadingButton == true ? true : false}
                    >
                        {this.state.loadingButton == true ?
                            <Spinner fullWidth={true} size={24} />
                            :
                            "Valider"
                        }
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        className={styles.btnCancel}
                        color="default"
                        onClick={this.onCancel}
                    >
                        Annuler
                        </Button>
                </div>
            </form>
        )
    }
}

export default withRouter(ParamGroupes)
