import React from 'react';
import Store from "./store/store";
import { observer } from "mobx-react";
// import Dashboard from './Dashboard/Dashboard'

interface Props {
}

@observer
export default class HomePageUnconnected extends React.Component<{}, {}>{
    public constructor(props: Props) {
        super(props)
    }

    public render() {
        return (
            <div>
                {Store.userConnected.id == undefined ?
                    <div>
                        Vous n'êtes pas connecté !
                    </div>
                 :
                <div>
                  Bienvenue {Store.userConnected.firstname}
                </div>}
            </div>
        )
    }

}