import * as React from 'react'
import { UserYes } from '../types';
const styles = require('./styles/styles.scss')
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import axios from 'axios'
import Store from '../store/store'
import PieStats from '../dashboard/PieStats'
import Grid from '@material-ui/core/Grid';

interface Props {

}

let TodayDate = new Date
let tempArray2 = []
let phrase = [
    "vous êtes très en beauté aujourd'hui !",
    'vous semblez livide, prenez ce jour de congé offert par le patron.',
    'on dit de vous que vous êtes sympatique.',
    'qu\'attendez vous pour proposer un verre à Jaqueline de la compta ?',
    'ne mettez pas vos coudes sur la table.',
    "ne mettez pas votre doigt dans votre nez, tout le monde vous regarde...",
    'Régis à dit que vous aviez pris des hanches ...',
    'dites bonjour à votre patron qui regarde en ce moment même par dessus votre épaule !',
    'faites coucou au dev qui vous regarde via la webcam :)']
let random = []


interface State {
    period: number, // nombre de documents traités en fonction l’utilisateur connecté
    nbDocumentTraiteByUserConnected: number, // nombre de documents traités en fonction l’utilisateur connecté
    startDate?: Date;
    endDate?: Date;
    nbDossierTermine?: number,
    nbDossierEncours?: number
    nbOrientation?: number
    nameOfOrientation?: string[]
    tempNameOfOrientation?: string[]
    nbOfNameOrientation?: number[]
    taskByUserConnected?: any[]
    allStepIdFromIdTask?: any[]
    IdDocByUserConnected?: any[]
    nbDossierEnRetard?: number
    nbDossierATemps?: number
}

export default class Dashboard extends React.Component<Props, State>{

    public constructor(props: Props) {
        super(props)
        const today = new Date();
        const last2mounth = new Date(today.getFullYear(), today.getMonth() - 1, 0); // pour régler le date picker a 2 mois en arrière , à modifier au besoin
        last2mounth.setDate(Math.min(today.getDate(), last2mounth.getDate()));

        this.state = {
            period: undefined, // nombre de documents traités en fonction l’utilisateur connecté
            nbDocumentTraiteByUserConnected: undefined, // nombre de documents traités en fonction l’utilisateur connecté
            startDate: last2mounth,
            endDate: new Date(),
            nbDossierEncours: undefined,
            nbDossierTermine: undefined,
            nameOfOrientation: [],
            nbOrientation: undefined,
            taskByUserConnected: [],
            allStepIdFromIdTask: [],
            IdDocByUserConnected: [],
            nbDossierEnRetard: undefined,
            nbDossierATemps: undefined,
            nbOfNameOrientation: [],
            tempNameOfOrientation: [],
        }
        //this.getAllDocumentByUserConnected()
        this.getAllTaskByUserConnected()
        this.randomizePhrase()
    }

    componentDidMount() {

    }
    onClickButton = () => {
        Store.snackBar.open = true;
        Store.snackBar.message = "Merci d'avoir pris le temps d'évaluer notre produit. C'est gentil.";
    }
    getAllTaskByUserConnected() { // Permet de récupérer toutes les tâches de l'utilisateur connecté pour remonter jusqu'au doc
        axios.get(Store.wsPath + '/1/documents/getAllTaskByUserConnected', {
            params: {
                userIdx: Store.userConnected.id,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }
        })
            .then(response => {
                this.setState({ taskByUserConnected: response.data })
                if (this.state.taskByUserConnected.length > 1) {
                    this.getStepIdFromIdTask()
                }
            })
    }

    getStepIdFromIdTask() { // Permet de récupérer les step en rapport avec les tâches , ainsi que de compter le nombre de step d'orientation
        let tempArray = []
        this.state.taskByUserConnected.forEach(task => {
            tempArray.push(task.stepId)
        });
        let uniqueSet = Array.from(new Set(tempArray)) // POUR VIRER LES DOUBLONS DU TABLEAU
        axios.get(Store.wsPath + '/1/documents/getStepIdFromIdTask', {
            params: {
                stepId: uniqueSet,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }
        })
            .then(response => {
                this.setState({ nbOrientation: response.data.getProjectIdIsStepOrientation.length })
                response.data.getProjectIdIsStepOrientation.forEach(element => {
                    this.pushProjectName(element)
                });
                this.setState({ nameOfOrientation: tempArray2 })
                this.counterProjectSameName(this.state.nameOfOrientation)
                this.setState({ allStepIdFromIdTask: response.data.getProject })
                this.getIdDocFromProject()
            })
    }

    getIdDocFromProject() { // Pour récupérer les idxDoc et compter le nombre de Step en cours et Terminé et les noms de projets
        let tempArray = []
        this.state.allStepIdFromIdTask.forEach(step => { // Pour virer les doublons du tableau d'id
            tempArray.push(step.projetId)
        });
        let uniqueSet = Array.from(new Set(tempArray))
        axios.get(Store.wsPath + '/1/documents/getIdDocFromProject', {
            params: {
                idProject: uniqueSet,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }
        })
            .then(response => {
                this.setState({
                    nbDossierEncours: response.data.getIdDocEncours.length,
                    nbDossierTermine: response.data.getIdDocTerminé.length,
                    IdDocByUserConnected: response.data.getIdDoc
                })
                this.getDocumentbyIdDoc()
                this.getRatioDossierEnRetard(response)
            })
    }

    getDocumentbyIdDoc() { // Pour récupérer les documents traités
        let tempArray = []
        this.state.IdDocByUserConnected.forEach(idDoc => {
            tempArray.push(idDoc.id)
        });
        let uniqueSet = Array.from(new Set(tempArray))
        this.setState({ nbDocumentTraiteByUserConnected: uniqueSet.length })
    }

    getRatioDossierEnRetard = (response) => { // Pour calculer le ratio de dossiers traités à temps et en retard pour l'utilisateur connecté
        let tempCountEnRetard = 0
        //console.log(response)
        response.data.getIdDoc.forEach(project => {
            if (project.plannedDateEnd > TodayDate) {
                tempCountEnRetard = tempCountEnRetard + 1
            }
        });
        this.setState({ nbDossierEnRetard: tempCountEnRetard }) // Nb de dossiers en retards
        let tempLenght = response.data.getIdDoc.length // Nb de dossiers total de la réponse
        let tempCountATemps = tempLenght - tempCountEnRetard // Soustraction du nb de dossiers en retard au nb de dossiers
        this.setState({ nbDossierATemps: tempCountATemps }) // Pour trouver le nb  de dossiers traités à temps
    }

    pushProjectName(element) {
        this.setState({ tempNameOfOrientation: element.name })
        tempArray2.push(this.state.tempNameOfOrientation)
    }

    counterProjectSameName = (array) => {
        let counter = []
        let label = []

        array.forEach(orientationName => {
            const find = label.indexOf(orientationName);
            if (find == -1) {
                counter.push(1)
                label.push(orientationName)
            }
            else {
                counter[find] = counter[find] + 1;
            }
        })
        this.setState({ nameOfOrientation: label })
        this.setState({ nbOfNameOrientation: counter })
    }
    onChangeDatePicker = name => event => {
        if (name == 'startDate') {
            this.setState({ startDate: event })
        }
        else if (name == 'endDate') {
            this.setState({ endDate: event })
        }
    }
    randomizePhrase() {
        let tempArray = phrase.slice(0)
        while (random.length < 1) {
            random.push(tempArray.splice(Math.floor(Math.random() * tempArray.length), 1)[0])
        }
    }
    public render() {
        return (
            <div className={styles.mainContent}>
                {this.state.nbDossierEncours >= 1 || this.state.nbDossierTermine >= 1 ?
                    <div>
                        <div className={styles.firstContent}>
                            <div className={styles.dateContainer} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Mon tableau de bord :</h5></div>
                            <div className={styles.contentTitle}><h6>Bonjour {Store.userConnected.firstname + " " + Store.userConnected.lastname}</h6>Vous avez traité à ce jour, pour la période selectionnée {this.state.nbDocumentTraiteByUserConnected} documents. <br />Vous êtes impliqué dans {this.state.nbDossierEncours} dossiers en cours et {this.state.nbDossierTermine} dossiers terminés.<br />Vous êtes à temps sur {this.state.nbDossierATemps} dossiers et êtes en retard sur {this.state.nbDossierEnRetard} dossiers.<br />Vous avez traité {this.state.nbOrientation} dossiers d'orientation.</div>
                        </div>
                        {/* <div className={styles.secondContent}>
                            <div className={styles.dateContainer} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Recommenderiez vous notre progiciel à un collègue ou un ami ?</h5></div>
                            <div className={styles.contentTitle}>
                                <Button onClick={this.onClickButton} className={styles.button} color='primary' variant="contained">Oui, bien sûr</Button>
                                <Button onClick={this.onClickButton} className={styles.button} color='primary' variant="contained">Oui, bien evidemment</Button></div>
                        </div> */}
                        <div className={styles.thirdContent}>
                            <div className={styles.dateContainer} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Période d'affichage :</h5></div>
                            <div className={styles.headerColumn}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                    <DatePicker
                                        value={this.state.startDate}
                                        format="dd/MM/yyyy"
                                        onChange={this.onChangeDatePicker("startDate")}
                                        cancelLabel="Annuler"
                                        clearLabel="Pas de date"
                                        clearable
                                        maxDate={this.state.endDate != null ? this.state.endDate : new Date()}
                                        variant="outlined"
                                        label="Du"
                                        style={{ width: "45%", maxWidth: "150px" }}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                    <DatePicker
                                        value={this.state.endDate}
                                        format="dd/MM/yyyy"
                                        onChange={this.onChangeDatePicker("endDate")}
                                        cancelLabel="Annuler"
                                        clearLabel="Pas de date"
                                        clearable
                                        maxDate={new Date()}
                                        variant="outlined"
                                        label="Au"
                                        style={{ width: "45%", maxWidth: "150px" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <Grid container className={styles.piestats}>
                                {this.state.nbDossierEncours != undefined && this.state.nbDossierTermine != undefined ?
                                    <Grid item lg={4} sm={6} xs={6}>
                                        <PieStats
                                            data={[this.state.nbDossierEncours, this.state.nbDossierTermine]}
                                            text={'Nombre de dossiers traités :'}
                                            labels={['Dossier en cours', 'Dossiers terminé']}
                                        />
                                    </Grid>
                                    :
                                    <div></div>
                                }
                                {this.state.nbDossierATemps != undefined && this.state.nbDossierEnRetard != undefined ?
                                    <Grid item lg={4} sm={6} xs={12}>
                                        <PieStats
                                            data={[this.state.nbDossierATemps, this.state.nbDossierEnRetard]}
                                            text={'Ratio des dossiers traités à temps :'}
                                            labels={['Dossiers traités à temps ', 'Dossiers en retards']}
                                        />
                                    </Grid>
                                    :
                                    <div></div>
                                }
                                {this.state.nameOfOrientation.length > 0 ?
                                    <Grid item lg={4} sm={6} xs={12}>
                                        <PieStats
                                            data={this.state.nbOfNameOrientation}
                                            text={'Nombre de dossiers par orientation :'}
                                            labels={this.state.nameOfOrientation}
                                        />
                                    </Grid>
                                    :
                                    <div></div>
                                }
                            </Grid>
                        </div>
                    </div>
                    :
                    <div>
                        <div className={styles.firstContent}>
                            <div className={styles.dateContainer} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Mon tableau de bord :</h5></div>
                            <div className={styles.contentTitle}><h6>Bonjour {Store.userConnected.firstname + " " + Store.userConnected.lastname}</h6></div>
                        </div>
                        <div className={styles.secondContent}>
                            <div className={styles.dateContainer} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Période d'affichage :</h5></div>
                            <div className={styles.headerColumn}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                    <DatePicker
                                        value={this.state.startDate}
                                        format="dd/MM/yyyy"
                                        onChange={this.onChangeDatePicker("startDate")}
                                        cancelLabel="Annuler"
                                        clearLabel="Pas de date"
                                        clearable
                                        maxDate={this.state.endDate != null ? this.state.endDate : new Date()}
                                        variant="outlined"
                                        label="Du"
                                        style={{ width: "45%", maxWidth: "150px" }}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                    <DatePicker
                                        value={this.state.endDate}
                                        format="dd/MM/yyyy"
                                        onChange={this.onChangeDatePicker("endDate")}
                                        cancelLabel="Annuler"
                                        clearLabel="Pas de date"
                                        clearable
                                        maxDate={new Date()}
                                        variant="outlined"
                                        label="Au"
                                        style={{ width: "45%", maxWidth: "150px" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={styles.contentTitle}>Nous ne disposons pas de données suffisantes pour afficher vos statistiques sur la pérdiode sélectionnée.</div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}