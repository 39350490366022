import * as React from 'react';
import Icon from '@material-ui/core/Icon';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import Store from "../store/store";
import { observer } from 'mobx-react';
const snackStyle = require('./styles/SnackBar.scss');

let myTimeout: any = undefined;
@observer
export default class SnackBarComponent extends React.Component<{}, {}>{
	constructor(props) {
		super(props)
		this.state = {
		}
	}
	public onEnterSnackBar = () => {
        clearTimeout(myTimeout);
    }
    public handleClose = () => {
        Store.snackBar.open = false;
        Store.snackBar.message = "";
        myTimeout = setTimeout(() => { Store.snackBar.error = false }, 1000);
    }

	public render() {
		return (
				<Snackbar
                    open={Store.snackBar.open}
                    onEnter={this.onEnterSnackBar}
                    autoHideDuration={4000}
                    onClose={this.handleClose}
					className={snackStyle.snackBarContent}
                >
                    <SnackbarContent
                        className={Store.snackBar.error ? snackStyle.error : snackStyle.success}
                        aria-describedby="client-snackbar"
                        message={
                            <span key={"client-snackbar"} id="client-snackbar" className={snackStyle.message}>
                                {Store.snackBar.error ? <Icon className={snackStyle.iconVariant}>warning</Icon> : <Icon className={snackStyle.iconVariant}>done</Icon>}
                                {Store.snackBar.message}
                            </span>
                        }
                        action={[
                            <Icon key={"icon_snackbar"} onClick={this.handleClose} className={snackStyle.icon}>close</Icon>
                        ]}
                    />
                </Snackbar>
		);
	}
}