import * as React from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from "react-router";
import { useQuery } from "./../helper/helpers";

@observer
class Refresh extends React.Component<RouteComponentProps<{}> & any, any>{
    public constructor(props:any) {
        super(props)
    }
    componentDidMount() {
      // const redir: string =  useQuery().toString();
      const splitParams = this.props.location.search.split("redir=");
      const paramString:string = splitParams[splitParams.length - 1];
      if (paramString != undefined && paramString != null && paramString != "") {
        this.props.history.push("/"+paramString);
      }else{
        this.props.history.push("/");
      }
    }
    
    public render() {
        return (
            <div></div>
        )
    }
}
export default withRouter(Refresh);