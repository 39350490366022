import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IColumnInfo } from '../interfaces/IRenderForm';

interface SPFieldNumberProps {
    field:IColumnInfo;
    value: string;
    readOnly?:boolean;
    onChange?: (question:IColumnInfo, type?:string) => (event: any, newValue?: string) => void;
}

interface SPFieldNumberState {

}

class SPFieldNumber extends React.Component<SPFieldNumberProps, SPFieldNumberState> {
    constructor(props: SPFieldNumberProps) {
        super(props);
        this.state = {};
    }
    render() {
        const onChangeParam:any = this.props.onChange != undefined && this.props.onChange != null ? {onChange:this.props.onChange(this.props.field)} : {};
        return (
            <TextField
                type="number"
                label={this.props.field.createData.Title}
                name={this.props.field.internalName}
                value={this.props.value}
                noValidate
                description={this.props.field.createData.Description}
                min={this.props.field.createData.MinimumValue != undefined && this.props.field.createData.MinimumValue != null ? this.props.field.createData.MinimumValue : ""}
                max={this.props.field.createData.MaximumValue != undefined && this.props.field.createData.MaximumValue != null ? this.props.field.createData.MaximumValue : ""}
                required={this.props.field.createData.Required}
                readOnly={this.props.readOnly != undefined ? this.props.readOnly : false}
                // onChange={this.props.onChange(this.props.field)}
                key={this.props.field.createData.Title}
                {...onChangeParam}
            />
        );
    }
}

export default SPFieldNumber;