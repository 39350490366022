import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
const styles = require("./styles/spinner.scss");

export interface ISpinnerProps {
    size?:number;
    label?:string;
    labelPlacement?: "top" | "bottom" | "right" | "left"; // Top, bottom, right, left
    labelSize?:number;
    space?:number;
    fullWidth?:boolean; // Si le container du spinner prend toute la largeur disponible ou pas true ou false
    className?:string; // liste des classes
    style?: React.CSSProperties;
}

export interface ISpinnerState {

}

export default class Spinner extends React.Component<ISpinnerProps, ISpinnerState> {
    constructor(props: ISpinnerProps) {
        super(props);
        this.state = {

        };
    }
    render() {
        const styleSpinner = {};
        if(this.props.size != undefined && this.props.size != null) {
            styleSpinner["width"] = this.props.size + "px";
            styleSpinner["height"] = this.props.size + "px";
        }

        const styleLabel = {};
        if(this.props.labelSize != undefined && this.props.labelSize != null) {
            styleLabel["fontSize"] = this.props.labelSize + "px";
        }

        let marginSpace:string = "";
        let styleContainer = {};
        switch (this.props.labelPlacement) {
            case "top":
                styleContainer["flexDirection"] = "column-reverse";
                marginSpace = "marginBottom";
                break;
            case "bottom":
                styleContainer["flexDirection"] = "column";
                marginSpace = "marginTop";
                break;
            case "right":
                styleContainer["flexDirection"] = "row";
                marginSpace = "marginLeft";
                break;
            case "left":
                styleContainer["flexDirection"] = "row-reverse";
                marginSpace = "marginRight";
                break;

            default:
                styleContainer["flexDirection"] = "row";
                marginSpace = "marginLeft";
                break;
        }


        if(this.props.fullWidth != undefined && this.props.fullWidth != null && this.props.fullWidth == true) {
            styleContainer["width"] = "100%";
        }


        if(this.props.space != undefined && this.props.space != null && marginSpace.length > 0) {
            styleLabel[marginSpace] = this.props.space + "px";
        }
        else {
            styleLabel[marginSpace] = "10px";
        }

        // Si une ou des classes sont passées en props
        const customClasses:string = this.props.className != undefined && this.props.className != null && this.props.className.length > 0 ? this.props.className : "";

        // Si un style custom est passé en props
        if(this.props.style != undefined && this.props.style != null && Object.keys(this.props.style).length !== 0) {
            styleContainer = {...this.props.style, ...styleContainer};
        }
        return (
            <div className={styles.spinnerContainer + " " + customClasses} style={styleContainer}>
                <CircularProgress style={styleSpinner} />
                {
                    this.props.label != undefined && this.props.label != null && this.props.label.length > 0 ?
                    <span style={styleLabel}>{this.props.label}</span>
                    :
                    <React.Fragment></React.Fragment>
                }
            </div>
        );
    }
}