import * as React from 'react';
import { IColumnInfo } from '../interfaces/IRenderForm';

interface TitleProps {
    field:IColumnInfo;
    value?: string;
}

interface TitleState {

}

export default class Title extends React.Component<TitleProps, TitleState> {
    constructor(props: TitleProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <h2 style={{margin:"10px 0"}}>{this.props.field.createData.Title}</h2>
        );
    }
}