import * as React from "react";
import TextField from "@material-ui/core/TextField";

interface ITextEditorProps {
  onUpdate: () => string;
  defaultValue: string;
  onBlur: () => any;
  row: any;
  className?: string;
}

interface ITextEditorState {
  value: string;
}

export default class TextEditor extends React.Component<ITextEditorProps, ITextEditorState> {
  constructor(props: ITextEditorProps) {
    super(props);
    this.state = {
      value: this.props.defaultValue != undefined && this.props.defaultValue != null ? this.props.defaultValue : ""
    };
  }

  public onChange = (event) => {
    this.setState({value: event.target.value});
  }
  render() {
    return (
      <div>
        <TextField
          variant="outlined"
          defaultValue={this.state.value}
          onChange={this.onChange}
          onBlur={this.props.onBlur}
          className={this.props.className}
        />
      </div>
    );
  }
}