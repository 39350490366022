import * as React from 'react';
const styles = require('../../styles/styles.module.scss');
import { observer } from "mobx-react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import SelectReact, { createFilter, components } from 'react-select';
import Store, { getAllRoles } from '../store/store';
import axios from 'axios';
import { IModelWf, IStepsModel } from '../Workflow/interfaces/IWorkflow';
import { ITypeInvitation } from '../Workflow/interfaces/IWorkflow';
import Spinner from '../Spinner/Spinner';
import { IRole } from '../parametersRoles/interfaces/IRole';
import { MenuList, customSingleValue } from './../helper/reactSelectCustomRender';
import Typography from "@material-ui/core/Typography";
import TreeMenu from "react-simple-tree-menu";

interface Props {
}

interface State {
    calculrankStep?: number,
    displayElementRole?: boolean,
    roleSuggestionsState?: any[],
    valueLabelRole?: any,
    copieStepModel: IStepsModel;
    valueRadioIsRequired?: any;
    suggestionInvitation?: ITypeInvitation[],
    treeModeles?: any[];
    isStepOrientation?: boolean;
    idsModel?: number[],
    choiceType?: ITypeInvitation
    valueAnb?: boolean;
    realNbDays: number;
    loadingButton: boolean;
    modelInfo: IModelWf;
    listStatusFormSuggestion: any[];
    listFormTypeSuggestion: any[];
    selectedParentResponseModel:any;

}

// Composant pour avoir un rendu custom pour les roles (le nom des personnes au survol)
const MultiValueLabel = props => {
    return (
        <div title={props.data.title != undefined && props.data.title != null ? props.data.title : ""}>
            <components.MultiValueLabel {...props} />
        </div>
    );
};

// Composant pour avoir un rendu custom pour les roles (le nom des personnes au survol) dans les options du select
const OptionSelect = props => {
    return (
        <div title={props.data.title != undefined && props.data.title != null ? props.data.title : ""}>
            <components.Option {...props} />
        </div>
    );
};

@observer
export default class EditStepModel extends React.Component<Props, State>{
    public constructor(props: Props) { // Dans le constructeur, on initialise toujours les states
        super(props)
        const tempStepMdodel: IStepsModel = Store.stepModel;

        const listSugestionInvitation = [{ value: 1, label: "Contribution" }, { value: 2, label: "Lecture" }];

        this.state = {
            calculrankStep: 0,
            displayElementRole: false,
            roleSuggestionsState: Store.allRoles.sort(this.compare).map((suggestion: IRole) => ({
                value: suggestion.id,
                label: suggestion.name,
                title: suggestion.user
            })),
            treeModeles: [],
            valueLabelRole: "",
            selectedParentResponseModel:{},
            copieStepModel: {
                id: tempStepMdodel.id,
                idsRole: tempStepMdodel && tempStepMdodel.idsRole != null ? tempStepMdodel.idsRole.slice() : [],
                idxModel: tempStepMdodel.idxModel,
                name: tempStepMdodel.name,
                typeTask: tempStepMdodel.typeTask,
                rankStep: tempStepMdodel.rankStep,
                state: tempStepMdodel.state,
                idsRoleSecondaire: tempStepMdodel && tempStepMdodel.idsRoleSecondaire != null ? tempStepMdodel.idsRoleSecondaire.slice() : [],
                isRequired: tempStepMdodel.isRequired,
                isStepOrientation: tempStepMdodel.isStepOrientation,
                idsStepOrientationModel: tempStepMdodel.idsStepOrientationModel,
                isNotified: tempStepMdodel.isNotified != null ? tempStepMdodel.isNotified : false,
                realNbDays: tempStepMdodel.realNbDays,
                changeStatut: tempStepMdodel && tempStepMdodel.changeStatut != undefined && tempStepMdodel.changeStatut != null ? tempStepMdodel.changeStatut : false,
                idStatutForm: tempStepMdodel.idStatutForm != undefined && tempStepMdodel.idStatutForm != null ? tempStepMdodel.idStatutForm : null,
                isCollab: tempStepMdodel.isCollab != undefined && tempStepMdodel.isCollab != null ? tempStepMdodel.isCollab : false,
                isAllowApprop: tempStepMdodel.isAllowApprop != undefined && tempStepMdodel.isAllowApprop != null ? tempStepMdodel.isAllowApprop : false,
                isCreateForm: tempStepMdodel.isCreateForm != undefined && tempStepMdodel.isCreateForm != null ? tempStepMdodel.isCreateForm : false,
                createFormType: tempStepMdodel.createFormType != undefined && tempStepMdodel.createFormType != null ? tempStepMdodel.createFormType : null,
                isCreateFDP: tempStepMdodel.isCreateFDP != undefined && tempStepMdodel.isCreateFDP != null ? tempStepMdodel.isCreateFDP : false,
                isConvocation: tempStepMdodel.isConvocation != undefined && tempStepMdodel.isConvocation != null ? tempStepMdodel.isConvocation : false,
                isResponse: tempStepMdodel.isResponse != undefined && tempStepMdodel.isResponse != null ? tempStepMdodel.isResponse : false,
                responseModelParentId: tempStepMdodel.responseModelParentId != undefined && tempStepMdodel.responseModelParentId != null ? tempStepMdodel.responseModelParentId : null,
            },
            valueRadioIsRequired: tempStepMdodel != undefined && tempStepMdodel.isRequired != undefined && tempStepMdodel.isRequired != null ? tempStepMdodel.isRequired == true ? "oui" : "non" : "oui",
            isStepOrientation: tempStepMdodel != undefined && tempStepMdodel.isStepOrientation != undefined && tempStepMdodel.isStepOrientation != null ? tempStepMdodel.isStepOrientation : false,
            suggestionInvitation: listSugestionInvitation,
            idsModel: [],
            realNbDays: tempStepMdodel.realNbDays != undefined && tempStepMdodel.realNbDays != null ? tempStepMdodel.realNbDays : 1,
            choiceType: tempStepMdodel.typeTask != undefined && tempStepMdodel.typeTask != null ? tempStepMdodel.typeTask : listSugestionInvitation[0],
            loadingButton: false,
            modelInfo: Store.modelWf,
            listStatusFormSuggestion: [{ value: undefined, label: "Chargement en cours..." }],
            listFormTypeSuggestion: [{ value: undefined, label: "Chargement en cours..." }]
        }
        this.getFormType();
        this.getALLModel();
        if (Store.modelWf.formType != undefined && Store.modelWf.formType != null && Store.modelWf.formType.length >0) {
            this.getAllStatutForTypeForm(Store.modelWf.formType);
        }
    }

    public compare(a, b) {
        if (a.name < b.name)
            return -1;
        if (a.name > b.name)
            return 1;
        return 0;
    }

    // Fonction qui récupére les statuts grc en base
    // private getAllStatutForm = () => {
    //     axios.get(Store.wsPath + `/1/formulaire/GetAllFormStatut`).then(responseStatut => {
    //         if (responseStatut.data.length > 0) {
    //             this.setState({ listStatusFormSuggestion: responseStatut.data.map(status => {return {value:status.id, label:status.name}}) });
    //         }
    //         else {
    //             this.setState({ listStatusFormSuggestion: [{ value: undefined, label: "Pas de statut disponible" }] });
    //         }
    //     }).catch(error => {
    //         console.error("Error 'getAllStatutForm' : ", error);
    //         this.setState({ listStatusFormSuggestion: [{ value: undefined, label: "Pas de statut disponible" }] });
    //     });
    // }
    getFormType = () => {
        axios.get(Store.wsPath + "/1/formulaire/GetAllFormType").then(result => {
            const allTypeForm = result.data.map(type => ({
                value: type.name,
                label: type.label
            }));
            this.setState({ listFormTypeSuggestion: allTypeForm })
        });

    }
    getAllStatutForTypeForm = (typeFormName) => {
        axios.get(Store.wsPath + `/1/formulaire/GetAllStatutForFormType`, {
            params: {
                typeFormName: typeFormName
            }
        }).then(responseStatut => {
            if (responseStatut.data.length > 0) {
                this.setState({ listStatusFormSuggestion: responseStatut.data.map(status => { return { value: status.id, label: status.name } }) });
            }
            else {
                this.setState({ listStatusFormSuggestion: [{ value: undefined, label: "Pas de statut disponible" }] });
            }
        }).catch(error => {
            console.error("Error 'getAllStatutForm' : ", error);
            this.setState({ listStatusFormSuggestion: [{ value: undefined, label: "Pas de statut disponible" }] });
        });
    }
    public editStepModel = (event) => {
        event.preventDefault();

        this.setState({ loadingButton: true }, () => {
            const convertIsRequired: boolean = this.state.valueRadioIsRequired == 'oui' ? true : false
            axios.post(Store.wsPath + `/1/workflows/updateStepModel`, {
                idStep: this.state.copieStepModel.id,
                nameStep: this.state.copieStepModel.name,
                idsRole: this.state.copieStepModel.idsRole,
                realNbDays: this.state.realNbDays,
                typeTask: this.state.choiceType,
                valueRadioIsRequired: convertIsRequired,
                isStepDorientation: this.state.isStepOrientation,
                idModel: this.state.copieStepModel.idxModel,
                idsRoleSecondaire: this.state.copieStepModel.idsRoleSecondaire,
                isNotified: this.state.copieStepModel.isNotified,
                changeStatut: this.state.copieStepModel.changeStatut,
                idxStatut: this.state.copieStepModel.idxStatut,
                idStatutForm: this.state.copieStepModel.idStatutForm,
                isCollab: this.state.copieStepModel.isCollab,
                isAllowApprop: this.state.copieStepModel.isAllowApprop,
                isCreateForm: this.state.copieStepModel.isCreateForm,
                createFormType: this.state.copieStepModel.createFormType,
                isCreateFDP: this.state.copieStepModel.isCreateFDP,
                isConvocation: this.state.copieStepModel.isConvocation,
                isResponse: this.state.copieStepModel.isResponse,
                responseModelParentId:this.state.copieStepModel.responseModelParentId
            }).then(responseSteps => {
                this.setState({ loadingButton: false });
                this.reloadData();
                Store.snackBar.message = "Etape mise à jour avec succès";
                Store.snackBar.open = true;
            }).catch(error => {
                console.error("Error 'editStepModel' : ", error);
                this.setState({ loadingButton: false });
                Store.snackBar.message = "Etape mise à jour avec succès";
                Store.snackBar.error = true;
                Store.snackBar.open = true;
            });
        });
    }

    public reloadData = () => {
        Store.reloadListModel = "model";
    }

    public onChange = (name: string, option?) => event => {
        if (name == "insertStepModel" || name == "editStepModel") {
            const monState = this.state.copieStepModel;
            monState.name = event.target.value;
            this.setState({ copieStepModel: monState })
        } else if (name == "isStepOrientation") {
            let changeValueRadioIsRequired: string = ""
            if (event.target.checked == true) {
                changeValueRadioIsRequired = "oui"
            } else {
                changeValueRadioIsRequired = this.state.valueRadioIsRequired
            }
            let copieStepModel = this.state.copieStepModel;
            copieStepModel.isCollab = false;
            this.setState({
                isStepOrientation: event.target.checked,
                copieStepModel: copieStepModel,
                valueRadioIsRequired: changeValueRadioIsRequired
            })
        } else if (name == "isCollab") {
            const myState: IStepsModel = { ...this.state.copieStepModel };
            myState.isCollab = event.target.checked;
            this.setState({
                copieStepModel: myState
            })
        }
        else if (name == "changeStatut") {
            const myState: IStepsModel = { ...this.state.copieStepModel };
            let changeValueRadioIsRequired: string = ""
            if (event.target.checked == true) {
                changeValueRadioIsRequired = "oui";
                myState.changeStatut = true;
            } else {
                changeValueRadioIsRequired = this.state.valueRadioIsRequired;
                myState.changeStatut = false;
            }

            this.setState({
                copieStepModel: myState,
                valueRadioIsRequired: changeValueRadioIsRequired
            });
        }
        else if (name == "statutValue") {
            if (event != undefined) {
                const myState: IStepsModel = { ...this.state.copieStepModel };
                myState.idStatutForm = event.value;

                this.setState({
                    copieStepModel: myState
                });
            }
        }
        else if (name == "formTypeValue") {
            if (event != undefined) {
                const myState: IStepsModel = { ...this.state.copieStepModel };
                myState.createFormType = event.value;

                if (event.value != "recruit") {
                    myState.isCreateFDP = false;
                }

                this.setState({
                    copieStepModel: myState
                });
            }
        }
        else if (name == "isMailNotified") {
            let copieStepModel = this.state.copieStepModel;
            if (event != undefined && event != null) {
                copieStepModel.isNotified = event.target.checked
                this.setState({
                    copieStepModel: copieStepModel
                });
            } else {
                copieStepModel.isNotified = null
                this.setState({
                    copieStepModel: copieStepModel
                });
            }
        }
        else if (name == "typeInvitation") {
            if (event != null) {
                this.setState({
                    choiceType: {
                        value: event.value,
                        label: event.label
                    }
                });
            } else {
                this.setState({
                    choiceType: null
                });
            }
        }
        else if (name == "isAllowApprop") {
            const myState: IStepsModel = { ...this.state.copieStepModel };
            myState.isAllowApprop = event.target.checked;
            this.setState({
                copieStepModel: myState
            });
        }
        else if (name == "isCreateForm") {
            const myState: IStepsModel = { ...this.state.copieStepModel };
            myState.isCreateForm = event.target.checked;
            // Si c'est oui on passe l'étape en mode collaboratif
            if(event.target.checked == true) {
                myState.isCollab = true;
            }
            this.setState({
                copieStepModel: myState
            });
        }
        else if (name == "isCreateFDP") {
            const myState: IStepsModel = { ...this.state.copieStepModel };
            myState.isCreateFDP = event.target.checked;
            this.setState({
                copieStepModel: myState
            });
        }
        else if (name == "isConvocation") {
            const myState: IStepsModel = { ...this.state.copieStepModel };
            myState.isConvocation = event.target.checked;
            // Si c'est oui on passe l'étape en mode collaboratif
            if(event.target.checked == true) {
                myState.isCollab = true;
            }
            this.setState({
                copieStepModel: myState
            });
        }
        else if (name == "isResponse") {
            const myState: IStepsModel = { ...this.state.copieStepModel };
            let isStepOrientation = this.state.isStepOrientation;
            myState.isResponse = event.target.checked;
            // Si c'est oui on passe l'étape en mode collaboratif
            if(event.target.checked == true) {
                myState.isCollab = true;
                isStepOrientation = false;
            }else{
                myState.responseModelParentId = Store.stepModel.responseModelParentId
            }
            this.setState({
                copieStepModel: myState,
                isStepOrientation: isStepOrientation
            });
      }
        else if (name == "dureeEtape") {
            this.setState({ realNbDays: event.target.value });
            // Store.realNbDays = event.target.value
        } if (name == "nameRole") {
            Store.addEditRole.name = event.target.value
        }
    }

    public insertStepModel = (event) => {
        event.preventDefault();

        this.setState({ loadingButton: true }, () => {
            const convertIsRequired: boolean = this.state.valueRadioIsRequired == 'oui' ? true : false

            const newStep: IStepsModel = {
                name: this.state.copieStepModel.name,
                idxModel: this.state.copieStepModel.idxModel,
                state: this.state.copieStepModel.state,
                rankStep: this.state.copieStepModel.rankStep + 1,
                idsRole: this.state.copieStepModel.idsRole,
                typeTask: this.state.choiceType,
                realNbDays: this.state.realNbDays,
                isRequired: convertIsRequired,
                isStepOrientation: this.state.isStepOrientation,
                idsRoleSecondaire: this.state.copieStepModel.idsRoleSecondaire,
                isNotified: this.state.copieStepModel.isNotified,
                changeStatut: this.state.copieStepModel.changeStatut,
                idxStatut: this.state.copieStepModel.idxStatut,
                idStatutForm: this.state.copieStepModel.idStatutForm,
                isCollab: this.state.copieStepModel.isCollab,
                isAllowApprop: this.state.copieStepModel.isAllowApprop,
                isCreateForm: this.state.copieStepModel.isCreateForm,
                createFormType: this.state.copieStepModel.createFormType,
                isCreateFDP: this.state.copieStepModel.isCreateFDP,
                isConvocation: this.state.copieStepModel.isConvocation,
                isResponse: this.state.copieStepModel.isResponse,
                responseModelParentId:this.state.copieStepModel.responseModelParentId
            };

            const stepBefore = {
                id: this.state.copieStepModel.id,
                rankStep: this.state.copieStepModel.rankStep,
            }

            axios.post(Store.wsPath + `/1/workflows/insertStepinModel`, {
                idxModel: this.state.copieStepModel.idxModel,
                newStep: newStep,
                stepBefore: stepBefore
            }).then(response => {
                this.setState({ loadingButton: false });
                this.reloadData();
                // SnackBar
                Store.snackBar.message = "Etape ajoutée au modèle avec succès";
                Store.snackBar.open = true;
            }).catch(error => {
                console.error("Error 'insertStepModel' : ", error);
                // SnackBar
                Store.snackBar.message = "Une erreur est survenue lors de l'ajout de l'étape au modèle, réessayez";
                Store.snackBar.error = true;
                Store.snackBar.open = true;
                this.setState({ loadingButton: false });
            });
        });
    }

    public onChangeSelect = (name, option) => {
        if (name == "editStepModel") {
            const tempState = this.state.copieStepModel;
            if (option.length > 0) {
                const tempArray = option.map(role => role.value);
                tempState.idsRole = tempArray;
                this.setState({ copieStepModel: tempState });
            }
            else {
                tempState.idsRole = [];
                tempState.idsRoleSecondaire = [];
                this.setState({ copieStepModel: tempState });
            }
        }
        else if (name == "roleSecondaire") {
            const tempState = this.state.copieStepModel;
            if (option.length > 0) {
                const tempArray = option.map(role => role.value);
                tempState.idsRoleSecondaire = tempArray;
                this.setState({ copieStepModel: tempState });
            }
            else {
                tempState.idsRoleSecondaire = [];
                this.setState({ copieStepModel: tempState });
            }
        }
    }

    public updateReactSelect = (idsRole: number[]) => {
        const tempArray: any[] = [];
        idsRole.forEach(idRole => {
            const tempSuggestion = this.state.roleSuggestionsState.find(suggestion => suggestion.value == idRole);
            tempArray.push(tempSuggestion);
        });

        return tempArray;
    }

    public updateReactSelectRole = (user: any) => {
        const myUser = Store.suggestionsUser.find(suggestion => suggestion.value == user);
        return myUser;
    }

    public getAllRoles = () => {
        getAllRoles().then(response => {
            this.setState({
                roleSuggestionsState: response.sort(this.compare).map((suggestion: IRole) => ({
                    value: suggestion.id,
                    label: suggestion.name,
                    title: suggestion.user
                }))
            }, () => {
                if (this.state.valueLabelRole != "" || this.state.valueLabelRole != null || this.state.valueLabelRole != undefined) {
                    for (let i = 0; i < this.state.roleSuggestionsState.length; i++) {
                        if (this.state.roleSuggestionsState[i].label == this.state.valueLabelRole) {
                            const tempTest = this.state.copieStepModel;
                            tempTest.idsRole.push(this.state.roleSuggestionsState[i].value);
                            this.setState({ copieStepModel: tempTest, valueLabelRole: "" });
                        }
                    }
                }
            });
        }).catch((error) => {
            console.error("Error 'getAllRoles' data : ", error);
            console.error("Error 'getAllRoles' status : ", error);
            console.error("Error 'getAllRoles' headers : ", error);
        })
    }

    public addRole = (event) => {
        event.preventDefault();
        if (Store.addEditRole.name != null || Store.addEditRole.name != undefined) {
            this.setState({ valueLabelRole: Store.addEditRole.name }, () => {
                axios.post(Store.wsPath + `/1/roles/createRole`, {
                    nameRole: Store.addEditRole.name,
                    idUser: Store.addEditRole.idxUser
                }).then(response => {
                    if (response.status == 200) {
                        this.getAllRoles();
                        Store.addEditRole = Store.DefaultValueRole;
                        // SnackBar
                        Store.snackBar.message = "Rôle ajouté avec succés"
                        Store.snackBar.open = true;
                        Store.snackBar.error = false;
                        this.toggleRole();
                    }
                    else {
                        // SnackBar
                        Store.snackBar.message = "Une erreur c'est produite lors de l'ajout du rôle, réessayez";
                        Store.snackBar.error = true;
                        Store.snackBar.open = true;
                    }
                }).catch(error => {
                    console.error("Error 'addRole' : ", error);
                    // SnackBar
                    Store.snackBar.message = "Une erreur c'est produite lors de l'ajout du rôle, réessayez";
                    Store.snackBar.error = true;
                    Store.snackBar.open = true;
                });
            });
        }
        else {
            // SnackBar
            Store.snackBar.message = "Une erreur c'est produite lors de l'ajout du rôle, réessayez";
            Store.snackBar.error = true;
            Store.snackBar.open = true;
        }
    }

    public onChangeUser = (option) => {
        if (option != null) {
            Store.addEditRole.idxUser = option.value;
        }
        else {
            Store.addEditRole.idxUser = null;
        }
    }

    public toggleRole = () => {
        this.setState({
            displayElementRole: !this.state.displayElementRole
        });
    }

    public updateReactSelectInvit = (choice: any) => {
        const myValue = this.state.suggestionInvitation.find(suggestion => suggestion.value == choice.value);
        return myValue
    }
    // Fonction qui récupére tous les modéles
  public getALLModel= () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const responseAx = await axios.get(Store.wsPath + `/1/workflows/getAllModelWf`);
        const allModelsWF =responseAx.data;
        const treeModeles = await this.constructTree(allModelsWF);
        
        this.setState({ treeModeles: treeModeles.treeModeles });
        resolve();
      } catch (error) {
        console.error("Error 'getAllModelsWf' : ", error);
        reject("Error 'getAllModelsWf' : " + error);
      }
    });
  }
  public constructTree = async (modeles: any[]) => {
    const modelesParent: any[] = modeles.filter(modele => modele.idParent == null);
    const testResult: any = [];
    if (modelesParent.length > 0) {
        for (const modeleParent of modelesParent) {
            testResult.push({ key: modeleParent.id.toString(), id: modeleParent.id, label: modeleParent.name, nodes: [], isGRC: modeleParent.isGRC, isForm: modeleParent.isForm, formType:modeleParent.formType, guid:modeleParent.guid });
        }
        return { treeModeles: testResult, allModelsList: modeles };
    }
    else {
        testResult.push({ key: "Pas de modèle pour le moment", label: "Pas de modèle pour le moment", name: "Pas de modèle pour le moment", id: "noModel" });
        return { treeModeles: testResult, allModelsList: []};
    }
  }
  public onClickModele = (nodes) => {
    if (nodes != undefined && nodes != null) {
      const monState = this.state.copieStepModel;
      monState.responseModelParentId = nodes.id;
      this.setState({ selectedParentResponseModel: { name: nodes.label, guid: nodes.guid, id: nodes.id },copieStepModel:monState  });
      
    }
  }
    public onChangeBtnRadio = (name: string) => event => {
        if (name == "isRequired" && this.state.isStepOrientation == false) {
            this.setState({
                valueRadioIsRequired: event.target.value
            });
        }
    }

    public render() {
        // Suggestion roles secondaire avis non bloquant (Role qui sont la en lecture sans bloquer le wf)
        const suggestionRoleSecondaire: any[] = [];
        Store.allRoles.sort(this.compare).forEach((suggestion: IRole) => {
            const tempStateRole = this.state.copieStepModel.idsRole != undefined && this.state.copieStepModel.idsRole != null ? this.state.copieStepModel.idsRole : [];
            // On test si le role est déjà présent dans les roles principaux, si c'est le cas on ne le mets pas dans la liste des suggestions des roles secondaire
            if (tempStateRole.filter(idRole => idRole == suggestion.id).length == 0) {
                suggestionRoleSecondaire.push({
                    value: suggestion.id,
                    label: suggestion.name,
                    title: suggestion.user
                });
            }
        });
        let propsSelect:any ={}
        if (this.state.copieStepModel.responseModelParentId != undefined) {
          propsSelect ={initialActiveKey : this.state.copieStepModel.responseModelParentId.toString()};
        }
        return (
            <div>
                <form autoComplete="false" onSubmit={Store.contextualmenu.content == "insertStepModel" ? this.insertStepModel : this.editStepModel}>
                    <h5 className={styles.titleContextual}>{Store.contextualmenu.content == "insertStepModel" ? "Ajouter une étape" : "Modifier l'étape"}</h5>
                    <TextField
                        // InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        className={styles.field}
                        required
                        type="text"
                        defaultValue={this.state.copieStepModel.name}
                        onChange={this.onChange("editStepModel")}
                        label="Nom de l'étape"
                    />
                    <InputLabel>Rôle(s) assigné(s) à l'étape * :</InputLabel>
                    <div style={{ position: "relative" }}>
                        <SelectReact
                            options={this.state.roleSuggestionsState}
                            // components = {option => this.animated(option ? option : null)}
                            onChange={option => this.onChangeSelect("editStepModel", option ? option : null)}
                            placeholder="Rôle(s) assigné(s) à l'étape *"
                            filterOption={createFilter({ ignoreAccents: false })}
                            className={styles.field}
                            styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                            isClearable
                            isMulti
                            value={this.state.copieStepModel.idsRole != undefined ? this.updateReactSelect(this.state.copieStepModel.idsRole) : null}
                            components={{ MultiValueLabel: MultiValueLabel, Option: OptionSelect }}
                        />
                    </div>

                    <InputLabel>Rôle(s) secondaire :</InputLabel>
                    <div style={{ position: "relative" }}>
                        <SelectReact
                            options={suggestionRoleSecondaire}
                            // components = {option => this.animated(option ? option : null)}
                            onChange={option => this.onChangeSelect("roleSecondaire", option ? option : null)}
                            placeholder="Rôle(s) secondaire :"
                            filterOption={createFilter({ ignoreAccents: false })}
                            className={styles.field}
                            styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                            isClearable
                            isMulti
                            value={this.state.copieStepModel.idsRoleSecondaire != undefined ? this.updateReactSelect(this.state.copieStepModel.idsRoleSecondaire) : null}
                            isDisabled={this.state.copieStepModel.idsRole != undefined && this.state.copieStepModel.idsRole != null && this.state.copieStepModel.idsRole.length > 0 ? false : true}
                            components={{ MultiValueLabel: MultiValueLabel, Option: OptionSelect }}
                        />
                    </div>

                    {/* // ANAIS TEST */}
                    <div className={styles.addrole} onClick={this.toggleRole}><Icon className={styles.iconPlus}>add</Icon>Ajouter un rôle</div>
                    <div>
                        {this.state.displayElementRole == true ?
                            <div>
                                <TextField
                                    // InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    className={styles.field}
                                    required
                                    type="text"
                                    value={Store.addEditRole.name != undefined ? Store.addEditRole.name : ""}
                                    onChange={this.onChange("nameRole")}
                                    label="Nom du rôle"
                                />

                                <InputLabel>Personne assignée au rôle :</InputLabel>
                                <div style={{ position: "relative" }}>
                                    <SelectReact
                                        options={Store.suggestionsUser}
                                        onChange={option => this.onChangeUser(option ? option : null)}
                                        placeholder="Personne assignée au rôle"
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        value={Store.addEditRole.idxUser != undefined ? this.updateReactSelectRole(Store.addEditRole.idxUser) : null}
                                        className={styles.field}
                                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                                        isClearable
                                        composant
                                        components={{ SingleValue: customSingleValue, Option: MenuList }}
                                    />
                                </div>

                                <Button
                                    onClick={this.addRole}
                                    className={styles.btnAddUser}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                >
                                    Ajouter
                                </Button>

                            </div>

                            : ""}
                    </div>

                    <TextField
                        variant='outlined'
                        className={styles.field}
                        required
                        type="number"
                        value={this.state.realNbDays}
                        onChange={this.onChange("dureeEtape")}
                        label="Nombre de jour pour l'étape"
                    />

                    <div>
                        <SelectReact
                            options={this.state.suggestionInvitation}
                            defaultValue={{ value: 1, label: "Contribution" }}
                            onChange={this.onChange('typeInvitation')}
                            placeholder="Type d'invitation"
                            filterOption={createFilter({ ignoreAccents: false })}
                            value={(this.state.choiceType != undefined && this.state.choiceType.value != undefined) ? this.updateReactSelectInvit(this.state.choiceType) : this.state.suggestionInvitation[0]}
                            className={styles.field}
                            styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }), menu: styles => ({ ...styles, zIndex: "9999" }), option: styles => ({ ...styles, cursor: "pointer" }) }}
                        />
                    </div>

                    <div>
                        <FormLabel>Etape requise : </FormLabel>

                        <RadioGroup
                            name={"Sélection"}
                            value={this.state.valueRadioIsRequired}
                            onChange={this.onChangeBtnRadio("isRequired")}
                        >
                            <div className={styles.styleBtnRadio}>
                                <FormControlLabel
                                    checked={this.state.valueRadioIsRequired == 'oui' ? true : false}
                                    value="oui"
                                    control={<Radio className={styles.radioPadding} color="primary" />}
                                    label="Oui"
                                    disabled={this.state.isStepOrientation == true || this.state.copieStepModel.changeStatut == true}
                                />
                                <FormControlLabel
                                    checked={this.state.valueRadioIsRequired == 'oui' ? false : true}
                                    value="non"
                                    control={<Radio className={styles.radioPadding} color="primary" />}
                                    label="Non"
                                    disabled={this.state.isStepOrientation == true || this.state.copieStepModel.changeStatut == true}
                                />
                            </div>
                        </RadioGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={this.state.copieStepModel.isResponse}
                                    checked={this.state.isStepOrientation}
                                    onChange={this.onChange('isStepOrientation')}
                                    color='primary'
                                />
                            }
                            label="Etape d'orientation"
                        />
                        <FormControlLabel
                            disabled={this.state.isStepOrientation || this.state.copieStepModel.isCreateForm || this.state.copieStepModel.isConvocation || this.state.copieStepModel.isResponse}
                            control={
                                <Checkbox
                                    checked={this.state.isStepOrientation == true ? true : this.state.copieStepModel.isCollab}
                                    onChange={this.onChange('isCollab')}
                                    color='primary'
                                />
                            }
                            label="Etape collaborative"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.copieStepModel.isAllowApprop}
                                    onChange={this.onChange('isAllowApprop')}
                                    color='primary'
                                />
                            }
                            label="Autoriser l'appropriation"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.copieStepModel.isCreateForm}
                                    onChange={this.onChange('isCreateForm')}
                                    color='primary'
                                />
                            }
                            label="Créer un formulaire à la validation"
                        />
                        {this.state.copieStepModel.isCreateForm == true ?
                            <div style={{ paddingLeft: "15px", borderLeft: "1px solid darkgrey", marginLeft: "10px" }}>
                                <div style={{ position: "relative" }}>
                                    <InputLabel required={true}>
                                        Sélectionner le type du formulaire à créer
                                    </InputLabel>
                                    <SelectReact
                                        options={this.state.listFormTypeSuggestion}
                                        onChange={this.onChange('formTypeValue')}
                                        placeholder="Type du formulaire"
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        value={this.state.copieStepModel.createFormType != undefined && this.state.copieStepModel.createFormType != null && this.state.listFormTypeSuggestion.filter(formType => formType.value == this.state.copieStepModel.createFormType)[0] != undefined ? this.state.listFormTypeSuggestion.filter(formType => formType.value == this.state.copieStepModel.createFormType)[0] : ""}
                                        className={styles.field}
                                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }), menu: styles => ({ ...styles, zIndex: "9999" }), option: styles => ({ ...styles, cursor: "pointer" }) }}
                                    />
                                    {/* Input caché pour le required pour le select */}
                                    <input onChange={() => null} style={{ opacity: 0, height: 0, padding: 0, position: "absolute", margin: 0, left: "10%", top: "50%", borderColor: "transparent" }} required={true} value={this.state.copieStepModel.createFormType != undefined && this.state.copieStepModel.createFormType != null ? this.state.copieStepModel.createFormType : ""} />
                                </div>
                                {this.state.copieStepModel.createFormType != undefined && this.state.copieStepModel.createFormType != null && this.state.copieStepModel.createFormType == "recruit" ?
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.copieStepModel.isCreateFDP}
                                                onChange={this.onChange('isCreateFDP')}
                                                color='primary'
                                            />
                                        }
                                        label="Créer une fiche de poste"
                                    />
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                        }
                        {
                            this.state.modelInfo.formType == "recruit" ?
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.copieStepModel.isConvocation}
                                            onChange={this.onChange('isConvocation')}
                                            color='primary'
                                        />
                                    }
                                    label="Étape de convocation"
                                />
                                :
                                <React.Fragment></React.Fragment>
                        }    
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.copieStepModel.isResponse}
                                    onChange={this.onChange('isResponse')}
                                    color='primary'
                                />
                            }
                            label="Étape de réponse"
                        />
                        {this.state.copieStepModel.isResponse == true ?
                          <div>
                            <Typography variant="subtitle2" id="simple-modal-description">
                              Veuillez sélectionner le modèle contenant les modèles de réponse
                            </Typography>
                            <TreeMenu
                              data={this.state.treeModeles}
                              onClickItem={this.onClickModele}
                              {...propsSelect}
                            />
                          </div>
                          :<React.Fragment></React.Fragment>
                        }
                              
                               
                        {(this.state.modelInfo.isGRC == true || this.state.modelInfo.isForm == true) && this.state.listStatusFormSuggestion.length != 0 && this.state.listStatusFormSuggestion[0].value != undefined ?
                            <div>
                                <FormControlLabel
                                    className={styles.field}
                                    control={
                                        <Checkbox
                                            checked={this.state.copieStepModel.changeStatut}
                                            onChange={this.onChange('changeStatut')}
                                            color='primary'
                                        />
                                    }
                                    label="Modifier le statut de la demande à la fin de cette étape"
                                />
                                {this.state.copieStepModel.changeStatut == true ?
                                    <div style={{ position: "relative" }}>
                                        <InputLabel required={true}>
                                            Sélectionner un statut
                                        </InputLabel>
                                        <SelectReact
                                            options={this.state.listStatusFormSuggestion}
                                            onChange={this.onChange('statutValue')}
                                            placeholder="Statut"
                                            filterOption={createFilter({ ignoreAccents: false })}
                                            value={this.state.copieStepModel.idStatutForm != undefined && this.state.copieStepModel.idStatutForm != null && this.state.listStatusFormSuggestion.filter(statut => statut.value == this.state.copieStepModel.idStatutForm)[0] != undefined ? this.state.listStatusFormSuggestion.filter(statut => statut.value == this.state.copieStepModel.idStatutForm)[0] : ""}
                                            className={styles.field}
                                            styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }), menu: styles => ({ ...styles, zIndex: "9999" }), option: styles => ({ ...styles, cursor: "pointer" }) }}
                                        />

                                        {/* Input caché pour le required pour le select */}
                                        <input onChange={() => null} style={{ opacity: 0, height: 0, padding: 0, position: "absolute", margin: 0, left: "10%", top: "50%", borderColor: "transparent" }} required={true} value={this.state.copieStepModel.idStatutForm != undefined && this.state.copieStepModel.idStatutForm != null ? this.state.copieStepModel.idStatutForm : ""} />
                                    </div>
                                    :
                                    ""
                                }
                            </div>
                            :
                            ""
                        }

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.copieStepModel.isNotified}
                                    onChange={this.onChange('isMailNotified')}
                                    color='primary'
                                />
                            }
                            label="Notifier d'un mail lors de l'ouverture d'une tâche."
                        />
                    </div>


                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={this.state.loadingButton == true || this.state.copieStepModel.idsRole == undefined || this.state.copieStepModel.idsRole == null || this.state.copieStepModel.idsRole.length == 0 ? true : false}
                    >
                        {this.state.loadingButton == true ?
                            <Spinner size={24} />
                            :
                            "Valider"
                        }
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        className={styles.btnCancel}
                        color="default"
                        onClick={() => { Store.stepModel = undefined; Store.contextualmenu = Store.defaultValueContextualMenu }}
                    >
                        Annuler
                    </Button>
                </form>
            </div>
        )
    }
}