import * as React from 'react'
import { DropzoneComponent } from 'react-dropzone-component';
import Store from '../store/store';
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
//import {  StateVersionYes } from '../ListeDocuments/interfaces/IDoc';
//import { IDoc,StateVersionYes} from '../ListeDocuments/interfaces/IDoc'
import axios from 'axios';
require('../../../node_modules/react-dropzone-component/styles/filepicker.css');
require('../../../node_modules/dropzone/dist/min/dropzone.min.css');
import download from "downloadjs";

// const styles = require('./styles/Dropzone.scss')
interface Props{
  docassocie? : boolean
}
interface State {
  dzMessage: string
}
@observer
class Dropzone extends React.Component<RouteComponentProps<any>, State> {

  constructor(props: any) {
    super(props)
    this.state = {
      dzMessage: ""
    }
  }

  public DeserializeDocument(strDocument:any) { // TODO Provisoire en attendant d'avoir la table pour workflow
    const resultDocument:any = { title: strDocument.title };
    resultDocument.reference = strDocument.reference;
    if (strDocument.fileName) {
      resultDocument.fileName = strDocument.fileName.toString().substr(strDocument.fileName.toString().lastIndexOf("/") + 1)
    }

    // if (strDocument.state.toString() == "En préparation") {
    //     resultDocument.state = 0
    //     this.setState({
    //         stateDocSelected: "En préparation"
    //     })
    // }

    // else if (strDocument.state.toString() == "En vigueur") {
    //     resultDocument.state = 1
    //     this.setState({
    //         stateDocSelected: "En vigueur"
    //     })
    // }

    // else if (strDocument.state.toString() == "Retiré") {
    //     resultDocument.state = 2
    //     this.setState({
    //         stateDocSelected: "Retiré"
    //     })
    // }

    // else
    //     resultDocument.state = strDocument.state;

    // resultDocument.id = strDocument.id

    resultDocument.matriculeDoc = strDocument.matriculeDoc

    resultDocument.workflow = strDocument.workflow
    if (!strDocument.workflow) {
      resultDocument.workflow = []
    }

    if (strDocument.numVersion === null)
      resultDocument.numVersion = "1.0"
    else
      resultDocument.numVersion = strDocument.numVersion

    if (strDocument.object === null)
      resultDocument.object = ""
    else
      resultDocument.object = strDocument.object

    if (strDocument.natureIdx) {
      resultDocument.natureIdx = strDocument.natureIdx
    }
    return resultDocument
  }
  

  public blobToFile = (theBlob: Blob, fileName: string): Promise<File> => {
    return new Promise((resolve, reject) => {
      const b: any = theBlob;
      b.lastModified = new Date();
      b.name = fileName;
      const file: File = b;
      resolve(file);
    })
  }
  public onSuccessUpload = (item: any) => {
    // Axios créer doc
    const libelleDoc = item.fileName
    let extensionDoc: string
    extensionDoc = libelleDoc.substr(libelleDoc.length - 3)
    // this.setState((previousState: State) => ({
    //     fileUploaded: item ? item : previousState.fileUploaded,
    //     // documentSelected: null

    // }))

    const id = item.id
    const reference = item.reference
    const title = item.title
    const fileName = item.fileName
    const object = item.object
    const natureIdx = item.natureIdx
    const numVersion = item.numVersion
    // let privateDoc = item.privateDocument
    if (item.matriculeDoc != undefined) {
      Store.selectedDoc = item
      axios.post(Store.wsPath + '/1/documents/UpdateDocument', {
        respDoc: Store.userConnected.id,
        id: id,
        reference: reference,
        title: title,
        fileName: fileName,
        fileData: item.fileData,
        object: object,
        publishAfter: true,
        numVersion: numVersion,
        natureId: natureIdx,
        publicDoc: true
      }).then(() => {

        axios.get(Store.wsPath + '/1/documents/getOneDocumentByMatricule', { params: { matriculeDoc: item.matriculeDoc } })
          .then(response => {
            const data = response.data;
            const updatedDocument = this.DeserializeDocument(data);
            const mesDocs = Store.listDocsDisplaying;
            for (let i = 0; i < mesDocs.length; i++) {
              if (mesDocs[i].id === item.id) {
                mesDocs[i] = updatedDocument
                break
              }
            }

            // this.setState({
            //     emptyDoc: false,
            //     changeTab: 0,
            //     idFromDb: data.id,
            //     fileUploaded: ({ title: response.data.fileName, fileName: response.data.fileName }),
            //     documentSelected: this.DeserializeDocument(data),
            //     divDisplayed: "Form",
            //     selectedDocumentReference: data.matriculeDoc,
            //     open: false,
            //     isHiddenContextualMenu: false, // Menu contextuel affiché
            //     contentDisplaying: "FormEdit", // Contenu du menu contextuel
            //     contentDisplayingFilAriane: "ModifyFicheDocumentaire",
            //     isfile: false,
            //     displayContextualMenu: true,
            // })

            let extensionDoc: string
            extensionDoc = updatedDocument.fileName.substr(updatedDocument.fileName.length - 3)

            // if (extensionDoc == "pdf") {
            //     this.setState({
            //         viewForm: "withDocPdf"
            //     })
            // }
            // else {
            //     this.setState({
            //         viewForm: "withDocNoPdf"
            //     })
            // }

          });

      });
    }
    else {
      axios.post(Store.wsPath + '/1/documents/createDocument', {
        respDoc: Store.userConnected.id,
        reference: item.reference,
        title: item.title,
        fileName: item.fileName,
        fileData: item.fileData,
        object: item.object,
        publishAfter: false,
        natureId: item.nature,
        numVersion: item.numVersion
      }).then((response) => {

        Store.selectedDoc = response.data;
        Store.divDisplayed = "Form";
        this.props.history.push('/referencetodocument');
      })

      // if (extensionDoc == "pdf") {
      //     this.setState({
      //         viewForm: "withDocPdf"
      //     })
      // }
      // else {
      //     this.setState({
      //         viewForm: "withDocNoPdf"
      //     })
      // }
    }
  }
  render() {
    let dropzone = null;


    const eventHandlersForm = {
      init: (dz) => {
        dropzone = dz;
      },
      addedfile: (file) => {
        let idDocument
        if(Store.selectedDoc.id != undefined){
          idDocument = Store.selectedDoc.id
        }else{
          idDocument = Store.selectedDoc.id
        }

        Store.isUploadDocument = true;
        const formData = new FormData();
        const body: string = JSON.stringify({
          id: idDocument,
          title: file.name,
          fileName: file.name
        })
        formData.append("json", body);
        formData.append("file", file, file.name);
        axios.post(Store.wsPath + '/1/dropzones/onUploadFileInForm/', formData, {
        }).then((response) => {
          if(response.status == 200)
          {
          Store.selectedDoc = response.data;
          Store.selectedDoc = response.data;
          Store.divDisplayed = "Form";
          // this.props.history.push('/referencetodocument');
          dropzone.options.complete(file);
          }
          else{
           // console.log("je passe dans l'erreur !!!")
            dropzone.options.error(file,response.data);
          }
        //  console.log('response.data TEST !!!!!! : ', response.data)
        })
      },
      success: (file) => {
      //  console.log("successs !!!!", file);
      },
      error: (file) => {
        return "error"
      },
      complete: (file) => {
       // console.log("completed !!")
      },
      uploadprogress: (file, progress) => {
       // console.log("uploadprogress !!",progress)
        progress = 100;
        return progress;
      },
      sending: (file) => {
      //  console.log("sending !!")
      },
      queuecomplete: null
    }
    const eventHandlers = {
      init: (dz) => {
        dropzone = dz;
       // console.log("dz !!!!",dropzone);

      },
      addedfile: (file) => {
       // console.log('list addedfile')
        Store.isUploadDocument = true;
        const formData = new FormData();
        const body: string = JSON.stringify({
          respDoc: Store.userConnected.id,
          reference: undefined,
          title: file.name,
          fileName: file.name,
          object: undefined,
          publishAfter: false,
          natureId: undefined,
          numVersion: undefined
        })
        formData.append("json", body);
        formData.append("file", file, file.name);
        axios.post(Store.wsPath + '/1/dropzones/onUploadFile/', formData).then((response) => {
        //  console.log('')
          Store.selectedDoc = response.data;
          Store.divDisplayed = "Form";
          this.props.history.push('/referencetodocument');
        })
      },
      success: (file) => {
      //  console.log("successs !!!!", file);
      },
      error: (file) => {
       // console.log("coucouc error !!!!!!!!!");
        return "error"
      },
      complete: (file) => {
      //  console.log("completed !!")
      },
      uploadprogress: (file, progress) => {
      //  console.log("uploadprogress !!",progress)
        progress = 100;
        return progress;
      },
      sending: (file) => {
       // console.log("sending !!")
      },
      queuecomplete: null
    }


    const djsConfig = {
      addRemoveLinks: false,
      clickable: false,
      // previewTemplate: "<div></div>",
      message: "Upload",
      autoProcessQueue: false,
      dictDefaultMessage: "Glisser/déposer pour importer un document",
    }

    // const componentConfig = {
    //   maxFiles: 1,
    //   iconFiletypes: ['.pdf', '.docx'],
    //   showFiletypeIcon: true,
    //   postUrl: Store.wsPath + '/1/dropzones/onUploadFile/'
    // }
    const componentConfig = {
      maxFiles: 1,
      iconFiletypes: ['.pdf', '.docx'],
      showFiletypeIcon: true,
      postUrl: 'no-url'
    }

    return (
      <div /* className={styles.contentUpload} */>
        {Store.userConnected ?
          <div>
            {Store.userConnected.typeUserLydoc == 'ged' || Store.userConnected.typeUserLydoc == 'admin' ?
              <div>
                <DropzoneComponent config={componentConfig} djsConfig={djsConfig} eventHandlers={Store.isList == true ? eventHandlers:eventHandlersForm}/>
              </div>
              : ""
            }
          </div>
          : ""
        }
      </div>
    )
  }
}
export default withRouter(Dropzone);