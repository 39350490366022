import * as React from 'react'
import UploadZone from "../Dropzone/Dropzone"
const styles = require('../ListeDocuments/styles.scss')
import axios from 'axios';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Icon  from '@material-ui/core/Icon';
import Store from '../store/store'
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props {
}

interface State {
    valueRadioFolder?: any,
    valueNewFolder?: string,
}


@observer
class ManageVersion extends React.Component<RouteComponentProps<any>, State>{

    public constructor(props: any) {
        super(props)
        this.state = {
            valueRadioFolder: "",
            valueNewFolder: "",
        }

    }

    public archieveDocuments = () => {
        axios.post(Store.wsPath + '/1/documents/archieveSimpleDocument', {
            idDoc: Store.selectedDoc.id
        }).then((response) => {
            Store.selectedDoc.state = response.data.state
            Store.selectedDoc.state = response.data.state
            Store.snackBar.open = true
            Store.snackBar.message = "Le document a été archivé"
            Store.snackBar.error = false
        })
    }
    // public archieveDocuments = () => { //TODO SELECTION MULTIPLE ?
    //     axios.post(Store.wsPath + '/1/documents/archieveSimpleDocument', {
    //         idDoc: Store.idDOcSelected
    //     }).then((response) => {
    //         console.log('response', response.data)
    //         Store.selectedDoc.state = response.data.state
    //         Store.snackBar.open = true
    //         Store.snackBar.message = "Le document a été archivé"
    //         Store.snackBar.error = false
    //     })
    // }

    public onVersionInPreparation = () => {
        axios.post(Store.wsPath + `/1/documents/cancelPublishDocument`, {
            id: Store.selectedDoc.id
        })
            .then(response => {
                Store.selectedDoc.state = response.data.state
                Store.selectedDoc.state = response.data.state
                Store.snackBar.open = true
                Store.snackBar.message = "La version du document est en préparation"
                Store.snackBar.error = false
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('Error data : ', error.response.data);
                    console.log('Error status : ', error.response.status);
                    console.log('Error headers : ', error.response.headers);
                } else if (error.request) {
                    console.log('Error request : ', error.request);
                } else {
                    console.log('Error message : ', error.message);
                }
                console.log(error.config);
            })
    }

    public displayingFolder = () => {
        Store.isFolderDisplaying = !Store.isFolderDisplaying;
    }

    public onChangeValue = name => event => {
        if (name == "folder") {
            this.setState({
                valueRadioFolder: event.target.value
            })
        } else if (name == "textfieldNewFolder") {
            this.setState({
                valueNewFolder: event.target.value
            })
        } else if (name == "classeurSelect") {
            Store.idFolder = event.target.value
        }
    }

    public reinitialiseMnuContextual = () => {
        Store.contextualmenu.content = ""
        Store.contextualmenu.open = "hidden"
    }

    public addNewDocsInFolder = () => {
        axios.post(Store.wsPath + '/1/folders/addDocInNewFolder', {
            listIdDoc: Store.idDOcSelected,
            nameFolder: this.state.valueNewFolder
        });
        this.reinitialiseMnuContextual()
    }

    public addNewDocsInExistantFolder = () => {
        axios.post(Store.wsPath + '/1/folders/addDocInFolder', {
            listIdDoc: Store.idDOcSelected,
            idFolder: Store.idFolder
        }).then(() => {
            this.reinitialiseMnuContextual()
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    public getAllDocuments = () => {
        axios.get(Store.wsPath + `/1/documents/`, { params: { state: "all" } })
            .then(response => {
                Store.listDocsDisplaying = response.data
                Store.consulting = false
                Store.isNeedActionToDocument = false
            })
            .catch(function (error) {
                if (error.response) {
                    console.log('Error data : ', error.response.data);
                    console.log('Error status : ', error.response.status);
                    console.log('Error headers : ', error.response.headers);
                } else if (error.request) {
                    console.log('Error request : ', error.request);
                } else {
                    console.log('Error message : ', error.message);
                }
                console.log(error.config);
            })
    }
    public onPublishVersion = () => {
        let id
        if (Store.selectedDoc.id != undefined) {
            id = Store.selectedDoc.id
        } else {
            id = Store.selectedDoc.id
        }
        let title = Store.selectedDoc.title

        if (title != undefined && title != null && title != "") {
            axios.post(Store.wsPath + '/1/documents/updateDocumentNew', {
                respDoc: Store.userConnected.id,
                id: id,
                reference: Store.selectedDoc.reference,
                title: Store.selectedDoc.title,
                fileName: Store.selectedDoc.fileName,
                object: Store.selectedDoc.object,
                numVersion: Store.selectedDoc.numVersion,
                natureId: Store.idNature,
                // referencialIdx: _idxReferencial, TODO JJ
                // publicDoc: this.state.docPublic, TODO
                state: "En vigueur"
            }).then((res) => {
                this.getAllDocuments()
                Store.snackBar.message = "La version du document a été publiée"
                Store.snackBar.open = true
                Store.snackBar.error = false
                if(Store.modeSharepoint == false){
                    Store.contextualmenu.open = "extended";
                    Store.contextualmenu.content = "documentVersion";
                  }
                Store.selectedDoc.state = res.data.state
                Store.selectedDoc.state = res.data.state
            });
        } else{
            Store.snackBar.message = "Erreur : Veuillez remplir le titre !"
            Store.snackBar.open = true 
            Store.snackBar.error = true
        }
    }


    public reload = (obj) => {
        Store.selectedDoc.state = obj.state
    }
    public askPublishDocAtGed = () => {

        let _idDoc
        if (Store.selectedDoc.id != undefined || Store.selectedDoc.id != null) {
            _idDoc = Store.selectedDoc.id
        } else {
            _idDoc = Store.selectedDoc.id
        }
        if (Store.isDisableditem == false) {
            axios.post(Store.wsPath + '/1/documents/askPublishDoc', {
                id: _idDoc
            }).then(response => {
                Store.isDisableditem = true
                Store.snackBar.open = true
                Store.snackBar.message = "La demande de publication a été envoyé au gestionnaire documentaire"
                Store.snackBar.error = false
            })
        } else {
            Store.snackBar.open = true
            Store.snackBar.message = "La publication est en attente de validation"
            Store.snackBar.error = false
        }

    }
    

    renderConditionMode = () => {
        if (Store.modeDocument == 1 && (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin" || Store.param.isAuthorizedPublish == true) ) { // Nouveau document
            if (Store.selectedDoc.state == "En préparation") {
                return (
                    <div className={styles.itemMenuParameterVersion} onClick={this.onPublishVersion}> <Icon className={styles.icon}>assignment_returned</Icon>Publier directement la version</div>
                )
            } else if (Store.selectedDoc.state == "En vigueur") {

                return (
                    <div>
                        <div className={styles.itemMenuParameterVersion} onClick={this.onVersionInPreparation}> <Icon className={styles.icon}>assignment_returned</Icon>Annuler la publication</div>
                        <div className={styles.itemMenuParameterVersion} onClick={this.archieveDocuments}> <Icon className={styles.icon}>assignment_returned</Icon>Archiver la version</div>
                    </div>
                )
            }
            else if (Store.selectedDoc.state == "Retiré") {
                return (
                    <div>
                        <div className={styles.itemMenuParameterVersion} onClick={this.onVersionInPreparation}> <Icon className={styles.icon}>assignment_returned</Icon>Annuler le retrait de la version</div>
                    </div>
                )
            }

        } else if (Store.modeDocument == 1 && (Store.userConnected.typeUserLydoc != "ged" && Store.userConnected.typeUserLydoc != "admin" && Store.param.isAuthorizedPublish == false)) {
            if (Store.selectedDoc.state == "En préparation") {
                return (
                    <div className={styles.itemMenuParameterVersion} onClick={this.askPublishDocAtGed}> <Icon className={styles.icon}>assignment_returned</Icon>Demander la publication du document</div>
                )
            }
        }

        else if (Store.modeDocument == 2 && (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin" || Store.param.isAuthorizedPublish == true) ) { // Document existant (depuis le stylo)

            if (Store.selectedDoc.state == "En préparation") {
                return (
                    <div className={styles.itemMenuParameterVersion} onClick={this.onPublishVersion}> <Icon className={styles.icon}>assignment_returned</Icon>Publier directement la version</div>
                )
            } else if (Store.selectedDoc.state == "En vigueur") {
                return (
                    <div>
                        <div className={styles.itemMenuParameterVersion} onClick={this.onVersionInPreparation}> <Icon className={styles.icon}>assignment_returned</Icon>Annuler la publication</div>
                        <div className={styles.itemMenuParameterVersion} onClick={this.archieveDocuments}> <Icon className={styles.icon}>assignment_returned</Icon>Archiver la version</div>
                    </div>
                )
            } else if (Store.selectedDoc.state == "Retiré") {
                return (
                    <div className={styles.itemMenuParameterVersion} onClick={this.onVersionInPreparation}> <Icon className={styles.icon}>assignment_returned</Icon>Annuler le retrait de la version</div>
                )
            }
        } else if (Store.modeDocument == 2 && (Store.userConnected.typeUserLydoc != "ged" && Store.userConnected.typeUserLydoc != "admin" && Store.param.isAuthorizedPublish == false)) {
            if (Store.selectedDoc.state == "En préparation") {
                return (
                    <div className={styles.itemMenuParameterVersion} onClick={this.askPublishDocAtGed}> <Icon className={styles.icon}>assignment_returned</Icon>Demander la publication du document</div>
                )
            }
        }
    }
    public render() {
        return (
            <div>
                {this.renderConditionMode()}
            </div>
        )
    }
}
export default withRouter(ManageVersion);