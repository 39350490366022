import * as React from "react";
import TextField from "@material-ui/core/TextField";

interface IMultilineEditorProps {
  onUpdate: () => string;
  defaultValue: string;
  onBlur: () => any;
  row: any;
}

interface IMultilineEditorState {
  value: string;
}

export default class MultilineEditor extends React.Component<IMultilineEditorProps, IMultilineEditorState> {
  constructor(props: IMultilineEditorProps) {
    super(props);
    this.state = {
      value: this.props.defaultValue != undefined && this.props.defaultValue != null ? this.props.defaultValue : ""
    };
  }

  public onChange = (event) => {
    this.setState({value: event.target.value});
  }
  render() {
    return (
      <div>
        <TextField
          variant="outlined"
          defaultValue={this.state.value}
          onChange={this.onChange}
          multiline
          minRows={3}
          onBlur={this.props.onBlur}
        />
      </div>
    );
  }
}