import * as React from 'react'
const styles = require('./styles/styles.scss')
import { Pie } from 'react-chartjs-2';

interface Props { 
    data: number[]
    text: string
    labels: string[]
}

interface State {

}

export default class PieStats extends React.Component<Props, State>{
    public constructor(props: Props) {
        super(props)
    }


    componentDidMount() {

    }

    render() {
        return (
            <div className={styles.statsRight}>
                <Pie
                    data={{
                        labels: this.props.labels,
                        datasets: [{
                            data: this.props.data,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1,
                            weight: 0.5,
                        }]
                    }}
                    // data={this.props.data}
                    options={{
                        title: {
                            display: true,
                            text: this.props.text,
                            fontSize: 22
                        },
                        legend: {
                            display: true,
                            position: 'top'
                        },
                        // weight : 0.5,
                        // borderColor : '#000000'
                    }}
                />
            </div>
        )
    }
}