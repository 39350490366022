import * as React from 'react';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import styles from "./SPFieldDateTime.module.scss";
import globalStyle from "./globalStyle.module.scss";
import { IColumnInfo } from '../interfaces/IRenderForm';

interface SPFieldDateTimeProps {
  field: IColumnInfo;
  value: string;
  readOnly?:boolean;
  onChange?: (question:IColumnInfo, type?:string) => (event: any, newValue?: string) => void;
}

interface SPFieldDateTimeState {

}

export const DayPickerStrings: IDatePickerStrings = {
  months: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ],
  shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Dec'],
  days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  shortDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  goToToday: "Aller à aujourd'hui",
  prevMonthAriaLabel: 'Mois précédent',
  nextMonthAriaLabel: 'Mois suivant',
  prevYearAriaLabel: 'Année précédente',
  nextYearAriaLabel: 'Année suivante',
  closeButtonAriaLabel: 'Fermer',
  monthPickerHeaderAriaLabel: '{0}, sélectionner pour changer le mois',
  yearPickerHeaderAriaLabel: "{0}, sélectionner pour changer le l'année",
};

export const onFormatDate = (date?: Date): string => {
  let dateFormat = date;
  const dates = dateFormat.getDate() < 10 ? "0" + dateFormat.getDate() : dateFormat.getDate();
  const month = dateFormat.getMonth();
  const year = dateFormat.getFullYear();
  const day = dateFormat.getDay();

  return !dateFormat ? '' : DayPickerStrings.days[day] + " " + dates + ' ' + DayPickerStrings.shortMonths[month] + " " + year;
};

export const hoursMinutesParams: { hours: string[], minutes: string[] } = {
  hours: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
  minutes: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"]
}

export const formatHours = (date: string | Date) => {
  const dateDate:Date = typeof(date) == "string" ? new Date(date) : date;
  const hours = dateDate.getHours() < 10 ? "0" + dateDate.getHours() : dateDate.getHours();
  const minutes = dateDate.getMinutes() < 10 ? "0" + dateDate.getMinutes() : dateDate.getMinutes();
  const dateFinale:string = hours + ":" + minutes;
  return dateFinale;
}

export default class SPFieldDateTime extends React.Component<SPFieldDateTimeProps, SPFieldDateTimeState> {
  constructor(props: SPFieldDateTimeProps) {
    super(props);
    this.state = {};
  }
  render() {
    let value = null;
    if(this.props.value != undefined && this.props.value != null && this.props.value != "") {
      if(this.props.value == "[today]") {
        value = new Date();
      } else {
        value = new Date(this.props.value);
      }
    }
    const onChangeParamDate:any = this.props.onChange != undefined && this.props.onChange != null ? {onSelectDate:this.props.onChange(this.props.field, "date")} : {};
    const onChangeParamHour:any = this.props.onChange != undefined && this.props.onChange != null ? {onChange:this.props.onChange(this.props.field, "hour")} : {}; 
    return ( 
      <div>
        <div className={styles.SPFieldDateTimeContainer} style={{position:"relative"}}>
        <DatePicker
          className={styles.SPFieldDateTime}
          label={this.props.field.createData.Title}
          value={value}
          firstDayOfWeek={DayOfWeek.Monday}
          strings={DayPickerStrings}
          placeholder="Sélectionner une date..."
          ariaLabel="Sélectionner une date..."
          formatDate={onFormatDate}
          key={this.props.field.createData.Title}
          isRequired={this.props.field.createData.Required}
          textField ={{readOnly:this.props.readOnly != undefined ? this.props.readOnly : false, name:this.props.field.internalName}}
          id={this.props.field.internalName}
          // onSelectDate={this.props.onChange(this.props.field, "date")}
          {...onChangeParamDate}
        />
        {this.props.readOnly != undefined && this.props.readOnly == true ?
          <div style={{position: "absolute",top: 0,left: 0,width: "100%",height: "100%"}}></div>:<React.Fragment></React.Fragment>
        }
        {
          this.props.field.createData.DisplayFormat == 1 ?
            <TextField
              type="time"
              key={this.props.field.createData.Title + "/Hour"}
              // label="Heures et Minutes (hh:mm)"
              // onChange={this.onChangeHour("DefaultValue")}
              required={this.props.field.createData.Required}
              value={value == null ?"":formatHours(value)}
              pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
              title="Champ heure, format : hh:mm"
              className={styles.SPFieldDateTimeHour}
              readOnly={this.props.readOnly != undefined ? this.props.readOnly : false}
              //onChange={this.props.onChange(this.props.field, "hour")}
              disabled={value == undefined || value == null ? true : false}
              {...onChangeParamHour}
            />
            :
            ""
        }
        </div>
        {
          this.props.field.createData.Description != undefined && this.props.field.createData.Description != null && this.props.field.createData.Description.length > 0 ?
          <span className={globalStyle.formFieldDescription}>{this.props.field.createData.Description}</span>
          :
          ""
        }
      </div>
    );
  }
}