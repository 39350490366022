import * as React from 'react';
import { observer } from "mobx-react";
import TextField  from '@material-ui/core/TextField';


interface Props {

}

interface State {
    nameSearch
}

@observer

export default class EditBanette extends React.Component<Props, State>{
    public constructor(props: Props) {
        super(props)
        this.state = {
            nameSearch: undefined
        }
    }



    public render() {
        return (
           <div>
               <TextField />
           </div>

        )
    }
}