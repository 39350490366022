import * as React from "react";
// import ElementGenerator from "../Elements/ElementGenerator";
import { observer } from "mobx-react";
import Icon from "@material-ui/core/Icon";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
const styles = require("./styles/ContextualMenu.scss");
const animationStyle = require("../../styles/animation.scss");
import DocumentSelected from '../ListeDocuments/documentselected'
import VersionContextualMenu from "../FormulaireReferencement/VersionContextualMenu";
import User from "../parametersUsers/User";
import EditWorflow from "../Workflow/EditWorflow";
// import SaveModel from "../Workflow/SaveModel"
import ActionWorkflow from "../Workflow/ActionWorkflow"
// import ActionWorkflow2 from "../Workflow/ActionWorkflow2"
import ModelWf from "../parametersModels/ModelWf";
import EditStepModel from "../parametersModels/EditStepModel"
import AddEditRole from "../parametersRoles/AddEditRole"
import EditDateFinSouhaitee from "../FormulaireReferencement/EditDateFinSouhaitee";
import AddSearchWorkflow from "../search/AddSearchWorkflow";
import EditBanette from "../search/editBanette";
import MenuDeleg from "./contentMenu/MenuDeleg";
import MenuModelDeleg from "./contentMenu/MenuModelDeleg";
import ParamGroupes from"../parametersGroups/ParamGroupes";
import Store from "../store/store";

interface Props {
    onCloseContextualMenu: () => void;
    menuOpen: string;
    menuContent: string;
}

@observer
export class ContextualMenu extends React.Component<Props, {}> {
    private closeListener: any = "";
    constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        this.closeListener = document.addEventListener('mousedown', (e: any) => {
            if (this.props.menuOpen == "extended" && this.props.menuContent != "ActionsWorkflow") {
                const container = document.getElementById('contextualMenuContainer');

                if (container != null && !container.contains(e.target)) {
                    this.closeMenuContextual();
                }
            }
        });
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeListener);
    }

    public closeMenuContextual = () => {
        this.props.onCloseContextualMenu();
    }

    public renderComponent = (content: string) => {
        switch (content) {
            case "documentsselected":
                return (<DocumentSelected />)

            case "documentVersion":
                return (<VersionContextualMenu />)

            case "addUser":
            case "editUser":
                return (<User />)

            case "addStep":
            case "editStep":
                return (<EditWorflow />)

            case "editStepModel":
            case "addStepModel":
            case "insertStepModel":
                return (<EditStepModel />)

            case "ActionsWorkflow":
                return (<ActionWorkflow contextualMenuOpen={this.props.menuOpen} myStepTaskSelectionned={Store.myStepTaskSelectionned} />)

            case "addNewModel":
            case "editModel":
                return (<ModelWf />)

            case "editDatedeFinsouhaitee":
                return (<EditDateFinSouhaitee />)

            case "addRole":
            case "editRole":
                return (<AddEditRole />)

            case "addSearch":
                return (<AddSearchWorkflow />)

            case "deletBanet":
                return (<EditBanette />)
            
            case "addNewGroup":
            case "EditGroup":
            case "DeleteGroup":
                return (<ParamGroupes />)

            case "editModelDeleg":
            case "addNewModelDeleg":
                return (<MenuModelDeleg />)

            case "programDeleg":
            case "startDeleg":
            case "editDeleg":
            case "addNewDeleg":
                return (<MenuDeleg />)

            default:
                return (
                    <div className={styles.noContain}>
                        <h6>Aucune action en cours</h6>
                    </div>
                )
        }


        // {Store.contextualmenu.content == "" || Store.contextualmenu.content == null || Store.contextualmenu.content == undefined ?
        //                         <div className={styles.noContain}>
        //                             <h6>Aucune action en cours</h6>
        //                         </div>
        //                         : ""}

        //                     {Store.contextualmenu.content == "documentsselected" && Store.workflow.id == undefined ?
        //                         <div>
        //                             <DocumentSelected />
        //                         </div>
        //                         : ""}

        //                     {Store.contextualmenu.content == "documentVersion" ?
        //                         <div>
        //                             <VersionContextualMenu />
        //                         </div>
        //                         : ""}
        //                     {Store.contextualmenu.content == "addUser" || Store.contextualmenu.content == "editUser" ?
        //                         <div>
        //                             <User />
        //                         </div>
        //                         : ""}
        //                     {Store.contextualmenu.content == "editProject" || Store.contextualmenu.content == "addStep" || Store.contextualmenu.content == "editStep" ?
        //                         <div>
        //                             <EditWorflow />
        //                         </div>
        //                         : ""}

        //                     {Store.contextualmenu.content == "editStepModel" || Store.contextualmenu.content == "addStepModel" || Store.contextualmenu.content == "insertStepModel" ?
        //                         <div>
        //                             <EditStepModel />
        //                         </div>
        //                         : ""}

        //                     {Store.contextualmenu.content == "ActionsWorkflow" ?
        //                         <div>
        //                             <ActionWorkflow />
        //                         </div>
        //                         : ""}

        //                     {Store.contextualmenu.content == "addNewModel" || Store.contextualmenu.content == "editModel" ?
        //                         <div>
        //                             <ModelWf />
        //                         </div>
        //                         : ""}

        //                     {Store.contextualmenu.content == "editDatedeFinsouhaitee" ?
        //                         <div>
        //                             <EditDateFinSouhaitee />
        //                         </div>
        //                         : ""}

        //                     {Store.contextualmenu.content == "addRole" || Store.contextualmenu.content == "editRole" ?
        //                         <div>
        //                             <AddEditRole />
        //                         </div>
        //                         : ""}

        //                     {Store.contextualmenu.content == "addSearch" ?
        //                         <div>
        //                             <AddSearchWorkflow />
        //                         </div>
        //                         : ""}

        //                     {Store.contextualmenu.content == "deletBanet" ?
        //                         <div>
        //                             <EditBanette />
        //                         </div>
        //                         : ""}
    }

    public render() {
        const componentContentStyleCSS: React.CSSProperties = {};
        if (this.props.menuOpen == "open") {
            componentContentStyleCSS["paddingLeft"] = 0;
        }
        return (
            // <React.Fragment>
            //     {
            //         this.props.menuOpen == "extended" ?
            //             <div className={styles.layerClick} onClick={() => this.closeMenuContextual()}></div>
            //             :
            //             ""
            //     }
                <div id="contextualMenuContainer" className={styles.contextualMenuContainer}>
                    <div onClick={this.closeMenuContextual} className={styles.headerContextualMenu}>
                        <Icon style={this.props.menuOpen == "extended" ? { transform: "rotate(180deg)" } : {}} className={this.props.menuOpen != "extended" ? animationStyle.iconArrow : ""} color='primary'>keyboard_arrow_left</Icon>
                        <h5 className={styles.h5}>Actions</h5>
                    </div>
                    <hr className={styles.hr} />
                    <div className={styles.componentContent}>
                        <SimpleBar>
                            <div style={componentContentStyleCSS} className={styles.componentContentStyle}>
                                {this.renderComponent(this.props.menuContent)}
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            // </React.Fragment>
        );
    }
}