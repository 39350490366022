import SPFieldText from "./SPFieldText";
import SPFieldNumber from "./SPFieldNumber";
import SPFieldChoice from "./SPFieldChoice";
import SPFieldDateTime from "./SPFieldDateTime";
import SPFieldBoolean from "./SPFieldBoolean";
import Title from "./Title";
import Paragraphe from "./Paragraphe";
import File from "./File";

export const formFieldsList:any = {
    Text: SPFieldText,
    Note: SPFieldText,
    Number: SPFieldNumber,
    Choice: SPFieldChoice,
    DateTime: SPFieldDateTime,
    Boolean: SPFieldBoolean,
    Title:Title,
    Paragraph: Paragraphe,
    File: File
};