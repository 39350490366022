import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
const styles = require('./styles/MyMessages.scss');
import Store from '../store/store';
import axios from 'axios';
import { observer } from "mobx-react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { RouteComponentProps, withRouter } from 'react-router-dom';


interface Props {

}

interface IState {
    myMessagesSend: any[];
    checkBoxEncours: boolean;
    checkBoxTerminée: boolean;
    checkBoxEnAttente: boolean;
    checkBoxRefuse: boolean;
    messageForMe: any[];
    ongletSelect: number;
    messageToDisplay: any[];
}

@observer

class MyMessages extends React.Component<RouteComponentProps<any>, IState> {
    public constructor(props: any) {
        super(props)
        this.state = {
            checkBoxEncours: true,
            checkBoxTerminée: false,
            checkBoxEnAttente: false,
            checkBoxRefuse: false,
            messageForMe: [],
            myMessagesSend: [],
            ongletSelect: 0,
            messageToDisplay: []
        }
        this.getDocumentName()
    }
    componentDidMount() {
    }

    getDocumentName() {
        axios.get(Store.wsPath + '/1/postIt/getTaskPostItByUser', {
            params: {
                idUserConnected: Store.userConnected.id
            }
        }).then(async response => {
            this.toSetDataForTable(response.data);
        });
    }
    goToWorkFlow = (row) => {
        this.props.history.push('/document?w=' + row.idProject + "&s=" + row.stepId + "&t=" + row.taskId);
    }
    toSetDataForTable(allPostItBlue: { myMessage: any[], forMe: any[] }) {
        const allMyMessage: any[] = [];
        const allForMeMessage: any[] = [];

        if (allPostItBlue.forMe != undefined && allPostItBlue.forMe != null && allPostItBlue.forMe.length > 0) {
            for (let i = 0; i < allPostItBlue.forMe.length; i++) {
                if (allPostItBlue.forMe[i].TaskPostIts.length > 0) {
                    for (let j = 0; j < allPostItBlue.forMe[i].TaskPostIts.length; j++) {
                        allPostItBlue.forMe[i].TaskPostIts[j].PostItMessages.forEach(message => {
                            // A modifier en mode sherPoint
                            const tempObject = {
                                id: message.id,
                                date: message.createdAt,
                                message: message.message,
                                idUserQuiAEcritLeMessage: message.idxUser,
                                idProject: allPostItBlue.forMe[i].WfStep.WfProject.id,
                                stateStep: allPostItBlue.forMe[i].WfStep.state,
                                designationProjet: allPostItBlue.forMe[i].WfStep.WfProject.name,
                                idPostIt: allPostItBlue.forMe[i].TaskPostIts[j].id,
                                taskName: allPostItBlue.forMe[i].name,
                                stateTask: allPostItBlue.forMe[i].state,
                                stepId: allPostItBlue.forMe[i].WfStep.id,
                                taskId: allPostItBlue.forMe[i].id
                            }

                            allForMeMessage.push(tempObject)
                        });
                    }
                }
            }
        }

        if (allPostItBlue.myMessage != undefined && allPostItBlue.myMessage != null && allPostItBlue.myMessage.length > 0) {
            for (let i = 0; i < allPostItBlue.myMessage.length; i++) {
                if (allPostItBlue.myMessage[i].TaskPostIts.length > 0) {
                    for (let j = 0; j < allPostItBlue.myMessage[i].TaskPostIts.length; j++) {
                        allPostItBlue.myMessage[i].TaskPostIts[j].PostItMessages.forEach(message => {
                            // A modifier en mode sherPoint
                            const tempObject = {
                                id: message.id,
                                date: message.createdAt,
                                message: message.message,
                                idUserQuiAEcritLeMessage: message.idxUser,
                                idProject: allPostItBlue.myMessage[i].WfStep.WfProject.id,
                                stateStep: allPostItBlue.myMessage[i].WfStep.state,
                                designationProjet: allPostItBlue.myMessage[i].WfStep.WfProject.name,
                                idPostIt: allPostItBlue.myMessage[i].TaskPostIts[j].id,
                                taskName: allPostItBlue.myMessage[i].name,
                                stateTask: allPostItBlue.myMessage[i].state,
                                stepId: allPostItBlue.myMessage[i].WfStep.id,
                                taskId: allPostItBlue.myMessage[i].id
                            }

                            allMyMessage.push(tempObject)
                        });
                    }
                }
            }
        }

        this.setState({ myMessagesSend: allMyMessage, messageForMe: allForMeMessage }, () => this.sortAllMessagesByUsers())
    }

    public convertDate = (date) => {
        const newDate = new Date(date)
        if ((newDate.getMonth() + 1) < 10) {
            if (newDate.getDate() < 10) {
                return "0" + newDate.getDate() + "/0" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
            }
            else {
                return newDate.getDate() + "/0" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
            }
        } else {
            if (newDate.getDate() < 10) {
                return "0" + newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
            }
            else {
                return newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
            }
        }
    }

    public convertUser = (idUserQuiAEcritLeMessage) => {
        const tempUserFind = Store.allUsers.find(user =>
            user.id == idUserQuiAEcritLeMessage
        )
        if (tempUserFind != undefined || null) {
            return tempUserFind.firstname + ' ' + tempUserFind.lastname
        }
        else {
            return "Auteur introuvable"
        }
    }

    sortAllMessagesByUsers() {
        let tempArrayAllMessages = []
        const finalTempArrayMessages = []
        if (this.state.ongletSelect == 1) {
            tempArrayAllMessages = this.state.myMessagesSend;
            // this.state.allMessageCopie.forEach(message => {
            //     if (Store.userConnected.id == message.idUserQuiAEcritLeMessage) {
            //         tempArrayAllMessages.push(message)
            //     }
            // });
        }
        if (this.state.ongletSelect == 0) {
            tempArrayAllMessages = this.state.messageForMe;
            // this.state.allMessageCopie.forEach(message => {
            //     if (Store.userConnected.id != message.idUserQuiAEcritLeMessage) {
            //         tempArrayAllMessages.push(message)
            //     }
            // });
        }


        if (this.state.checkBoxEncours) { // En cours
            tempArrayAllMessages.forEach(message => {
                if (message.stateTask == "En cours") {
                    finalTempArrayMessages.push(message)
                }
            });
        }
        if (this.state.checkBoxTerminée) { // terminée
            tempArrayAllMessages.forEach(message => {
                if (message.stateTask == "Terminée") {
                    finalTempArrayMessages.push(message)
                }
            });
        }
        if (this.state.checkBoxEnAttente) { // en attente
            tempArrayAllMessages.forEach(message => {
                if (message.stateTask == "En préparation") {
                    finalTempArrayMessages.push(message)
                }
            });
        }
        if (this.state.checkBoxRefuse) { // refusé
            tempArrayAllMessages.forEach(message => {
                if (message.stateTask == "Refusé" || message.stateTask == "Annulé") {
                    finalTempArrayMessages.push(message)
                }
            });
        }
        const TempArray = Array.from(new Set(finalTempArrayMessages));
        this.setState({ messageToDisplay: TempArray });
    }

    buttonFormatTask = status => event => {
        if (status == 'En cours') {
            this.setState({ checkBoxEncours: event.target.checked }, () => this.sortAllMessagesByUsers())
        }
        if (status == 'Terminée') {
            this.setState({ checkBoxTerminée: event.target.checked }, () => this.sortAllMessagesByUsers())
        }
        if (status == 'En attente') {
            this.setState({ checkBoxEnAttente: event.target.checked }, () => this.sortAllMessagesByUsers())
        }
        if (status == 'Refusé') {
            this.setState({ checkBoxRefuse: event.target.checked }, () => this.sortAllMessagesByUsers())
        }
    }

    onChangeOnglet = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ ongletSelect: newValue }, () => this.sortAllMessagesByUsers())
    }

    public render() {
        const onClickRow = {
            clickToSelect: true,
            onSelect: this.goToWorkFlow,
            mode: 'radio',
            bgColor: '#10a544',
            hideSelectColumn: true
        }
        const options = {
            sortIndicator: true,
            defaultSortName: 'id',
            noDataText: 'Aucun message à afficher',
            sizePerPage: 10,
        };
        return (
            <div className={styles.contentFirst}>
                <div className={styles.titleContent} style={{ background: Store.themeParam.backgroundPrimary }}>
                    <h5>Mes messages</h5>
                </div>
                <div className={styles.tableElement} >
                    <div className={styles.checkDiv}>
                        <span className={styles.statutText}>Statut de la tâche :</span>
                        <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.checkBoxEncours} onChange={this.buttonFormatTask('En cours')} />En cours
                        <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.checkBoxEnAttente} onChange={this.buttonFormatTask('En attente')} />En attente
                        <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.checkBoxTerminée} onChange={this.buttonFormatTask('Terminée')} />Terminée
                        <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.checkBoxRefuse} onChange={this.buttonFormatTask('Refusé')} />Refusé
                    </div>
                    <AppBar position='static'>
                        <Tabs
                            onChange={this.onChangeOnglet}
                            value={this.state.ongletSelect}
                            indicatorColor='primary'
                        >
                            <Tab label="Mes messages adressés" style={{ background: Store.themeParam.backgroundSecondary, marginRight: "10px" }}> {/* onChange={this.buttonFormat('moi')} */}</Tab>
                            <Tab label="Mes messages envoyés" style={{ background: Store.themeParam.backgroundSecondary }}> {/* onChange={this.buttonFormat('autres')} */}</Tab>
                        </Tabs>
                    </AppBar>
                    <BootstrapTable
                        multiColomnSearch
                        data={this.state.messageToDisplay}
                        searchPlaceholder='Rechercher dans la liste'
                        search={true}
                        options={options}
                        selectRow={onClickRow}
                        pagination version='4'
                        bordered={true}
                        className={styles.contentMyTasks}
                        trStyle={{ cursor: "pointer" }}
                    >
                        <TableHeaderColumn isKey={true} dataField='id' hidden dataAlign='center' headerAlign='center' >id</TableHeaderColumn>
                        <TableHeaderColumn datasort={true} dataField='designationProjet' dataAlign='center' headerAlign='center' >Désignation du dossier</TableHeaderColumn>
                        <TableHeaderColumn datasort={true} dataField='taskName' dataAlign='center' headerAlign='center' >Nom de la tâche</TableHeaderColumn>
                        <TableHeaderColumn datasort={true} dataField='idUserQuiAEcritLeMessage' dataFormat={this.convertUser} dataAlign='center' headerAlign='center' >Auteur</TableHeaderColumn>
                        <TableHeaderColumn datasort={true} dataField='date' dataFormat={this.convertDate} dataAlign='center' headerAlign='center' width="8%" >Date</TableHeaderColumn>
                        <TableHeaderColumn datasort={true} dataField='message' dataAlign='center' headerAlign='center' >Message</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        )
    }
}
export default withRouter(MyMessages);