import * as React from 'react'
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const styles = require('./styles/param_styles.scss');
import Store from '../store/store'
import axios from 'axios'
import { observer } from "mobx-react";
import paramMailSender from '../parametersEmail/paramMailSender'

interface Props {
}

interface State {
    isAuthorizedPublish?: boolean
}

@observer
export default class Parameter extends React.Component<Props, State>{

    public constructor(props: Props) { // Dans le constructeur, on initialise toujours les states
        super(props)
        this.getParam()
        this.state = {
            isAuthorizedPublish: false
        }
    }


    public getParam = () => {
        axios.get(Store.wsPath + "/1/paramLydoc/getParamLydoc").then((response) => {
            Store.param.isAuthorizedPublish = response.data.isAuthorizedPublish
            Store.param.isSavedPJ = response.data.savePieceJointe
        })
    }

    public onChange = (name: string, ) => event => {
        if (name == "authorizePublishDoc") {
            Store.param.isAuthorizedPublish = event.target.checked
        }
    }

    public updateParam = () => {
        axios.post(Store.wsPath + '/1/paramLydoc/updateParamYes', {
            isAuthorizedPublish: Store.param.isAuthorizedPublish
        }).then((response) => {
            Store.snackBar.error = false
            Store.snackBar.message = "Le paramétrage a été modifié"
            Store.snackBar.open = true
        })
    }

    public render() {
        return (
            <div className={styles.firstContent}>
                <div className={styles.paramTitle} style={{ background: Store.themeParam.backgroundPrimary }}>
                    <h5>Paramètres généraux :</h5>
                </div>
                <div className={styles.paramContent}>
                    <div>
                        {Store.userConnected.typeUserLydoc == "admin" ?
                            <div>
                                <h5>Gestion des informations clients</h5>

                            </div>
                        : 
                            ""
                        }

                        {Store.modeSharepoint == false ?
                            <div>
                                <h5>Publication des documents</h5>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Store.param.isAuthorizedPublish}
                                            onChange={this.onChange('authorizePublishDoc')}
                                            color='primary'
                                        />
                                    }
                                    labelPlacement="start"
                                    label="Autoriser la publication au responsable de document"

                                />
                            </div>
                            : <div />}
                        {Store.modeSharepoint == false ?
                        <div className={styles.contentBtn}>
                            <Button
                                onClick={this.updateParam}
                                variant="contained"
                                className={styles.button}
                                color="primary"
                            >
                                VALIDER
                            </Button>
                        </div>
                    :""}
                    {Store.userConnected.typeUserLydoc == 'all' ?
                        <div>
                            {paramMailSender}
                        </div>
                    :""}
                    </div>
                </div>
            </div>
        )
    }
}