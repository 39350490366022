import React from "react";
import { IUser } from "../../parametersUsers/interfaces/IUser";
import Store from '../../store/store';
import { IMessage } from "../interfaces/Messages";
const styles = require("../styles/styles.scss");

interface MessageProps {
    message: IMessage;
}

interface MessageState {
    user: IUser;
    myMessage: string;
    isDateDisplay: boolean;
}

class Message extends React.Component<MessageProps, MessageState> {
    constructor(props: MessageProps) {
        super(props);
        this.state = {
            user: null,
            myMessage: null,
            isDateDisplay: false
        };
    }

    componentDidMount(): void {
        const user = Store.allUsers.find(user => user.id === this.props.message.idUser);
        if (user !== undefined) {
            this.setState({ user: user });
        }
    }
    fullNameOfUser = (idUser) => {
        const user = Store.allUsers.find(user => user.id == idUser);
        return user.firstname + " " + user.lastname;
    }
    createProfilPicture = (idUser) => {
        const user = Store.allUsers.find(user => user.id == idUser);
        if (user != undefined) {
            return user.firstname[0] + user.lastname[0];
        }
    }
    onClickMessage = (event) => {
        event.stopPropagation();
        const tempIsDateDisplay = !this.state.isDateDisplay
        this.setState({ isDateDisplay: tempIsDateDisplay });
    }
    showMessageDate = () => {
        const date = new Date(this.props.message.createdAt)
        var dateToPrint = "";
        dateToPrint += date.getDate().toString();
        if (date.getMonth() > 9) {
            dateToPrint += "/" + date.getMonth().toString();
        } else {
            dateToPrint += "/0" + date.getMonth().toString();
        }
        if (new Date().getFullYear() !== date.getFullYear()) {
            dateToPrint += "/" + date.getFullYear().toString();
        }
        dateToPrint += " à " + date.getHours().toString() + ":";
        if (date.getMinutes() < 10) {
            dateToPrint += "0" + date.getMinutes();
        } else {
            dateToPrint += date.getMinutes();
        }
        return dateToPrint;
    }
    render() {
        return (
            <div className={styles.messageContainer}>
                <div className={styles.dateContainer + " " + (this.state.isDateDisplay ? styles.dateContainerShow : '')}>
                    <span>Envoyé le {this.showMessageDate()}</span>
                </div>
                <div className={styles.message} onClick={this.onClickMessage}>
                    {
                        this.props.message.idUser === Store.userConnected.id ?
                            // son message
                            <div className={styles.myMessageBox + " " + styles.messageBox}>
                                <div className={styles.textBox}>
                                    {this.props.message.content}
                                </div>
                            </div>
                            :
                            // pas son message
                            <div className={styles.messageBox}>
                                <div className={styles.profilPicture} title={this.fullNameOfUser(this.props.message.idUser)}>
                                    {this.createProfilPicture(this.props.message.idUser)}
                                </div>
                                <div className={styles.textBox}>
                                    {this.props.message.content}
                                </div>
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default Message;