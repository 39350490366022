import * as React from 'react';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import globalStyle from "./globalStyle.module.scss";
import { IColumnInfo } from '../interfaces/IRenderForm';

interface SPFieldBooleanProps {
  field: IColumnInfo;
  value: string;
  readOnly?:boolean;
  onChange?: (question:IColumnInfo, type?:string) => (event: any, checked?: boolean) => void;
}

interface SPFieldBooleanState {

}

export default class SPFieldBoolean extends React.Component<SPFieldBooleanProps, SPFieldBooleanState> {
  constructor(props: SPFieldBooleanProps) {
    super(props);
    this.state = {};
  }
  render() {
    const onChangeParam:any = this.props.onChange != undefined && this.props.onChange != null ? {onChange:this.props.onChange(this.props.field)} : {}; 
    return (
      <div style={{position:"relative"}}>
        <Toggle
          label={this.props.field.createData.Title}
          onText="Oui"
          offText="Non"
          defaultChecked={this.props.value == "1" ? true : false}
          // onChange={this.props.onChange(this.props.field)}
          key={this.props.field.createData.Title}
          {...onChangeParam}
        />
        {
          this.props.field.createData.Description != undefined && this.props.field.createData.Description != null && this.props.field.createData.Description.length > 0 ?
            <span className={globalStyle.formFieldDescription}>{this.props.field.createData.Description}</span>
            :
            ""
        }
        {this.props.readOnly != undefined && this.props.readOnly == true ?
          <div style={{position: "absolute",top: 0,left: 0,width: "100%",height: "100%"}}></div>:<React.Fragment></React.Fragment>
        }
        
      </div>
    );
  }
}